import { BookingTime } from './StepComponents/BookConsultation/consts';

interface Input {
  allTime: BookingTime,
  disableTime: {
    [key: string]: string[],
  }
}

const withDisableTime = (data: Input): BookingTime => {

  const newBookingTime = JSON.parse(JSON.stringify(data.allTime));

  if (data.disableTime) {
    for (const key in data.allTime) {
      for (const timeKey in newBookingTime[key]) {
        if (data.disableTime[key] && data.disableTime[key].includes(timeKey)) {
          newBookingTime[key][timeKey] = false;
        }
      }
    }

    return newBookingTime;
  }
  
  return data.allTime;
};

export default withDisableTime;