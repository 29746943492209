import React from 'react';
import style from './style.module.scss';

interface Props {
  title: string;
  image: string;
}

const BannerDefault: React.FC<Props> = ({title, image}) => {
  return (
    <section className={style.banner}>
      <picture className={style.image}>
        <source srcSet={image} media="(max-width: 768px)" />
        <source srcSet={image} />
        <img srcSet={image} alt="" />
      </picture>
      <div className={style.panel}>
        <strong className={style.title}>{title}</strong>
      </div>
    </section>
  );
};

export default BannerDefault;