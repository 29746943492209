import React from 'react';

const IconDashedSquare: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="25" height="24" 
      viewBox="0 0 21 17" 
      fill="none"
    >
      <path 
        d="M0.137695 5.20898H1.55273V3.37207C1.55273 2.45801 2.03613 1.99219 2.91504 1.99219H4.73438V0.577148H2.89746C1.06055 0.577148 0.137695 1.48242 0.137695 3.30176V5.20898ZM20.8623 5.20898V3.30176C20.8623 1.48242 19.9395 0.577148 18.1025 0.577148H16.2656V1.99219H18.085C18.9639 1.99219 19.4473 2.45801 19.4473 3.37207V5.20898H20.8623ZM5.93848 1.99219H9.90234V0.577148H5.93848V1.99219ZM11.0977 1.99219H15.0615V0.577148H11.0977V1.99219ZM0.137695 10.9219H1.55273V6.41309H0.137695V10.9219ZM20.8623 10.9219V6.41309H19.4473V10.9219H20.8623ZM2.89746 16.7578H4.73438V15.3428H2.91504C2.03613 15.3428 1.55273 14.877 1.55273 13.9629V12.126H0.137695V14.0332C0.137695 15.8525 1.06055 16.7578 2.89746 16.7578ZM18.1025 16.7578C19.9395 16.7578 20.8623 15.8525 20.8623 14.0332V12.126H19.4473V13.9629C19.4473 14.877 18.9639 15.3428 18.085 15.3428H16.2656V16.7578H18.1025ZM5.93848 16.7578H9.90234V15.3428H5.93848V16.7578ZM11.0977 16.7578H15.0615V15.3428H11.0977V16.7578Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconDashedSquare;