import React from 'react';
import CustomSwiper from '../CustomSwiper';
import style from './style.module.scss';

interface Card {
  header: string, 
}

interface Props {
  data: Array<{
    custom_properties: Card;
    original_url: string,
  }>;
}

const SectionContentKit: React.FC<Props> = ({data}) => {
  return (
    <div className='swiper-stretch pagination-relative'>
      <CustomSwiper parameters={{ slidesPerView: 'auto', followFinger: false, loop: false,  spaceBetween: 10 }} classesForButtons={['.swiper-button-next', '.swiper-button-prev']} >
        {
          data.map((item, index) => (
            <div key={index} className={style.cell}>
              <div className={style.card}>
                <div className={style.preview} style={{backgroundImage: `url(${item.original_url})`}}></div>
                <strong className={style.title}>{item.custom_properties.header}</strong>
              </div>
            </div>
          ))
        }
      </CustomSwiper>
    </div>
  );
};

export default SectionContentKit;