import { ShippingActionTypes, ShippingAction, ClearShippingData, ChangeActiveDeliveryOption, ChangeActiveShippingOption, Address, ChangeAddressShippingOption } from './../../types/shipping';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<ShippingAction>) => void;

export const fetchShippingDisableDates = (): ReturnValue => {
  return async (dispatch: Dispatch<ShippingAction>) => {
    try {
      dispatch({
        type: ShippingActionTypes.FETCH_SHIPPING
      });
      const response = await axios.get('');
      dispatch({
        type: ShippingActionTypes.FETCH_SHIPPING_SUCCESS,
        payload: response.data
      });
    } catch (e) {
      dispatch({
        type: ShippingActionTypes.FETCH_SHIPPING_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const clearShippingDataAction = (): ClearShippingData => ({type: ShippingActionTypes.CLEAR_SHIPPING_DATA});
export const changeActiveDeliveryOptionAction = (payload: string): ChangeActiveDeliveryOption => ({type: ShippingActionTypes.CHANGE_ACTIVE_DELIVERY_OPTION, payload});
export const changeActiveShippingOptionAction = (payload: string): ChangeActiveShippingOption => ({type: ShippingActionTypes.CHANGE_ACTIVE_SHIPPING_OPTION, payload});
export const changeActiveShippingAddressAction = (payload: Address): ChangeAddressShippingOption => ({type: ShippingActionTypes.CHANGE_ADDRESS_SHIPPING_OPTION, payload});

