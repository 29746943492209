import { createSelector } from 'reselect';
import { CartProductsState } from '../../types/cartProducts';
import { ProductFeeTypes, ProductTypes } from '../../types/product';
import { calculateCartSubTotal, calculateCartTotal } from '../../utils/calculateCartTotal';
import { RootState } from '../reducers';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { getServiceLocation } from './order';
import { ServiceLocationType } from '../../types/order';
import { getDeliveryActiveOption } from './deliveryDetails';
import get from 'lodash/get';

export const getCartProductsState = ({ cartProducts }: RootState): CartProductsState  => cartProducts;

export const getCartProducts = createSelector(getCartProductsState, ({ products }) => products);

export const getCartProductsContainTypes = createSelector(
  [
    getCartProducts,
    (_, types: ProductTypes[] | ProductTypes) => types,
  ],
  (products, types) => !!products.filter(product => types.includes(product.product_type)).length
);

export const getCartProductFees = createSelector(
  [getCartProducts],
  (products) => !isEmpty(products) ? uniqBy(products.flatMap(product => product.fees), 'id') : [],
);

export const getCartProductConsulation = createSelector(
  [getCartProducts],
  (products) => get(products.find(product => product.consultation), 'consultation'),
);

export const getConsultationAndHomeCollectionFee = createSelector(
  [getCartProducts, getCartProductFees, getServiceLocation, getCartProductConsulation],
  (products, fees, serviceLocation, consultation) => {
    const hasConsultationFee = isEmpty(products.filter(({product_type}) => {
      return product_type === ProductTypes.ONLINE_CONSULTATION;
    }));

    //const hasConsultationFee = isEmpty(products.filter((item) => (item.consultation !== null) ? item.consultation.is_main_consultation == true : ''));

    const homeCollectionFee = serviceLocation === ServiceLocationType.HOME ? fees.find(fee => fee.type === ProductFeeTypes.HOME_COLLECTION_FEE) : null;
    
    return {
      homeCollectionFee: homeCollectionFee || null,
      consultationFee: hasConsultationFee ? consultation : null,
    };
  },
);

export const getCartSubTotal = createSelector(
  [getCartProducts],
  (products) => calculateCartSubTotal(products),
);

export const getCartTotal = createSelector(
  [
    getCartSubTotal,
    getConsultationAndHomeCollectionFee,
    getDeliveryActiveOption,
  ],
  (subtotal, { consultationFee, homeCollectionFee }, deliveryFee) => {
    const consultationPrice = consultationFee ? consultationFee.price : 0;
    const homeCollectionPrice = homeCollectionFee ? homeCollectionFee.price : 0;
    const deliveryPrice = deliveryFee ? deliveryFee.price : 0;

    return calculateCartTotal(subtotal, consultationPrice, homeCollectionPrice, deliveryPrice);
  },
);