import React from 'react';
import { Link } from 'react-router-dom';
import IconBriefcase from '../../assets/images/icons/briefcase';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import style from './style.module.scss';

const Basket: React.FC = () => {
  const {products} = useTypedSelector(state => state.cartProducts);

  return (
    <Link to={'/cart'} className={style.basket}>
      <div className={style.icon}>
        <IconBriefcase />
      </div>
      {products.length ? (
        <span className={style.counter}>
          {products.length ?? 0}
        </span>
      ) : null }
    </Link>
  );
};

export default Basket;