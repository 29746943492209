import React, { useState } from 'react';

interface Props {
  product?: number;
  onAdd: () => void;
  disabled?: boolean;
  full?: boolean
}

const AddButton: React.FC<Props> = ({onAdd, disabled, full}) => {
  const [added, setAdded] = useState(false);

  function isAdded() {
    setAdded(true);

    setTimeout(() => {
      setAdded(false);
    }, 3000);

  }

  return (
    <button disabled={disabled} className={full ? 'btn btn-default btn-full' : 'btn btn-default' } onClick={() => {onAdd(); isAdded();}}>
      {added ? (<span className={'btn-icon-check'}></span>) : 'Add to Tote'}
    </button>
  );
};

export default AddButton;