import React, { useCallback, useEffect, useMemo } from 'react';
import IconClinic from '../../../../assets/images/icons/clinic';
import IconHome from '../../../../assets/images/icons/home';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import { changeStepAction, clearServiceLocationAction, setServiceLocationAction } from '../../../../store/actions/order';
import { getCartProductsContainTypes } from '../../../../store/selectors/cartProducts';
import { ServiceLocationType, StepTypes } from '../../../../types/order';
import { ProductTypes } from '../../../../types/product';
import CustomButton from '../../../CustomButton';
import { DESCRIPTION_DATA } from './consts';
import style from './style.module.scss';
import { fetchClinic } from '../../../../store/actions/bookClinicVisit';
import { compareDesc } from 'date-fns/esm';

const ServiceLocation: React.FC = () => {
  const isOrderContainBloodCollection = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.BLOOD_TEST]));
  const isOrderContainPrescription = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.PRESCRIPTION]));
  const isOrderContainOnlineConsultation = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.ONLINE_CONSULTATION]));
  const isOrderContainClinicVisit = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.CLINIC_VISIT]));
  const dispatch = useTypedDispatch();

  const { clinics } = useTypedSelector(state => state.bookClinicVisit);

  const changeStep = useCallback((serviceType: ServiceLocationType) => {
    switch (serviceType) {
    case ServiceLocationType.CLINIC:
      dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT));
      break;
    case ServiceLocationType.HOME:
      if (isOrderContainOnlineConsultation && isOrderContainClinicVisit) {
        dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT));
      } else if (isOrderContainOnlineConsultation && isOrderContainBloodCollection) {
        dispatch(changeStepAction(StepTypes.BOOK_BLOOD_COLLECTION));
      } else if(isOrderContainOnlineConsultation) {
        dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
      } else {
        dispatch(changeStepAction(StepTypes.BOOK_CONSULTATION));
      }
      break;
    default:
      break;
    }
    
    dispatch(setServiceLocationAction(serviceType));
  }, [dispatch, isOrderContainBloodCollection, isOrderContainClinicVisit, isOrderContainOnlineConsultation]);

  useEffect(() => {
    dispatch(clearServiceLocationAction());
    dispatch(fetchClinic());
  }, []);

  const homeDescriptionList = useMemo(() => {
    if (isOrderContainBloodCollection) {
      return DESCRIPTION_DATA.BLOOD_COLLECTION;
    } else if (isOrderContainPrescription) {
      return DESCRIPTION_DATA.PRESCRIPTION;
    }

    return DESCRIPTION_DATA.TEST_KIT;
  }, [isOrderContainBloodCollection, isOrderContainPrescription]);


  const clinicsTimes: any = [];

  const getCloseDate = () => {
    clinics.map((item, key) => {
                
      const currentDate = {};
      const date = new Date();
      
      const now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );

      const currentHour = new Date(now_utc).getHours();
      const currentMinutes = new Date(now_utc).getMinutes();
      const timeRange = [ 'Morning', 'Afternoon', 'Evening',];          
      let currentRange = currentHour < 12 ? 0 : currentHour < 17 ? 1 : 2;
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
                      
      const isCurrentTime = (time:any) => {
        
        const hours = time.slice(0,2);
        const minuts = time.slice(3,5);

        if (currentHour == hours && currentMinutes < minuts) {
          return true;
        }

        return false;
      };

      const isCloseRange = (time:any) => {
        const hours = time.slice(0,2);
        const minuts = time.slice(3,5);

        if (currentHour < hours) {
          return true;
        }
        return false;
      };

      let dateItemIterator = Object.keys(item.disabledDates)[0];
      for (const dateItem in item.disabledDates) {
        
        const rangeTime = item.disabledDates[dateItem][timeRange[currentRange]];
        const objRange = Object.keys(rangeTime);
        let index = objRange.find(isCurrentTime);

        if (!index) {
          index = objRange.find(isCloseRange);
          if (!index) {
            if (dateItemIterator !== dateItem) {
              currentRange = 0;
              index = Object.keys(item.disabledDates[dateItem][timeRange[currentRange]])[0];
            }
          }
        }
        
        if (index && item.disabledDates[dateItem][timeRange[currentRange]][index]) {
          const newDate = new Date(dateItem);
          const finalDate = newDate.toDateString().slice(0, 3) + ', ' + newDate.getDate() + ' ' + monthNames[newDate.getUTCMonth()] + ' ' + newDate.getFullYear() + ' ' + 'at ' + index;
          clinicsTimes.push(finalDate);
        }
        dateItemIterator = dateItem;
      }

    });
    const compareDate = (a: string, b: string) => {
      const aStr = a.match(/\d/g);
      const bStr = b.match(/\d/g);
      let bDate: any;
      let aDate: any;
      let aTime: any;
      let bTime: any;

      
      if (aStr && aStr.length < 10) {
        aDate = +aStr[0];
      } else if (aStr) {
        aDate = aStr[0] + aStr[1];
      }

      if (bStr && bStr.length < 10) {
        bDate = +bStr[0];
      } else if (bStr) {
        bDate = bStr[0] + bStr[1];
      }

      if (aDate - bDate === 0) {
        
        if (aStr && bStr) {
          aTime = aStr.slice(aStr.length - 4).join('');
          bTime = bStr.slice(bStr.length - 4).join('');
          
          return +aTime - +bTime;
        }
         
      }
      
      return aDate - bDate;
    };
    clinicsTimes.sort(compareDate);
    return clinicsTimes[0];
  };
  
  return (
    <div className={style.wrapper}>
      <h1 className={style.h1}>Where would be more convenient for you?</h1>
      <div className={style.consultationTypes}>
        <div>
          <div>
            <div className={style.title}><IconHome /><span>My Preferred Address</span></div>
            <div className={style.description}>Your order will be delivered to your preferred address:</div>
            <ul>
              {
                homeDescriptionList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
          </div>
          <div className={style.footer}>
            <div className={style.footerDescription}></div>
            <div className={style.buttonContainer}>
              <CustomButton onClick={() => changeStep(ServiceLocationType.HOME)} text="Select" />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={style.title}><IconClinic /><span>Ferne&apos;s Partner Clinic</span></div>
            <div className={style.description}>Prefer to receive all your services in person? You could come into any of Ferne Health&apos;s Partner Clinic in:</div>
            <div className={style.lists}>
              <ul className={style.list}>
                {
                  clinics.map((item, key) => {
                    return (
                      <li key={key}>
                        {item.name}
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
          <div className={style.footer}>
            <div className={style.footerDescription}>
              Next available appointment:  {
                getCloseDate()
              }
            </div>
            <div className={style.buttonContainer}>
              <CustomButton onClick={() => changeStep(ServiceLocationType.CLINIC)} text="Select" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceLocation;