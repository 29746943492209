import { DatePicker } from '@material-ui/pickers';
import React, { useCallback, useState } from 'react';
import { BookingTime } from '../StepComponents/BookConsultation/consts';
import style from './style.module.scss';
import './overrides.scss';
import { format } from 'date-fns';
import classNames from 'classnames';
import { find, pickBy } from 'lodash';

interface Props {
  activeDate: string,
  activeTime: string | null, 
  changeDate: (date: string) => void, 
  changeTime: (value: string) => void, 
  bookingTime: BookingTime | { [key: string]: string[]; [key: number]: string[]; [key: symbol]: string[]; }, 
  title: string,
  withShortView?: boolean,
  range?: boolean,
  onChangeMonth?: (date: any) => void,
  bookingDates? : any,
}

const DateAndTimePicker: React.FC<Props> = ({ activeDate, activeTime, changeDate, changeTime, bookingTime, title, withShortView, range, onChangeMonth, bookingDates }) => {

  const [viewAll, setViewAll] = useState(!withShortView);

  const changeTimeView = useCallback(() => {
    setViewAll(true);
  }, [setViewAll]);

  const dtime_nums = (e: number, date: Date) => {
    const n = new Date(date);
    n.setDate(n.getDate() + e);
    return n.toISOString();
  };

  return (
    <section className={style.selectDate}>
      <h3>{title}</h3>
      <div className={style.dateAndTime}>
        <div className={classNames(style.date, style.cell)}>
          <DatePicker
            autoOk
            onMonthChange={onChangeMonth}
            disableToolbar
            views={['date']}
            disablePast
            variant='static'
            openTo='date'
            value={activeDate}
            onChange={(date) => changeDate(format(new Date(String(date)), 'yyyy-MM-dd'))}
            shouldDisableDate={(day) => {
              const newDay = bookingDates && Boolean(Object.keys(bookingDates).find(item => day && item === dtime_nums(1, day).split('T')[0]));
              return !newDay;
            }}
          />
        </div>
        <div className={classNames(style.time, style.cell)}>
          {!viewAll && bookingTime ?
            <div className={ range ? classNames(style.listTime, style.range) : style.listTime}>
              
              {
                Object.keys(
                  pickBy(
                    Object.values(bookingTime).reduce((acc, value) => ({...acc, ...value})), 
                    (value, key) => value
                  )
                ).filter((_, idx) => idx < 20).map((value, idx) => (
                  <button 
                    className={`${style.timeValue} ${value === activeTime ? style.active : null}`} 
                    key={idx}
                    onClick={() => changeTime(value)}
                  >
                    {value}
                  </button>
                ))
              }
                    
            </div> : bookingTime ? Object.keys(bookingTime).map((partOfDay, idx) => (
              <div className={style.allTime} key={idx}>
                {
                  Object.keys(bookingTime[partOfDay]).map((value: any, idx: number) => {
                    return bookingTime[partOfDay][value] ? true : false;
                  }).includes(true) ? <span>{partOfDay}:</span> : null
                }
                <div className={ range ? classNames(style.listTime, style.range) : style.listTime}>
                  {Object.keys(bookingTime[partOfDay]).map((value: any, idx: number) => {
                    return (
                      <button 
                        className={`${style.timeValue} ${value === activeTime ? style.active : null}`} 
                        key={idx}
                        onClick={() => changeTime(value)}
                        disabled={!bookingTime[partOfDay][value]}
                      >{value}</button>
                    );
                  })}
                </div>
              </div>
            )) : null}
          {withShortView && !viewAll && typeof bookingTime !== 'undefined' && <button className={style.viewButton} onClick={changeTimeView}>View All</button>}
        </div>
      </div>
    </section>
  );
};

export default DateAndTimePicker;