import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

interface Props {
  title: string | null;
  links: Array<{
    to: string,
    name: string,
    external: boolean,
  }>;
}

const FooterMenu: React.FC<Props> = ({title, links}) => {
  return (
    <ul className={style.menu}>
      <li className={style.item}>
        <strong className={style.headline}>
          {title}
        </strong>
      </li>
      {
        links.map((link, index) => (
          <li key={index} className={style.item}>
            {link.external ? (
              <a href={link.to} className={style.link}>{link.name}</a>
            ) : (
              <Link to={link.to} className={style.link}>{link.name}</Link>
            )}
            
          </li>
        ))
      }
    </ul>
  );
};

export default FooterMenu;