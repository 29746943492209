import React from 'react';
import style from './style.module.scss';

interface Card {
  text: string,
}

interface Props {
  data: Array<{
    attributes: Card;
  }>;
}

const SectionContentShipping: React.FC<Props> = ({data}) => {

  return (
    <div className={style.grid}>
      {data.map((item, index) => {
        return <div key={index} className={style.cell}>
          <div className={style.card}>
            {
              item && item.attributes.text ? (
                <div className={style.text} dangerouslySetInnerHTML={{__html: item.attributes.text}} />
              ) : null
            }
          </div>
        </div>;
      }
      )}
    </div>
  );
};

export default SectionContentShipping;