import React from 'react';

const IconHome: React.FC = () => {

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="23" 
      height="23" 
      viewBox="0 0 23 23" 
      fill="none"
    >
      <path 
        d="M2.29993 10.4666C2.29993 10.9172 2.63232 11.2791 3.17154 11.2791C3.4153 11.2791 3.65167 11.1535 3.8585 10.9836L11.1417 4.87492C11.3707 4.67548 11.6366 4.67548 11.8729 4.87492L19.1414 10.9836C19.3482 11.1535 19.5845 11.2791 19.8357 11.2791C20.3306 11.2791 20.6999 10.9689 20.6999 10.4813C20.6999 10.2007 20.5817 9.96428 20.3601 9.77962L18.2845 8.02899V4.87492C18.2845 4.54252 18.0629 4.32093 17.7305 4.32093H16.5339C16.2015 4.32093 15.9725 4.54252 15.9725 4.87492V6.09371L12.6781 3.32374C11.969 2.72542 11.053 2.72542 10.3365 3.32374L2.63971 9.77962C2.41811 9.97167 2.29993 10.2228 2.29993 10.4666ZM4.76705 17.1514C4.76705 18.2446 5.47616 18.9168 6.6137 18.9168H16.3862C17.5237 18.9168 18.2328 18.2446 18.2328 17.1514V11.7223L11.969 6.46304C11.6735 6.21928 11.319 6.22667 11.0383 6.46304L4.76705 11.7223V17.1514ZM13.4685 17.3804H9.53879V12.7564C9.53879 12.4092 9.76777 12.1876 10.1149 12.1876H12.8923C13.2395 12.1876 13.4685 12.4092 13.4685 12.7564V17.3804Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconHome;