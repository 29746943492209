import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';

interface Card {
  header: string,
  kits: string,
  should_buy: boolean,
}

interface Props {
  data: Array<{
    attributes: Card;
  }>;
}

const SectionContentKitUsage: React.FC<Props> = ({data}) => {
  return (
    <div className={style.card}>
      {
        data.map((item, index) => {
          return (
            item.attributes.should_buy ? (
              <div key={index} className={style.cell}>
                <div className={classNames(style.header, style.default)}>{item.attributes.header}</div>
                <div className={style.description} dangerouslySetInnerHTML={{__html: item.attributes.kits}} />
              </div>
            ) : (
              <div key={index} className={style.cell}>
                <div className={classNames(style.header, style.primary)}>{item.attributes.header}</div>
                <div className={style.description} dangerouslySetInnerHTML={{__html: item.attributes.kits}} />
              </div>
            )   
          );
        }) 
      }
    </div>
  );
};

export default SectionContentKitUsage;