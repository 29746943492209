import React from 'react';
import classNames from 'classnames';
import style from './style.module.scss';
import OrderSummary from '../OrderSummary';
import { StepTypes } from '../../../types/order';
import useWindowSize from '../../../hooks/useWindowSize';

interface Props {
  step: React.ReactNode,
  stepType: StepTypes,
}

const Step: React.FC<Props> = ({ step, stepType }) => {

  const [windowWidth] = useWindowSize();

  return (
    <>
      <div className={classNames('container', style.container)}>
        <div className={style.order}>
          <div className={style.step}>
            <div className={style.inner}>
              {step}
            </div>
          </div>
          {
            windowWidth > 1023 ? (
              <OrderSummary />
            ) : null
          }
        </div>
      </div>
      {stepType === StepTypes.BOOK_CONSULTATION && <div className={classNames('container', style.faqContainer)}>
      </div>}
    </>
  );
};

export default Step;