import React, { useCallback, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import { changeActiveDeliveryOptionAction, changeActiveShippingAddressAction, changeActiveShippingOptionAction } from '../../../../store/actions/shipping';
import { Address, ShippingOptionValues } from '../../../../types/shipping';
import RadioButtonsGroup from '../../../CustomRadioButtonsGroup';
import AddressForm from '../../AddressForm';
import BookTitle from '../../BookTitle';
import style from './style.module.scss';
import BookButtons from '../../BookButtons';
import { changeStepAction } from '../../../../store/actions/order';
import { StepTypes } from '../../../../types/order';
import { ProductFeeTypes } from '../../../../types/product';
import { getCartProductFees } from '../../../../store/selectors/cartProducts';
import { changeActiveDeliveryDetailsOptionAction, clearDeliveryDetails } from '../../../../store/actions/deliveryDetails';

const Shipping: React.FC = () => {

  const { shippingOptions, activeShippingOption, deliveryOptions } = useTypedSelector(state => state.shipping);
  const { activeDeliveryOption } = useTypedSelector(state => state.deliveryDetails);
  const dispatch = useTypedDispatch();
  const cartProductFees = useTypedSelector(getCartProductFees);

  const [shippingAddress, setShippingAddress] = useState<Address>({
    streetAddress: '',
    additionalInfo: '',
    postalCode: '',
  });

  const onChangeShipping = useCallback((type) => {
    dispatch(changeActiveShippingOptionAction(type));
    dispatch(clearDeliveryDetails());
  }, [dispatch]);

  const onClickBack = useCallback(() => {
    dispatch(changeStepAction(StepTypes.BOOK_BLOOD_COLLECTION));
  }, [dispatch]);

  const onClickContinue = useCallback(() => {
    dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    dispatch(changeActiveShippingAddressAction(shippingAddress));
  }, [dispatch, shippingAddress]);

  const isValidAddressForm = useMemo(() => {
    for (const key in shippingAddress) {
      if (!shippingAddress[key].length) {
        return false;
      }
    }
    
    return true;
  }, [shippingAddress]);


  const getActualFees = useCallback(() => {
    return cartProductFees
      .filter(fee => ![ProductFeeTypes.CONSULTATION_FEE, ProductFeeTypes.HOME_COLLECTION_FEE].includes(fee.type))
      .filter(fee => fee.name !== 'Test At A Clinic')
      .map(fee => ({
        id: fee.id,
        value: `${fee.name} (${fee.price ? `$${fee.price}` : 'FREE' })`,
        text: `${fee.name} (${fee.price ? `$${fee.price}` : 'FREE' })`,
        price: fee.price
      }));
  }, [cartProductFees]);

  const onChangeDelivery = useCallback((type) => {

    const checkedFee = getActualFees().find(item => item.value === type);
    if (checkedFee) {
      dispatch(changeActiveDeliveryDetailsOptionAction(checkedFee));
    }
  }, [dispatch, getActualFees]);

  const feesEmpty = getActualFees().length === 0 ? true : false;

  return (
    <div className={style.wrapper}>
      <BookTitle
        title="How would you like your order to be delivered?"
      />
      <div className={style.shippingContainer}>
        <RadioButtonsGroup
          optionsWithBorder
          options={shippingOptions}
          activeOption={activeShippingOption}
          onChange={onChangeShipping}
        />
      </div>
      {activeShippingOption?.value === ShippingOptionValues.SEPARATELY_APPOINTMENT && (
        <>
          <AddressForm
            border={false}
            title="Shipping address:"
            address={shippingAddress}
            setAddress={setShippingAddress}
          />
          {
            !feesEmpty ? (
              <div className={style.deliveryOptions}>
                <div className={style.title}>Select delivery option:</div>
                <RadioButtonsGroup
                  options={getActualFees()}
                  activeOption={activeDeliveryOption}
                  onChange={onChangeDelivery}
                />
              </div>
            ) : null
          }
        </>
      )}
      {
        !feesEmpty ? (
          <BookButtons
            onClickBack={onClickBack} 
            onClickContinue={onClickContinue} 
            activeContinueButton={activeShippingOption?.value === ShippingOptionValues.DURING_APPOINTMENT || (activeDeliveryOption && isValidAddressForm) ? true : false} 
            buttonText="Continue" 
          />
        ) : (
          <BookButtons
            onClickBack={onClickBack} 
            onClickContinue={onClickContinue} 
            activeContinueButton={activeShippingOption?.value === ShippingOptionValues.DURING_APPOINTMENT || isValidAddressForm ? true : false} 
            buttonText="Continue" 
          />
        )
      }
      
    </div>
  );
};

export default Shipping;