import React, { useState } from 'react';
import IconMinus from '../../assets/images/icons/minus';
import IconPlus from '../../assets/images/icons/plus';
// styles
import style from './style.module.scss';
import AddButton from '../AddButton';


interface Props {
  product: number;
  quantity: number;
  counter?: boolean;
  onAdd: () => void;
  disabled: boolean;
}

const AddToCart: React.FC<Props> = ({counter, onAdd, disabled}) => {

  const [count, setCount] = useState(1);

  const handleIncrement = () => {
    setCount((prevCount: number) => prevCount + 1);
  };
  
  const handleDecrement = () => {
    setCount((prevCount => Math.max(prevCount - 1, 1)));
  };

  return (
    <div className={style.module}>
      {
        counter ? (
          <div className={style.cell}>
            <div className={style.counter}>
              <button className={style.decrement} disabled={count === 1 ? true : false} onClick={handleDecrement}><IconMinus /></button>
              <span className={style.quality}>{count}</span>
              <button className={style.increment} onClick={handleIncrement}><IconPlus /></button>
            </div>
          </div>
        ) : null
      }
      <div className={style.cell}>
        <AddButton
          onAdd={() => onAdd()}
          disabled={disabled}
        />
      </div>
      
    </div>
  );
};

export default AddToCart;