import React from 'react';
import IconMinus from '../../assets/images/icons/minus';
import IconPlus from '../../assets/images/icons/plus';
import { Product } from '../../types/product';
import style from './style.module.scss';

interface Props {
  product: Product,
  value: number,
  onChange: (product: Product, type: 'inc' | 'dec') => void,
}

const QuantityCounter: React.FC<Props> = ({ product, value, onChange}) => {
  return (
    <div className={style.counter}>
      <button onClick={() => onChange(product, 'dec')}><IconMinus /></button>
      <span>{value}</span>
      <button onClick={() => onChange(product, 'inc')}><IconPlus /></button>
    </div>
  );
};

export default QuantityCounter;