import {
  ProductsCategoryState,
  ProductsCategoryAction,
  ProductsCategoryActionTypes
} from '../../types/productsСategory';

const initialState: ProductsCategoryState = {
  list: [],
  loading: false,
  error: null
};

export const productsCategoryReducer = (state = initialState, action: ProductsCategoryAction): ProductsCategoryState => {
  switch (action.type) {
  case ProductsCategoryActionTypes.FETCH_PRODUCTS_CATEGORY:
    return {loading: true, error: null, list: []};
  case ProductsCategoryActionTypes.FETCH_PRODUCTS_CATEGORY_SUCCESS:
    return {loading: false, error: null, list: action.payload};
  case ProductsCategoryActionTypes.FETCH_PRODUCTS_CATEGORY_ERROR:
    return {loading: false, error: action.payload, list: []};
  default:
    return state;
  }
};
