import {
  ProductState,
  ProductAction,
  ProductActionTypes
} from '../../types/product';

const initialState: ProductState = {
  product: null,
  loading: false,
  error: null
};

export const productReducer = (state = initialState, action: ProductAction): ProductState => {
  switch (action.type) {
  case ProductActionTypes.FETCH_PRODUCT:
    return {loading: true, error: null, product: null};
  case ProductActionTypes.FETCH_PRODUCT_SUCCESS:
    return {loading: false, error: null, product: action.payload};
  case ProductActionTypes.FETCH_PRODUCT_ERROR:
    return {loading: false, error: action.payload, product: null};
  case ProductActionTypes.CLEAR_PRODUCT:
    return {...state, product: null};
  default:
    return state;
  }
};