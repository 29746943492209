import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearProduct, fetchProduct } from '../../store/actions/product';
import { useTypedDispatch, useTypedSelector } from '../../hooks/productsTypedSelector';
import { useParams } from 'react-router-dom';
import { Product as ProductType, ProductState, ProductTypes } from '../../types/product';
import style from './style.module.scss';
import Breadcrumb from '../../components/Breadcrumb';
import CustomSwiper from '../../components/CustomSwiper';
import Faq from '../../components/Faq';

import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import HowWorks from '../../components/HowWorks';
import Related from '../../components/Related';
import { fetchProductsCategory } from '../../store/actions/productsCategory';
import { ProductsCategoryState } from '../../types/productsСategory';
import { fetchProductFaq } from '../../store/actions/productFaq';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';

import SectionContentTested from '../../components/SectionContentTested';
import SectionContentSymptoms from '../../components/SectionContentSymptoms';
import SectionContentKit from '../../components/SectionContentKit';
import SectionContentInclude from '../../components/SectionContentInclude';
import SectionContentShipping from '../../components/SectionContentShipping';
import SectionContentKitUsage from '../../components/SectionContentKitUsage';

import AddToCart from '../../components/AddToCart';
import DescriptionPoints from '../../components/DescriptionPoints';
import { fetchCategory } from '../../store/actions/category';
import { CategoryState } from '../../types/category';
import SectionContentDefault from '../../components/SectionContentDefault';
import ProductVariations from '../../components/ProductVariations';

import _uniqueId from 'lodash/uniqueId';
import ClinicEmployees from '../../components/ClinicEmployees';
import DateAndTimePicker from '../../components/OrderComponents/DateAndTimePicker';
import BookTypePicker from '../../components/OrderComponents/BookTypePicker';
import { changeActiveDateAction, changeActiveDoctorAction, changeActiveTimeAction, changeCalendarMonth, clearDoctorDataAction, fetchDoctors } from '../../store/actions/bookConsultation';
import { initialBookingTime } from '../../components/OrderComponents/StepComponents/BookConsultation/consts';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addProductToCartAction, removeProductFromCartAction } from '../../store/actions/cartProducts';
import ProductVariationsSteps from '../../components/ProductVariationsSteps';

import classNames from 'classnames';
import { format, parseISO } from 'date-fns';

interface breadcrumbLink {
  to: string,
  name: string | null,
  external: boolean,
}

export const Product: React.FC = () => {
  const dispatch = useDispatch();
  const { id, slug } = useParams<{ id: string, slug: string}>();

  const [expanded, setExpanded] = React.useState<number | boolean>(true);
  const [expandedDefault, setExpandedDefault] = React.useState(true);

  const { doctors, activeDoctor, activeDate, activeTime, loadingBookConsultation } = useTypedSelector(state => state.bookConsultation);
  
  const [bookingTime, setBookingTime] = useState(initialBookingTime);

  const changeDoctor = useCallback((doctorId) => {
    if (activeDoctor && activeDoctor.id !== doctorId) {
      dispatch(changeActiveDoctorAction(doctorId));
      dispatch(changeActiveTimeAction(null));
    }
    const getDoctor = doctors.find((el) => {
      return el.id === doctorId;
    });

    if (getDoctor) {
      dispatch(changeActiveDateAction(Object.keys(getDoctor.disabledDates)[0]));
    }
  }, [activeDoctor, dispatch]);

  const changeDate = (date: string) => {
    dispatch(changeActiveDateAction(date));
    dispatch(changeActiveTimeAction(null));
  };

  const changeTime = (time: string) => {
    dispatch(changeActiveTimeAction(time));
  };
  const { products } = useTypedSelector(state => state.cartProducts);
  const {product, loading}: ProductState = useTypedSelector(state => state.product);
  const {list}: ProductsCategoryState = useTypedSelector(state => state.productsCategory);
  const relatedProducts = list.slice(0, 5);
  const {data}: CategoryState = useTypedSelector(state => state.category);

  const relatedProductsFilter = relatedProducts.filter(field => field.id !== product?.id);
 
  const productContent: any = product?.content;
  const productDescriptionPoints = product?.description_points;

  const resultHowWork = filter(productContent, obj => obj.collection_name === 'How it work');
  const resultSectionKit = filter(productContent, obj => obj.layout === 'What\'s in the kit');
  const resultSectionTested = filter(productContent, obj => obj.layout === 'What\'s tested');
  const resultSectionSymptoms = filter(productContent, obj => obj.layout === 'Related Symptoms');
  const resultSectionInclude = filter(productContent, obj => obj.layout === 'What\'s Included');
  const resultSectionKitUsage = filter(productContent, obj => obj.layout === 'Kit Usage');
  const resultSectionShipping = filter(productContent, obj => obj.layout === 'Shipping & Return Policy');
  const resultSectionDefault = filter(productContent, obj => obj.layout === 'default_content');

  const resultClinicEmployees = product?.clinic_employees;

  const [breadcrumbLinks, setBreadcrumbLinks] = useState<breadcrumbLink[] | null>(null);
  const [mainImage, setMainImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchProduct(String(slug)));

  }, [dispatch, id, slug]);

  useEffect(() => {
    if (product && product.category.name) {

      dispatch(fetchProductsCategory(Number(product.category_id)));

      setBreadcrumbLinks(prev => {
        return [
          {
            name: 'Home',
            to: 'https://www.fernehealth.com/',
            external: true,
          },
          {
            name: 'Shop',
            to: '/',
            external: false,
          },
          {
            name: product?.category.name,
            to: `/category/${product?.category.slug}`,
            external: false,
          },
          {
            name: product && product.name,
            to: '',
            external: false,
          }
        ];
      });
    }

    if (product && product.main_image) {
      setMainImage(`${process.env.REACT_APP_API_DOMAIN}/products_images/${product.main_image}`);
    }

    if (product && product.product_type === 'online_consultation' && product.clinic_employees || product && product.variations_type === 'multiple_products' && product.product_type === 'prescription') {
      dispatch(fetchDoctors(product.clinic_employees));
    }
  }, [product]);

  const theme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: 'none',
            paddingLeft: '0px',
            '&:before': {
              display: 'none',
            },

            '&.Mui-expanded': {
              margin: 0,
            },
          
          },
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            paddingLeft: '0px',
            minHeight: '8rem',
            width: '100%',

            '&.Mui-expanded': {
              minHeight: '8rem',
          
            },

          },
          content: {
            margin: '0',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '0 0 3rem',
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontWeight: 'bold',
            fontFamily: 'Avenir Next',
            fontSize: '2rem',
            lineHeight: '1.35',
          },
        }
      }
    },
  });
  
  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const addProductToCart = useCallback((product: ProductType, productVariation?: number) => {
    if (productVariation && product.product_variation[productVariation].related_product) {
      if (product.product_variation[productVariation].related_product.product_type === 'online_consultation') {
        dispatch(addProductToCartAction({...product.product_variation[productVariation].related_product, consultation: {
          date: activeDate ? activeDate : null,
          time: activeTime ? activeTime : null,
          doctor: activeDoctor ? activeDoctor.name : null,
        }}));
      } else {
        dispatch(addProductToCartAction({...product.product_variation[productVariation].related_product}));
      }
    } else {
      if (product.product_type === 'online_consultation') {
        dispatch(addProductToCartAction({...product, consultation: {
          date: activeDate ? activeDate : null,
          time: activeTime ? activeTime : null,
          doctor: activeDoctor ? activeDoctor.name : null,
        }}));
      } else if (!isNil(productVariation)) {
        dispatch(addProductToCartAction({...product, product_variation: [product.product_variation[productVariation]]}));
      } else {
        dispatch(addProductToCartAction(product));
      }
    }
  }, [dispatch, activeTime, activeDate, activeDoctor]);

  // const availableConsultation = useMemo(() => {
  //   return [...products].filter(item => item.product_type === 'online_consultation').length !== 0 && product && product.product_type === 'online_consultation';
  // }, [products, product]);

  const availableConsultation = useMemo(() => {
    return [...products].filter(item => item.consultation !== null).length !== 0 && product && product.product_type === 'online_consultation';
    //return [...products].filter(item => item.product_type === 'online_consultation').length !== 0 && product && product.product_type === 'online_consultation';
  }, [products, product]);

  

  const availableClinicVisit = useMemo(() => {
    return [...products].filter(item => item.product_type === 'clinic_visit').length !== 0 && product && product.product_type === 'clinic_visit';
  }, [products, product]);

  const onChangeMonth = useCallback((date) => {
    const dates = [format(parseISO(date.toISOString()), 'dd-MM-yyyy').toString(), format(parseISO(new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy').toString()];
    if (product && product.clinic_employees) {
      dispatch(changeCalendarMonth(product.clinic_employees, dates));
    }
  }, [product]);

  const swap = (input: string) => {
    const inputCopy = input.split('/');
    const [first, second, last] = [inputCopy.splice(0,1), inputCopy.splice(1,1) , inputCopy.splice(-1)];
    return  [...second, ...first, ...last].join('-');
  };

  const withoutPastTimeDates = useMemo(() => {
    if (activeDoctor && Object.keys(activeDoctor.disabledDates)[0] === activeDate && Object.keys(activeDoctor.disabledDates).length !== 0) {
      const dateNow = new Date().getTime();
      const newDisableDates = {...activeDoctor.disabledDates};
      const keys = Object.keys(activeDoctor.disabledDates[activeDate]);
      
      for (let i = 0; i <= keys.length; i++) {
        for (const key in activeDoctor.disabledDates[activeDate][keys[i]]) {
          if (new Date(`${activeDate} ${key}`).getTime() < dateNow) {
            newDisableDates[activeDate][keys[i]][key] = false;
          }
        }
      }
      return newDisableDates;
    } else if (activeDoctor && activeDoctor.disabledDates) {
      return activeDoctor.disabledDates;
    }
  }, [activeDoctor, activeDate]);

  return (
    <div className={style.single}>
      <div className="container">
        {/* product head */}
        <div className={style.head}>
          <div className={style.breadcrumb}>
            <Breadcrumb links={breadcrumbLinks} />
          </div>
        </div>
        {product && <div className={classNames(style.slider, 'main-slider')}>
          {
            product && product?.media.length > 1 ? (
              <CustomSwiper parameters={{ slidesPerView: 1, followFinger: false, loop: true, spaceBetween: 0 }} classesForButtons={['.productNextButtonSwiper', '.productPrevButtonSwiper']} >
                {
                  product?.media.map((slide: { id: number; original_url: string; }) => (
                    <div 
                      key={slide.id}
                      className={style.slide}
                    >
                      <img src={slide.original_url} alt={''} />
                    </div>
                  ))
                }
              </CustomSwiper>
            ) : (
              product?.main_image !== null ? (
                <div className={style.slide}>
                  <img
                    className={style.preview}
                    src={mainImage}
                    alt={''}
                  />
                </div>
              ) : null
          
            )
          }
        </div>
        }
        {/* product body */}
        <div className={style.body}>
          {/* product main */}
          <div className={style.product}>
            <div className={style.aside}>
              <h1 className={style.title}>{product && product.name}</h1>
              <div className={style.price}>
                {
                  product ? (
                    !product.min_variation_price && product.price === null ? (
                      <></>
                    ) : (
                      product.price === null ? `From $${product.min_variation_price}` : `$${product.price}`
                    )

                  ) : null
                }
              </div>
            </div>
            <div className={style.panel}>
              {
                product && product.description ? (
                  <div className={style.description} dangerouslySetInnerHTML={{__html: product.description}} />
                ) : null
              }
              {
                product && product.product_type === 'online_consultation' && !availableConsultation && !loadingBookConsultation ? (
                  <div className={style.doctors}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <BookTypePicker 
                        typeData={doctors}
                        activeTypeData={activeDoctor}
                        changeActiveTypeData={changeDoctor}
                        title='Select a doctor:'
                      />
                      <DateAndTimePicker 
                        activeDate={activeDate}
                        activeTime={activeTime}
                        onChangeMonth={onChangeMonth}
                        changeDate={changeDate}
                        changeTime={changeTime}
                        bookingDates={withoutPastTimeDates}
                        bookingTime={activeDoctor ? activeDoctor.disabledDates[activeDate] : bookingTime}
                        title={'Select appointment time:'}
                        withShortView
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                ) : null
              }
              {availableConsultation && product && product.product_type === 'online_consultation' ? (
                <div className={style.warning}>There is already a consultation in the cart</div>
              ) : null}

              <div className={style.options}>
                {
                  productDescriptionPoints && productDescriptionPoints.length ? (
                    <DescriptionPoints list={productDescriptionPoints} />
                  ) : null
                }

                {
                  product && product.variations_type === 'single_product' && product.price !== null ? (
                    <div className={style.addToCart}>
                      <AddToCart 
                        disabled={Boolean(availableConsultation) || Boolean(availableClinicVisit) || (product.product_type === ProductTypes.ONLINE_CONSULTATION && !activeTime) } 
                        onAdd={() => addProductToCart(product)} 
                        product={product.id} 
                        quantity={0} 
                      />
                    </div>
                  ) : null
                }

                {
                  product && product.variations_type === 'multiple_products' && product.product_type === 'prescription' ? (
                    <div className={style.variations}>
                      <ProductVariationsSteps
                        product={product}
                        typeData={doctors}
                        activeTypeData={activeDoctor}
                        changeActiveTypeData={changeDoctor}
                        activeDate={activeDate}
                        activeTime={activeTime}
                        onChangeMonth={onChangeMonth}
                        changeDate={changeDate}
                        changeTime={changeTime}
                        bookingDates={activeDoctor && activeDoctor.disabledDates}
                        bookingTime={activeDoctor ? activeDoctor.disabledDates[activeDate] : bookingTime}
                        variations={product.product_variation}
                        questions={product.question}
                        onAdd={(index) => addProductToCart(product, index)}
                      />
                    </div>
                  ) : null
                }

                {
                  product && product.variations_type === 'multiple_products' && product.product_type === 'blood_test' ? (
                    <ProductVariations onAdd={(index) => addProductToCart(product, index)} data={product.product_variation} />
                  ) : null
                }

                {
                  product && product.variations_type === 'multiple_products' && product.product_type === 'testing_kit' ? (
                    <ProductVariations onAdd={(index) => addProductToCart(product, index)} data={product.product_variation} />
                  ) : null
                }

              </div>
            </div>
          </div>

          {/* product accordion */}
          <div className={style.accordion}>
            <ThemeProvider theme={theme}>
              {
                product && product.product_type === 'online_consultation' ? (

                  <Accordion
                    key={9}
                    defaultExpanded
                    onChange={handleChange(9)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        Meet the Doctors
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ClinicEmployees data={resultClinicEmployees} />
                    </AccordionDetails>
                  </Accordion>
                ) : null
              }

              {
                resultSectionTested.length ? (
                  
                  <Accordion
                    key={1}
                    defaultExpanded
                    onChange={handleChange(1)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        {resultSectionTested[0].layout}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionContentTested data={resultSectionTested} />
                    </AccordionDetails>
                  </Accordion>

                ) : null
              }
            
              {
                resultSectionSymptoms.length ? (

                  <Accordion
                    key={2}
                    defaultExpanded
                    onChange={handleChange(2)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        {resultSectionSymptoms[0].layout}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionContentSymptoms data={resultSectionSymptoms} />
                    </AccordionDetails>
                  </Accordion>

                ) : null
              }

              {
                resultSectionKit.length ? (

                  <Accordion
                    key={3}
                    defaultExpanded
                    onChange={handleChange(3)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        {resultSectionKit[0].layout}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionContentKit data={resultSectionKit} />
                    </AccordionDetails>
                  </Accordion>

                ) : null
              }

              {
                resultSectionInclude.length ? (

                  <Accordion
                    key={4}
                    defaultExpanded
                    onChange={handleChange(4)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        {resultSectionInclude[0].layout}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionContentInclude data={resultSectionInclude} />
                    </AccordionDetails>
                  </Accordion>

                ) : null
              }

              {
                resultSectionKitUsage.length ? (

                  <Accordion
                    key={5}
                    defaultExpanded
                    onChange={handleChange(5)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        {resultSectionKitUsage[0].layout}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionContentKitUsage data={resultSectionKitUsage} />
                    </AccordionDetails>
                  </Accordion>

                ) : null
              }

              {
                resultSectionShipping.length ? (

                  <Accordion
                    key={6}
                    defaultExpanded
                    onChange={handleChange(6)}
                  >
                    <AccordionSummary
                      expandIcon={<span className='accordion-icon' />}
                    >
                      <Typography variant='body1'>
                        {resultSectionShipping[0].layout}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionContentShipping data={resultSectionShipping} />
                    </AccordionDetails>
                  </Accordion>

                ) : null
              }

              {
                

                resultSectionDefault.length ? (
                  resultSectionDefault.map((obj, index) => {
                    const uniqueIndex = Number('1.1' + index);
                    return (
                      <Accordion
                        key={uniqueIndex}
                        defaultExpanded
                        onChange={handleChange(uniqueIndex)}
                      >
                        <AccordionSummary
                          expandIcon={<span className='accordion-icon' />}
                        >
                          <Typography variant='body1'>
                            {obj.attributes.header}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SectionContentDefault data={obj} />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : null
              }

            </ThemeProvider>
          </div>
        </div>
      </div>
      {/* end container */}

      {/* product info */}
      {
        resultHowWork.length ? (
          <div className={style.how}>
            <HowWorks list={resultHowWork} />
          </div>
        ) : null
      }
      
      {
        relatedProducts.length ? (
          <div className='container'>
            <Related products={relatedProductsFilter} />
          </div>
        ) : null
      }

      {
        product && product.faqs.length !== 0 ? (
          <div className={style.faq}>
            <div className='container'>
              <Faq title={'Frequently Asked Questions'} faq={product.faqs[0].questions} multiply />
            </div>
          </div>
        ) : null
      }
      
    </div>
    
  );
};