import { ClickAwayListener, createTheme, ThemeProvider, Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

interface Props extends TooltipProps{
  title: string,
  openTooltip: boolean,
  setOpenTooltip: (value: boolean) => void,
  children: JSX.Element,
}

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Avenir Next',
          fontSize: '1.5rem',
          lineHeight: '2.2rem',
          background: '#f2f2f2',
          borderRadius: '10px',
          color: '#000',
          fontStyle: 'normal',
          fontWeight: 'normal',       
          padding: '8px 10px',
          '@media (max-width: 1023px)': {
            fontSize: '1.1rem',
            lineHeight: '1.5rem',
            maxWidth: '152px',
          }
        },
        tooltipPlacementRight: {
          marginLeft: '2px !important',
        },
        tooltipPlacementLeft: {
          marginRight: '2px !important',
        },
        tooltipPlacementTop: {
          marginBottom: '2px !important',
        },
        tooltipPlacementBottom: {
          marginTop: '2px !important',
        },
        arrow: {
          color: '#f2f2f2',
        },
      },
    }
  },
});

const CustomTooltip: React.FC<Props> = ({ title, openTooltip, setOpenTooltip, children, ...props }) => {
  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <div>
        <ThemeProvider theme={theme}>
          <Tooltip
            {...props}
            onClose={() => setOpenTooltip(false)}
            open={openTooltip}
            title={title}
          >
            {children}
          </Tooltip>
        </ThemeProvider>
      </div>
    </ClickAwayListener>
  );
};

export default CustomTooltip;