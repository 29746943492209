import { CartProductsAction, CartProductsActionTypes, CartProductsState } from '../../types/cartProducts';

const initialState: CartProductsState = {
  products: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : [],
  loading: false,
  error: null,
};

export const cartProductsReducer = (state = initialState, action: CartProductsAction): CartProductsState => {
  switch (action.type) {
  case CartProductsActionTypes.ADD_PRODUCT_TO_CART:
    const products = [...state.products, action.payload];

    localStorage.setItem('cart', JSON.stringify([...state.products, action.payload]));

    return {
      ...state,
      products,
    };
  case CartProductsActionTypes.REMOVE_PRODUCT_FROM_CART:

    const newProducts = state.products.filter((item) => {
      if(action.payload.variationId) {
        if(action.payload.id === item.id && action.payload.variationId === item.product_variation[0].id) {
          return false;
        } else {
          return true;
        }
      } else {
        if(action.payload.id === item.id) {
          return false;
        } else {
          return true;
        }
      }
    });

    localStorage.setItem('cart', JSON.stringify(newProducts));

    return {
      ...state,
      products: newProducts,
    };
  case CartProductsActionTypes.DECREMENT_PRODUCT_FROM_CART: {
    const newProducts = [...state.products].reverse();
    newProducts
      .splice([...newProducts].findIndex(item => item.id === action.payload.id && (item.product_variation[0] && action.payload.product_variation[0] ? item.product_variation[0].id === action.payload.product_variation[0].id : true)), 1);
    newProducts.reverse();
    localStorage.setItem('cart', JSON.stringify(newProducts));
    
    return {
      ...state,
      products: newProducts,
    };
  }
  case CartProductsActionTypes.CLEAR_CART: 
    localStorage.setItem('cart', JSON.stringify([]));
    return {...state, products: []};
  default:
    return {...state};
  }
};