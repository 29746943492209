import React from 'react';
import CustomSwiper from '../CustomSwiper';
import style from './style.module.scss';

interface Card {
  header: string,
  summary: string, 
}

interface Props {
  data: Array<{
    attributes: {
      tested: Card[];
    }
  }>;
}

const SectionContentTested: React.FC<Props> = ({data}) => {
  const list = data[0].attributes.tested;
  
  return (
    <div className={style.grid}>
      <div className='swiper-stretch pagination-relative'>
        <CustomSwiper parameters={{ slidesPerView: 'auto', followFinger: false, loop: false, spaceBetween: 10 }} classesForButtons={['.swiper-button-next', '.swiper-button-prev']} >
          {list.map((item, index) => (
            <div key={index} className={style.cell}>
              <div className={style.card}>
                <div className={style.inner}>
                  <div className={style.title}>
                    {item.header}
                  </div>
                  <div className={style.description}>
                    {item.summary}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CustomSwiper>
      </div>
      
    </div>
  );
};

export default SectionContentTested;