
import { ProductsCategoryAction, ProductsCategoryActionTypes } from '../../types/productsСategory';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<ProductsCategoryAction>) => void

export const fetchProductsCategory = (id: number): ReturnValue => {
  return async (dispatch: Dispatch<ProductsCategoryAction>) => {
    try {
      dispatch({
        type: ProductsCategoryActionTypes.FETCH_PRODUCTS_CATEGORY,
      });
      const response = await axios.get(`${process.env.REACT_APP_API}/product/list?category_id=${id}`);
      dispatch({
        type: ProductsCategoryActionTypes.FETCH_PRODUCTS_CATEGORY_SUCCESS,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: ProductsCategoryActionTypes.FETCH_PRODUCTS_CATEGORY_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};