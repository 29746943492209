import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import IconClose from '../../assets/images/icons/close';
import { useTypedDispatch } from '../../hooks/productsTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { addProductToCartAction, decrementProductFromCartAction } from '../../store/actions/cartProducts';
import { Product } from '../../types/product';
import { formateCartPrice } from '../../utils/formatting';
import { ICartProduct } from '../CartProductList';
import QuantityCounter from '../QuantityCounter';
import style from './style.module.scss';

interface Props {
  product: ICartProduct;
  id: number;
  onRemove: (index: number, variationId?: number) => void;
}

const CartProduct: React.FC<Props> = ({ product, onRemove, id }) => {
  const dispatch = useTypedDispatch();

  const [windowWidth] = useWindowSize();
  const history = useHistory();

  const changeQuantity = (product: Product, type: 'inc' | 'dec') => {
    switch (type) {
    case 'inc':
      dispatch(addProductToCartAction(product));
      break;
    case 'dec':
      dispatch(decrementProductFromCartAction(product));
      break;
    }
  };

  const handleEditClick = useCallback(() => {
    if (product.initialProduct.product_type === 'online_consultation') {
      history.push(`/products/${product.slug}`);
      
      onRemove(id);
    }
  }, [product]);

  return (
    <div className={style.product}>
      <img src={`${process.env.REACT_APP_API_DOMAIN}/products_images/${product.initialProduct.preview_image}`} alt="product" />
      <div className={style.content}>
        <header>
          <div>
            <Link to={`/products/${product.slug}`}>
              {product.title}
            </Link>
            <button onClick={() => onRemove(id, product.variationId)}><IconClose /></button>
          </div>
          {product.variationName && <div className={style.variation}>{product.variationName}</div>}
          {product.consultation && (windowWidth > 1023 ? (
            <div className={style.consultation}>
              <div>Date: {product.consultation.date}</div>
              <div>Time: {product.consultation.time}</div>
              <div>Doctor: {product.consultation.doctor}</div>
            </div>
          ) : (
            <div className={style.consultation}>
              <div>{`${product.consultation.date}, ${product.consultation.time}`}</div>
              <div>with {product.consultation.doctor}</div>
            </div>
          )
          )}
        </header>
        <footer className={style.footer}>
          <div>
            {product.consultation 
              // ? <button onClick={handleEditClick} className={style.edit}>Edit</button> 
              ? null
              : product.quantityEditable && product.price !== 0
                ? <div><QuantityCounter product={product.initialProduct} value={product.quantity} onChange={changeQuantity} /></div>
                : <div>Quantity: {product.quantity}</div>
            }
          </div>
          <div className={style.price}>${formateCartPrice(product.price * product.quantity)}</div>
        </footer>
      </div>
    </div>
  );
};

export default CartProduct;