
import { CategoryAction, CategoryActionTypes } from '../../types/category';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<CategoryAction>) => void

export const fetchCategory = (id: number): ReturnValue => {
  return async (dispatch: Dispatch<CategoryAction>) => {
    try {
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORY
      });
      const response = await axios.get(`${process.env.REACT_APP_API}/category/${id}`);
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORY_SUCCESS,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORY_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};