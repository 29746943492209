import { AxiosResponse } from 'axios';

export interface Doctor {
  type: 'doctor',
  id: number,
  name: string,
  photo: string,
  disabledDates: {
    [key: string | number | symbol]: {
      [key: string | number | symbol]: {
        [key: string | number | symbol]: boolean;
      },
    },
  },
}

export interface DoctorsState {
  doctors: Doctor[];
  activeDoctor: Doctor | undefined;
  activeDate: string,
  activeTime: string | null,
  loadingBookConsultation: boolean;
  error: null | string;
}

export enum DoctorsActionTypes {
  FETCH_DOCTORS = 'FETCH_DOCTORS',
  FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS',
  FETCH_DOCTORS_ERROR = 'FETCH_DOCTORS_ERROR',
  CHANGE_ACTIVE_DOCTOR = 'CHANGE_ACTIVE_DOCTOR',
  CHANGE_ACTIVE_DATE_DOCTOR = 'CHANGE_ACTIVE_DATE_DOCTOR',
  CHANGE_ACTIVE_TIME_DOCTOR = 'CHANGE_ACTIVE_TIME_DOCTOR',
  CLEAR_DOCTOR_DATA = 'CLEAR_DOCTOR_DATA',
  CHANGE_MONTH = 'CHANGE_MONTH_DOCTORS',
}

export interface FetchDoctorsAction {
  type: DoctorsActionTypes.FETCH_DOCTORS;
}

export interface FetchDoctorsSuccessAction {
  type: DoctorsActionTypes.FETCH_DOCTORS_SUCCESS;
  payload: Doctor[];
}

export interface FetchDoctorsErrorAction {
  type: DoctorsActionTypes.FETCH_DOCTORS_ERROR;
  payload: string;
}

export interface ChangeActiveDoctor {
  type: DoctorsActionTypes.CHANGE_ACTIVE_DOCTOR;
  payload: number,
}

export interface ChangeActiveDate {
  type: DoctorsActionTypes.CHANGE_ACTIVE_DATE_DOCTOR;
  payload: string,
}

export interface ChangeActiveMonth {
  type: DoctorsActionTypes.CHANGE_MONTH;
  payload: Doctor[];
}

export interface ChangeActiveTime {
  type: DoctorsActionTypes.CHANGE_ACTIVE_TIME_DOCTOR;
  payload: string | null,
}

export interface ClearDoctorData {
  type: DoctorsActionTypes.CLEAR_DOCTOR_DATA;
}

export type DoctorsAction = FetchDoctorsAction | FetchDoctorsSuccessAction | FetchDoctorsErrorAction | ChangeActiveDoctor | ChangeActiveDate | ChangeActiveTime | ClearDoctorData | ChangeActiveMonth;