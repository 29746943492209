import React, { useState } from 'react';
import { useTypedDispatch } from '../../hooks/productsTypedSelector';
import { setChangeOrderIDAction } from '../../store/actions/order';
import style from './style.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  order: any;
}

const PaymentAtome: React.FC<Props> = ({text, order}) => {

  const dispatch = useTypedDispatch();
  const [paying, setPaying] = useState(false);
  const [paypalErrorMessage, setPaypalErrorMessage] = useState<any>('');

  const handClick = () => {

    setPaying(true);

    fetch(`${process.env.REACT_APP_API}/order/create`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(order)
    })
      .then((response) => response.json())
      .then((details) => {
        
        if (details.errors || details.message) {
          const listError = Object.values(details.errors || details.message);
          setPaypalErrorMessage(listError);
        } else {
          const redirectURL = details.data.redirectUrl;
          dispatch(setChangeOrderIDAction(details.data.order_id));
          window.location.href = redirectURL;
        }
      })
      .catch((err) => {
        
      })
      .finally(() => {
        setPaying(false);
      });

  };

  const closeAlert = () => {
    setPaypalErrorMessage('');
    setPaying(false);
  };

  return (
    <>
      <button
        className={`btn ${paying ? 'btn-load' : ' btn-default'}`}
        onClick={handClick}
        disabled={paying ? true : false}
      >
        {paying ? (
          <svg className='spinner' viewBox='0 0 50 50'>
            <circle
              className='path'
              cx='25'
              cy='25'
              r='20'
              fill='none'
              strokeWidth='5'
            ></circle>
          </svg>
        ) : text}
      </button>
      {paypalErrorMessage !== '' ? (
        <div className={style.alert}>
          {paypalErrorMessage}
          <button
            onClick={closeAlert}
            disabled={paying}
            className={style.close}
          >
            {paying ? (
              <svg className='spinner' viewBox='0 0 50 50'>
                <circle
                  className='path'
                  cx='25'
                  cy='25'
                  r='20'
                  fill='none'
                  strokeWidth='5'
                ></circle>
              </svg>
            ) : '&times;'}
          </button>
        </div>
      ) : null }
    </>
  )
  ;
};

export default PaymentAtome;