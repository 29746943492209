import React, {useEffect, useState} from 'react';
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import style from './style.module.scss';
import { setChangeOrderIDAction } from '../../store/actions/order';
import { useTypedDispatch } from '../../hooks/productsTypedSelector';
import { useHistory } from 'react-router-dom';

interface Props {
  order: any;
}

interface CheckoutFormProps {
  total: any;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({total}) => {

  const history = useHistory();
  const dispatch = useTypedDispatch();
  const [messages, addMessage] = useState('');
  const [paypalErrorMessage, setPaypalErrorMessage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [paying, setPaying] = useState(false);


  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaying(true);
    setPaypalErrorMessage('');

    const cardNumber = elements.getElement(CardNumberElement);

    if (cardNumber) {

      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
      });

      const { error, token } = await stripe.createToken(cardNumber);  


      if (!error && token) {
        try {
          const orderToken = {...total, stripe_token: token.id};

          const response = await fetch(`${process.env.REACT_APP_API}/order/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify(orderToken)

          }).then(function (res) {
            return res.json();
          }).then(function (data) {

            if (data.errors || data.data.is_error) {
              const listError = Object.values(data.errors || data.data.message);
              setPaypalErrorMessage(listError);
            } else if(data.data.payment_status) {
              dispatch(setChangeOrderIDAction(data.data.order_id));
              history.push('/confirmed-order');
            } else {
              setPaying(false);
              setPaypalErrorMessage('Error');
            }
            
          });
          
        } catch (error) {
          console.log(error);
        }
      }

      if (payload.error && payload.error.message) {
        setPaying(false);
        setPaypalErrorMessage(payload.error.message);
      }
    }
 
  };

  const closeAlert = () => {
    setPaypalErrorMessage('');
    setPaying(false);
  };

  const inputStyle = {
    color: '#000000',
    fontWeight: '400',
    fontFamily: 'Avenir Next',
    fontSize: '17px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: 'rgba(0,0,0,.3)',
    },
    ':-webkit-autofill': {
      color: 'rgba(0,0,0,.3)',
    },
  };

  return (
    <div className={style.stripe}>
      <div className={style.form}>
        <form onSubmit={handleSubmit}>
          <div className={style.body}>
            <div className={style.row}>
              <label className={style.label} htmlFor={'cardNumber'}>Card Number</label>
              <div className={style.control}>
                <CardNumberElement
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                  id={'cardNumber'}
                />
              </div>
            </div>
            <div className={style.row}>
              <label className={style.label} htmlFor={'cardExpiry'}>Expiration date</label>
              <div className={style.control}>
                <CardExpiryElement
                  id={'cardExpiry'}
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                />
              </div>
            </div>
            <div className={style.row}>
              <label className={style.label} htmlFor={'cardCvc'}>CVC</label>
              <div className={style.control}>
                <CardCvcElement
                  id={'cardCvc'}
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.footer}>
            
            <button
              type="submit"
              className={`btn ${paying ? 'btn-load' : ' btn-default'}`}
              disabled={!stripe || paying}
            >
              {paying ? (
                <svg className='spinner' viewBox='0 0 50 50'>
                  <circle
                    className='path'
                    cx='25'
                    cy='25'
                    r='20'
                    fill='none'
                    strokeWidth='5'
                  ></circle>
                </svg>
              ) : 'Place Order'}
            </button>
          </div>
        </form>
      </div>
      {paypalErrorMessage !== '' ? (
        <div className={style.alert}>
          {paypalErrorMessage}
          <button onClick={closeAlert} className={style.close}>&times;</button>
        </div>
      ) : null }
    </div>
  );
};

const PaymentStripe: React.FC<Props> = ({order}) => {

  const getToken = `${process.env.REACT_APP_API}/order/payment/credentials?payment_service=stripe`;

  const [stripeKey, setStripeKey] = useState(null);
  
  const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

  useEffect(() => {
    (async () => {
      const response = await (
        await fetch(getToken)
      ).json();
      
      setStripeKey(response?.data.public_key);
    })();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm total={order} />
    </Elements>
  );
};

export default PaymentStripe;