import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Address } from '../StepComponents/BookBloodCollection';
import classNames from 'classnames';
import style from './style.module.scss';

enum InputTypes {
  STREET_ADDRESS = 'STREET_ADDRESS',
  ADDITIONAL_INFO = 'ADDITIONAL_INFO',
  POSTAL_CODE = 'POSTAL_CODE',
}

interface Props {
  address: Address,
  setAddress: Dispatch<SetStateAction<Address>>,
  title: string,
  border?: boolean,
}

const AddressForm: React.FC<Props> = ({address, setAddress, title, border}) => {

  const changeAddress = useCallback((value: string, type: InputTypes) => {
    switch (type) {
    case InputTypes.STREET_ADDRESS: 
      setAddress(prev => ({...prev, streetAddress: value}));
      break;
    case InputTypes.ADDITIONAL_INFO: 
      setAddress(prev => ({...prev, additionalInfo: value}));
      break;
    case InputTypes.POSTAL_CODE: 
      setAddress(prev => ({...prev, postalCode: value}));
      break;
    default:
      break;
    }
  }, [setAddress]);
  return (
    <form className={border ? style.address : classNames(style.address, style.noBorder)}>
      <span className={style.title}>{title}</span>
      <input 
        type="text" 
        placeholder="Street address" 
        value={address.streetAddress} 
        onChange={(e) => changeAddress(e.target.value, InputTypes.STREET_ADDRESS)} 
      />
      <input 
        type="text" 
        placeholder="Apt, suite, unit, floor (optional)" 
        value={address.additionalInfo} 
        onChange={(e) => changeAddress(e.target.value, InputTypes.ADDITIONAL_INFO)} 
      />
      <div>
        <input 
          type="number" 
          placeholder="Postal code" 
          value={address.postalCode} 
          onChange={(e) => changeAddress(e.target.value, InputTypes.POSTAL_CODE)} 
        />
        <input 
          type="text" 
          value="Singapore" 
          disabled 
        />
      </div>
    </form>
  );
};

export default AddressForm;