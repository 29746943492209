
import { ProductsAction, ProductsActionTypes } from '../../types/products';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<ProductsAction>) => void;

export const fetchProducts = (): ReturnValue => {
  return async (dispatch: Dispatch<ProductsAction>) => {
    try {
      dispatch({
        type: ProductsActionTypes.FETCH_PRODUCTS
      });
      const response = await axios.get(`${process.env.REACT_APP_API}/product/list`);
      dispatch({
        type: ProductsActionTypes.FETCH_PRODUCTS_SUCCESS,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: ProductsActionTypes.FETCH_PRODUCTS_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};