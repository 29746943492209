import { ChangeActiveDate, ChangeActiveClinic, ChangeActiveTime, ClinicsAction, ClinicsActionTypes, ClearClinicData } from '../../types/bookClinicVisit';
import { Dispatch } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';

type ReturnValue = (dispatch: Dispatch<ClinicsAction>) => void;

export const fetchClinic = (): any => {
  return async (dispatch: Dispatch<ClinicsAction>) => {
    try {
      dispatch({
        type: ClinicsActionTypes.FETCH_CLINICS
      });

      const dateNow = new Date();
      const startDate = format(parseISO(dateNow.toISOString()), 'dd-MM-yyyy');
      const endDate = format(parseISO(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy');

      axios.get(`${process.env.REACT_APP_API}/availability/clinic/list?start_date=${startDate}&end_date=${endDate}`)
        .then(res => {
          const clinics = res.data.data.map((el: any) => ({
            id: el.id,
            type: 'clinic',
            name: el.title,
            address: `${el.description}, ${el.phone}`,
            disabledDates: el.availability,
          }));

          dispatch({
            type: ClinicsActionTypes.FETCH_CLINICS_SUCCESS,
            payload: clinics,
          });
          dispatch({
            type: ClinicsActionTypes.CHANGE_ACTIVE_DATE_CLINIC,
            payload: Object.entries(clinics[0].disabledDates).map((el: any, index) => {
              return [el[0], Object.values(el[1]).map((elem: any) => Object.values(elem)).flat().some(el => el)];
            }).filter(el => el[1])[0][0] as string
          });
        });
    } catch (e) {
      dispatch({
        type: ClinicsActionTypes.FETCH_CLINICS_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const changeCalendarMonth = (dates: string[]): any => {
  return async (dispatch: Dispatch<ClinicsAction>) => {
    try {
      axios.get(`${process.env.REACT_APP_API}/availability/clinic/list?start_date=${dates[0]}&end_date=${dates[1]}`)
        .then(res => {
          dispatch({
            type: ClinicsActionTypes.CHANGE_MONTH,
            payload: res.data.data.map((el: any) => ({
              id: el.id,
              type: 'clinic',
              name: el.title,
              address: `${el.description}, ${el.phone}`,
              disabledDates: el.availability,
            }))
          });
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeActiveClinicAction = (payload: number): ChangeActiveClinic => ({type: ClinicsActionTypes.CHANGE_ACTIVE_CLINIC, payload});
export const changeActiveDateAction = (payload:  string): ChangeActiveDate => ({type: ClinicsActionTypes.CHANGE_ACTIVE_DATE_CLINIC, payload});
export const changeActiveTimeAction = (payload: string | null): ChangeActiveTime => ({type: ClinicsActionTypes.CHANGE_ACTIVE_TIME_CLINIC, payload});
export const clearClinicDataAction = (): ClearClinicData => ({type: ClinicsActionTypes.CLEAR_CLINIC_DATA});

