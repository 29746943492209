import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import style from './style.module.scss';
import classNames from 'classnames';
import { StylesProvider } from '@material-ui/styles';
import './overrides.scss';
import { PaymentDetailsOption, PaymentDetailsOptionValues } from '../../../types/paymentDetails';

interface Props {
  options: PaymentDetailsOption[],
  activeOption: PaymentDetailsOption | undefined,
  onChange: (type: PaymentDetailsOptionValues) => void, 
  optionsWithBorder?: boolean,
}

const PaymentForm: React.FC<Props> = ({options, activeOption, onChange, optionsWithBorder}) => {

  return (
    <StylesProvider injectFirst>
      <FormControl style={{width: '100%'}} component="fieldset">
        <RadioGroup
          aria-label=""
          defaultValue=""
          name="radio-buttons-group"
          onChange={(e) => onChange(e.target.value as PaymentDetailsOptionValues)}
        >
          {options.map(option => (
            <div key={option.id} style={{marginTop: '1rem'}}>
              <div className={classNames(style.radioItem, `${optionsWithBorder && style.radioWrapper} ${activeOption && activeOption.id === option.id ? style.active : null}`, option.disabled && style.disabledOption)}>
                <FormControlLabel
                  value={option.value }
                  control={<Radio checked={activeOption && activeOption.id === option.id ? true : false} />}
                  label={<div className={style.option}><option.icon /><span>{option.text ? option.text : ''}</span></div>}
                  disabled={option.disabled}
                />
              </div>
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </StylesProvider>
  );
};

export default PaymentForm;