import React from 'react';
import style from './style.module.scss';
import { Link } from 'react-router-dom';
import ProductsList from '../ProductsList';
import { Product } from '../../types/product';

interface Props {
  title: string,
  products: Product[];
}

const OtherProduct: React.FC<Props> = ({title, products}) => {
  return (
    <div className={style.other}>
      <div className={style.head}>
        <strong className={style.title}>{title}</strong>
        <Link to={'/'} className={style.link}>Shop All</Link>
      </div>
      <div className={style.body}>
        <ProductsList products={products} slide />
      </div>
    </div>
  );
};

export default OtherProduct;