import React from 'react';
import CustomAccordion from '../CustomAccordion';

export interface IFaqQuestion {
  id: number, 
  question: string,
  answer: any,
}

export interface Props {
  faq: IFaqQuestion[] | [],
  multiply?: boolean,
  title?: string,
}

const Faq: React.FC<Props> = ({title, faq, multiply}) => {
  return <CustomAccordion headline={title} multiply={multiply} data={faq} />;
};

export default Faq;