import React from 'react';

const IconStethoscope: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="25" 
      height="24" 
      viewBox="0 0 23 19" 
      fill="none"
    >
      <path 
        d="M0.393555 3.53662C0.393555 6.74072 3.12451 11.3975 5.84717 11.9121C5.96338 15.9131 7.47412 18.1294 10.064 18.1294C12.5874 18.1294 13.8989 16.1206 14.5962 12.0698C15.1191 9.04834 15.8579 8.10205 17.0615 8.10205C18.373 8.10205 19.0537 9.34717 19.228 11.8789C18.1406 12.1943 17.3521 13.207 17.3521 14.394C17.3521 15.855 18.5225 17.0254 19.9834 17.0254C21.4277 17.0254 22.5981 15.855 22.5981 14.394C22.5981 13.1489 21.7266 12.103 20.5479 11.8374C20.2324 8.45898 19.1948 6.76562 17.0615 6.76562C15.0444 6.76562 13.9487 8.14355 13.2847 11.771C12.6953 15.0332 11.9565 16.793 9.98096 16.793C8.271 16.793 7.2749 15.0332 7.18359 11.9204C9.92285 11.4224 12.6621 6.74902 12.6621 3.53662C12.6621 1.70215 11.7656 0.76416 10.0142 0.76416H9.77344C9.54932 0.407227 9.15918 0.166504 8.71094 0.166504C8.02197 0.166504 7.45752 0.730957 7.45752 1.41992C7.45752 2.10889 8.02197 2.67334 8.71094 2.67334C9.15088 2.67334 9.53271 2.44092 9.75684 2.10059H10.0806C10.9189 2.10059 11.3257 2.59863 11.3257 3.52002C11.3257 6.15137 8.89355 10.6255 6.52783 10.6255C4.16211 10.6255 1.72998 6.15137 1.72998 3.52002C1.72998 2.59863 2.14502 2.10059 2.9751 2.10059H3.28223C3.50635 2.44092 3.89648 2.67334 4.32812 2.67334C5.01709 2.67334 5.58154 2.10889 5.58154 1.41992C5.58154 0.730957 5.01709 0.166504 4.32812 0.166504C3.88818 0.166504 3.48975 0.407227 3.26562 0.76416H3.0415C1.29004 0.76416 0.393555 1.70215 0.393555 3.53662ZM19.9834 15.855C19.1699 15.855 18.5142 15.1992 18.5142 14.394C18.5142 13.5806 19.1699 12.9331 19.9834 12.9331C20.7886 12.9331 21.436 13.5806 21.436 14.394C21.436 15.1992 20.7886 15.855 19.9834 15.855Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconStethoscope;