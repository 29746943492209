export interface BookingTime {
  [key: string | number | symbol]: {
    [key: string | number | symbol]: boolean,
  },
}

export const initialBookingTime: BookingTime = {
  'Morning': {
    '8:00 – 10:00': true,
    '10:00 – 12:00': true,
    '12:00 – 14:00': true,
    '14:00 – 16:00': true,
    '16:00 – 18:00': true,
    '18:00 – 20:00': true,
  },
};