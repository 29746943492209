import { MenuItem, Select } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import style from './style.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Option = {
  value: string,
  label: string,
  id: number,
}

interface Props {
  options: Option[]
  value: string | null,
  handleChange: (value: string | null, type: string) => void,
  label?: React.ReactChild | string,
  name: string,
  alert?: boolean,
  placeholder?: string,
}

const CustomSelect: React.FC<Props> = ({options, value, handleChange, label, name, alert, placeholder }) => {

  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '1rem 1.5rem',
            fontFamily: 'Avenir Next',
            fontSize: '1.7rem',
            lineHeight: '2.3rem',
          },
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            width: '100%',
            '&:hover': {
              border: 'none !important',
            }
          },
          notchedOutline: {
            border: '1px solid #c7c7c7 !important',
          },
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'Avenir Next',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            lineHeight: '20px',
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={style.select}>
        {label && <div className={style.label}>{label}</div>}
        <div className={style.selectWrapper}>
          <Select
            value={value}
            onChange={(e) => handleChange(e.target.value, name)}
            inputProps={{ 'aria-label': 'Without label' }}
            variant="outlined"
            IconComponent={ExpandMoreIcon}
            displayEmpty
            className={alert ? style.alert : undefined}
            renderValue={
              value !== '' ? undefined : () => <div className={style.placeholder}>{placeholder ? placeholder : null}</div>
            }
          >
            {options.map(option => (
              <MenuItem key={option.id} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default CustomSelect;