import React, { useEffect, useState } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useHistory } from 'react-router-dom';
import { useTypedDispatch } from '../../hooks/productsTypedSelector';
import { setChangeOrderIDAction } from '../../store/actions/order';
import { clearClinicDataAction } from '../../store/actions/bookClinicVisit';
import { clearDoctorDataAction } from '../../store/actions/bookConsultation';
import { clearCartProductsAction } from '../../store/actions/cartProducts';
import { clearDeliveryDetails } from '../../store/actions/deliveryDetails';
import style from './style.module.scss';

interface Props {
  order: any,
}

const PaymentPayPalButton: React.FC<Props> = ({order}) => {

  const history = useHistory();
  const dispatch = useTypedDispatch();
  const [clientID, setClientID] = useState<string>('');
  const [paypalErrorMessage, setPaypalErrorMessage] = useState<any>('');
  const [succeeded, setSucceeded] = useState(false);
  const [billingDetails, setBillingDetails] = useState('');

  const getAPI = `${process.env.REACT_APP_API}/order/payment/credentials?payment_service=paypal`;

  useEffect(() => {
    (async () => {
      const response = await (
        await fetch(getAPI)
      ).json();
      setClientID(response?.data.client_id || response?.data.client_id);
    })();
  }, []);

  // creates a paypal order
  const createOrder = (data: any, actions: any) => {
    
    return fetch(`${process.env.REACT_APP_API}/order/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(order)
    }).then(function (res) {
      return res.json();
    }).then(function (data) {

      if (data.errors) {
        const listError = Object.values(data.errors);
        setPaypalErrorMessage(listError);
      } else {
        dispatch(setChangeOrderIDAction(data.data.order_id));
        return data.data.id;
      }
    });
     
  };

  // handles when a payment is confirmed for paypal
  const onApprove = (data: any, actions: { order: { capture: () => Promise<{ payer: any; id: string }>; }; }) => {
    return actions.order.capture().then(function (details: { payer: any; id: string }) {
      const {payer, id} = details;

      const orderID = {
        'payment_service_order_id': id,
      };
      
      fetch(`${process.env.REACT_APP_API}/order/payment/approve/paypal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(orderID),
      });

    
      dispatch(clearCartProductsAction());
      dispatch(clearDoctorDataAction());
      dispatch(clearClinicDataAction());
      dispatch(clearDeliveryDetails());

      history.push('/confirmed-order');
      
      setBillingDetails(payer);
      setSucceeded(true);
    }).catch(err => setPaypalErrorMessage(err));
  };

  const closeAlert = () => {
    setPaypalErrorMessage('');
  };

  return (
    <>
      {clientID ? (
        <>
          <PayPalScriptProvider
            options={
              {
                'client-id': clientID,
                'currency': 'SGD',
              }
            }
          >
            <PayPalButtons
              style={{
                color: 'blue',
                layout: 'horizontal',
                height: 48,
                tagline: false,
                shape: 'pill',
              }}
              createOrder={createOrder}
              onApprove={onApprove}
            />
            
          </PayPalScriptProvider>
          {paypalErrorMessage !== '' ? (
            <div className={style.alert}>
              {paypalErrorMessage}
              <button onClick={closeAlert} className={style.close}>&times;</button>
            </div>
          ) : null }
        </>
      ) : (
        <svg className='spinner' viewBox='0 0 50 50'>
          <circle
            className='path'
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='5'
          ></circle>
        </svg>
      ) }
    </>
  ); 
};
export default PaymentPayPalButton;