import IconCreditCard from '../../assets/images/icons/creditCard';
import IconGrabPay from '../../assets/images/icons/grabPay';
import IconPayNow from '../../assets/images/icons/payNow';
import IconPayAtome from '../../assets/images/icons/payAtome';
import IconPayPal from '../../assets/images/icons/payPall';
import {
  BillingAddressOptionValues,
  PaymentDetailsOptionValues,
  PaymentDetailsState,
  PaymentDetailsAction,
  PaymentDetailsActionTypes,
} from '../../types/paymentDetails';

const initialState: PaymentDetailsState = {
  paymentDetails: {},
  paymentOptions: [
    {
      id: 1,
      value: PaymentDetailsOptionValues.PAYPAL,
      icon: IconPayPal,
      disabled: false,
    },
    {
      id: 2,
      value: PaymentDetailsOptionValues.CREDIT_CARD,
      text: 'Credit/Debit Card',
      icon: IconCreditCard,
      disabled: false,
    },
    {
      id: 3,
      value: PaymentDetailsOptionValues.ATOME,
      icon: IconPayAtome,
      disabled: false,
    },
    // {
    //   id: 3,
    //   value: PaymentDetailsOptionValues.PAYNOW,
    //   icon: IconPayNow,
    //   disabled: true,
    // },
    // {
    //   id: 4,
    //   value: PaymentDetailsOptionValues.GRABPAY,
    //   icon: IconGrabPay,
    //   disabled: true,
    // }
  ],
  // activePaymentOption: PaymentDetailsOptionValues.PAYPAL,
  activePaymentOption: PaymentDetailsOptionValues.NONE,
  billingAddressOptions: [
    {
      id: 1,
      value: BillingAddressOptionValues.SAME_AS_SHIPPING_ADDRESS,
      text: 'Same as shipping address',
    },
    {
      id: 2,
      value: BillingAddressOptionValues.USE_A_DIFFERENT_BILLING_ADDRESS,
      text: 'Use a different billing address',
    },
  ],
  activeBillingAddressOption: {
    id: 1,
    value: BillingAddressOptionValues.SAME_AS_SHIPPING_ADDRESS,
    text: 'Same as shipping address',
  },
  billingAddress: {
    firstName: '',
    lastName: '',
    streetAddress: '',
    additionalInfo: '',
    postalCode: '',
    country: '',
  },
  promoCode: '',
  loading: false,
  error: null,
};

export const paymentDetailsReducer = (state = initialState, action: PaymentDetailsAction): PaymentDetailsState => {
  switch (action.type) {
  case PaymentDetailsActionTypes.CHANGE_ACTIVE_PAYMENT_OPTION:
    return {...state, activePaymentOption: action.payload};
  case PaymentDetailsActionTypes.CHANGE_ACTIVE_BILLING_ADDRESS_OPTION:
    return {...state, activeBillingAddressOption: action.payload};
  case PaymentDetailsActionTypes.CHANGE_BILLING_ADDRESS:
    return {...state, billingAddress: action.payload};
  case PaymentDetailsActionTypes.CHANGE_PROMOCODE:
    return {...state, promoCode: action.payload};
  case PaymentDetailsActionTypes.CLEAR_PAYMENT_OPTION:
    return {...state, activePaymentOption: PaymentDetailsOptionValues.NONE};
  default:
    return state;
  }
};