
import { CategoriesAction, CategoriesActionTypes } from '../../types/categories';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<CategoriesAction>) => void;

export const fetchCategories = (): ReturnValue => {
  return async (dispatch: Dispatch<CategoriesAction>) => {
    try {
      dispatch({
        type: CategoriesActionTypes.FETCH_CATEGORIES
      });
      const response = await axios.get(`${process.env.REACT_APP_API}/category/list`);
      dispatch({
        type: CategoriesActionTypes.FETCH_CATEGORIES_SUCCESS,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: CategoriesActionTypes.FETCH_CATEGORIES_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};