import React from 'react';
import FooterMenu from '../FooterMenu';
import SocialLinks from '../SocialLinks';
import style from './style.module.scss';
import classNames from 'classnames';

const Links1 = [
  {
    name: 'Sexual Health',
    to: '/category/sexual-health',
    external: false,
  },
  {
    name: 'Women’s Health',
    to: '/category/womens-health',
    external: false,
  },
  {
    name: 'Tele-consultation',
    to: '/category/tele-consultation',
    external: false,
  },
  {
    name: 'Birth Control',
    to: '/category/birth-control',
    external: false,
  },
  {
    name: 'Treatments',
    to: '/category/treatments',
    external: false,
  },
  {
    name: 'Supplements',
    to: '/category/supplements',
    external: false,
  },
];

const Links2 = [
  {
    name: 'Blog',
    to: 'https://www.fernehealth.com/blog',
    external: true,
  },
  {
    name: 'Events',
    to: 'https://www.fernehealth.com/events',
    external: true,
  },
  {
    name: 'FAQ',
    to: 'https://www.fernehealth.com/faq',
    external: true,
  },
  {
    name: 'About Us',
    to: 'https://www.fernehealth.com/about-us',
    external: true,
  },
  {
    name: 'Contact Us',
    to: 'https://www.fernehealth.com/contact',
    external: true,
  },
  {
    name: 'Partner Products',
    to: 'https://www.fernehealth.com/partner-products',
    external: true,
  },
];

const Links3 = [
  {
    name: 'Term of Use',
    to: 'https://www.fernehealth.com/term-of-use',
    external: true,
  },
  {
    name: 'Privacy Policy',
    to: 'https://www.fernehealth.com/privacy-policy',
    external: true,
  },
];

const LayoutFooter: React.FC = () => {
  return (
    <footer className={style.footer}>
      <div className="container">
        <div className={style.grid}>
          <div className={classNames(style.cell, style.one)}>
            <FooterMenu title={'Shop'} links={Links1} />
          </div>
          <div className={classNames(style.cell, style.two)}>
            <FooterMenu title={'Learn'} links={Links2} />
          </div>
          <div className={classNames(style.cell, style.three)}>
            <FooterMenu title={'Links'} links={Links3} />
          </div>
          <div className={classNames(style.cell, style.four)}>
            <div className={style.social}>
              <strong className={style.headline}>Follow Us</strong>
              <SocialLinks />
            </div>
          </div>
        </div>
        <div className={style.copyright}>
          <p>Copyright © {new Date().getFullYear()} Ferne Health Inc. Pte. Ltd.  All rights reserved. UEN 202003285D, Singapore</p>
        </div>
      </div>
    </footer>
  );
};

export default LayoutFooter;