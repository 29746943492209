import {
  SearchResultsState,
  SearchResultsAction,
  SearchResultsActionTypes
} from '../../types/searchResults';

const initialState: SearchResultsState = {
  search: [],
  text: '',
  loading: false,
  error: null
};

export const searchResultsReducer = (state = initialState, action: SearchResultsAction): SearchResultsState => {
  switch (action.type) {
  case SearchResultsActionTypes.FETCH_SEARCH_PRODUCTS:
    return {loading: true, error: null, text: '', search: []};
  case SearchResultsActionTypes.FETCH_SEARCH_PRODUCTS_SUCCESS:
    return {loading: false, error: null, text: action.text, search: action.payload};
  case SearchResultsActionTypes.FETCH_SEARCH_PRODUCTS_ERROR:
    return {loading: false, error: action.payload, text: '', search: []};
  default:
    return state;
  }
};