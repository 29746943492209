import React, { useCallback, useEffect, useMemo } from 'react';
import BannerAbout from '../../components/BannerAbout';
import CartProductList from '../../components/CartProductList';
import CartSummary from '../../components/CartSummary';
import style from './style.module.scss';
import OtherProduct from '../../components/OtherProducts';
import { useHistory } from 'react-router';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import { ProductsCategoryState } from '../../types/productsСategory';
import { fetchProductsCategory } from '../../store/actions/productsCategory';
import { useDispatch } from 'react-redux';
import { changeStepAction, clearServiceLocationAction, clearStepAction } from '../../store/actions/order';
import { StepTypes } from '../../types/order';
import { ProductTypes } from '../../types/product';
import { getCartProducts, getCartProductsContainTypes } from '../../store/selectors/cartProducts';
import { Link } from 'react-router-dom';
import { clearClinicDataAction } from '../../store/actions/bookClinicVisit';
import { clearDoctorDataAction } from '../../store/actions/bookConsultation';
import { clearDeliveryDetails, clearDeliveryDetailsAddress } from '../../store/actions/deliveryDetails';
import { clearPaymentOption } from '../../store/actions/paymentDetails';
import { clearBloodCollectionDataAction } from '../../store/actions/bookBloodCollection';
import { clearShippingDataAction } from '../../store/actions/shipping';
import { clearPersonalInfo } from '../../store/actions/personalInfo';

export const Cart: React.FC = (): JSX.Element => {
  const { list }: ProductsCategoryState = useTypedSelector(state => state.productsCategory);
  const products = useTypedSelector(getCartProducts);
  const isServiceLocationStep = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.TESTING_KIT, ProductTypes.BLOOD_TEST, ProductTypes.PRESCRIPTION]));
  const isClinicVisitStep = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.CLINIC_VISIT]));
  const dispatch = useDispatch();
  const history = useHistory();
  const isOrderContainOtherProducts = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.ONLINE_CONSULTATION]));

  const onStartOrder = useCallback(() => {
    if (isServiceLocationStep) {
      dispatch(changeStepAction(StepTypes.SERVICE_LOCATION));
    } else if (isClinicVisitStep) {
      dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT));
    } else {
      dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    }
    
    if (isOrderContainOtherProducts) {
      dispatch(clearClinicDataAction());
      dispatch(clearDeliveryDetails());
      dispatch(clearPaymentOption());
      dispatch(clearBloodCollectionDataAction());
      dispatch(clearShippingDataAction());
      dispatch(clearPersonalInfo());
      dispatch(clearDeliveryDetailsAddress());
    } else {
      dispatch(clearDoctorDataAction());
      dispatch(clearClinicDataAction());
      dispatch(clearDeliveryDetails());
      dispatch(clearPaymentOption());
      dispatch(clearBloodCollectionDataAction());
      dispatch(clearShippingDataAction());
      dispatch(clearPersonalInfo());
      dispatch(clearDeliveryDetailsAddress());
    }

    history.push('/order');
  }, [dispatch, history, isServiceLocationStep, isClinicVisitStep]);

  const productsRandom = useMemo(() => list.slice(0, 4), [list]);

  useEffect(() => {
    dispatch(clearStepAction());
    dispatch(clearServiceLocationAction());
    dispatch(fetchProductsCategory(Number(2)));
  }, [dispatch]);

  return (
    <div>
      <div className="container">
        <h1 className={style.title}>My tote towards better health</h1>
        {products.length !== 0 ? (
          <div className={style.wrapper}>
            <div className={style.productListWrapper}>
              <CartProductList products={products} />
            </div>
            <CartSummary onStartOrder={onStartOrder} />
          </div>
        ) : (
          <div className={style.empty}>
            <p className={style.message}>
              The tote is empty, <Link to={'/'}>shop now</Link>
            </p>
          </div>
        )}
      </div>
      <BannerAbout />
      <div className="container">
        <OtherProduct title={'You May Also Need'} products={productsRandom} />
      </div>
    </div>
  );
};