import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { MatchParams } from '../../scenes';

interface Props {
  params: MatchParams;
  url?: string;
  list: Array<{
    id: number,
    name: string,
    Summary: string,
    slug: string,
    content: string,
    position_number: number,
  }>;
}

function byField(field: any) {
  return (a: any, b: any) => a[field] > b[field] ? 1 : -1;
}

const ListCategory: React.FC<Props> = ({params, url, list}) => {

  const slug = params.name;

  return (
    <div className={style.category}>
      <ul className={style.list}>
        <li className={style.item}>
          <Link to={'/'} className={url === '/' ? classNames(style.button, style.active) : style.button}>All</Link>
        </li>
        {list.sort(byField('position_number')).map((category, index) =>
          <li key={index} className={style.item}>
            <Link to={`/category/${category.slug}`} className={slug === category.slug ? classNames(style.button, style.active) : style.button}>{category.name}</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ListCategory;