import {
  BloodCollectionState,
  BloodCollectionAction,
  BloodCollectionActionTypes
} from '../../types/bookBloodCollection';
// doctors 
import { format } from 'date-fns';

const initialState: BloodCollectionState = {
  address: null,
  disableDates: {}, 
  activeDate: format(new Date(), 'yyyy-MM-dd'),
  activeTime: null,
  loading: false,
  error: null
};

export const bookBloodCollectionReducer = (state = initialState, action: BloodCollectionAction): BloodCollectionState => {
  switch (action.type) {
  case BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION:
    return {...state, loading: true };
  case BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION_SUCCESS:
    return {...state, loading: false, disableDates: action.payload};
  case BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION_ERROR:
    return {...state, loading: false, error: action.payload};
  case BloodCollectionActionTypes.CHANGE_ACTIVE_DATE_BLOOD_COLLECTION:
    return {...state, activeDate: action.payload};
  case BloodCollectionActionTypes.CHANGE_ACTIVE_TIME_BLOOD_COLLECTION:
    return {...state, activeTime: action.payload};
  case BloodCollectionActionTypes.CHANGE_ADDRESS_BLOOD_COLLECTION:
    return {...state, address: action.payload};
  case BloodCollectionActionTypes.CLEAR_BLOOD_COLLECTION_DATA:
    return {...state, activeTime: null, activeDate: format(new Date(), 'yyyy-MM-dd')};
  default:
    return state;
  }
};