import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { CSSTransition } from 'react-transition-group';
import style from './style.module.scss';
import { Link } from 'react-router-dom';
import IconClose from '../../assets/images/icons/close';
import IconArrowRight from '../../assets/images/icons/arrowRight';
import classNames from 'classnames';
import banner from '../../assets/images/take-assessment.png';
import Burger from '../Burger';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';

const Links1 = [
  {
    name: 'Sexual Health',
    to: '/category/sexual-health',
    external: false,
  },
  {
    name: 'Women’s Health',
    to: '/category/womens-health',
    external: false,
  },
  {
    name: 'Tele-consultation',
    to: '/category/tele-consultation',
    external: false,
  },
  {
    name: 'Birth Control',
    to: '/category/birth-control',
    external: false,
  },
  {
    name: 'Treatments',
    to: '/category/treatments',
    external: false,
  },
  {
    name: 'Supplements',
    to: '/category/supplements',
    external: false,
  },
];

type Anchor = 'left';

const Sidebar: React.FC = () => {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const [activeMenu, setActiveMenu] = useState('main');
  const dropdownRef = useRef(null);

  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            boxShadow: 'none'
          }
        }
      }
    },
  });

  function DropdownItem(props: any) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="javascript:void(0)"
        className={style.link}
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        {props.children}
      </a>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Burger onClick={toggleDrawer('left', true)} />
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        <Box
          sx={{ width: 340 }}
          role="presentation"
        >
          <div className={style.dropdown} ref={dropdownRef}>       
            <button className={style.close} onClick={toggleDrawer('left', false)}>
              <IconClose />
            </button>

            <CSSTransition
              in={activeMenu === 'main'}
              timeout={500}
              classNames="menu-primary"
              unmountOnExit
            >
              <div className={style.menu}>
                <ul className={style.list}>
                  <li className={style.item}>
                    <DropdownItem goToMenu="shop">
                      Shop
                      <span className={style.icon}>
                        <IconArrowRight />
                      </span>
                    </DropdownItem>
                  </li>
                  <li className={style.item}>
                    <a href="https://www.fernehealth.com/blog" className={style.link}>Blog</a>
                  </li>
                  <li className={style.item}>
                    <a href="https://www.fernehealth.com/assessment" className={style.link}>Take Assessment</a>
                  </li>
                  <li className={style.item}>
                    <a href="https://www.fernehealth.com/events" className={style.link}>Events</a>
                  </li>
                  <li className={style.item}>
                    <a href="https://www.fernehealth.com/contact" className={style.link}>Contact</a>
                  </li>
                  <li className={style.banner}>
                    <img src={banner} alt={'Take Assessment'} className={style.bannerPreview} />
                  </li>
                  <li className={style.item}>
                    <a href="https://www.fernehealth.com/faq" className={style.link}>
                      <div className={style.group}>
                        <span className={classNames(style.icon, style.iconFaq)} /> FAQ
                      </div>
                    </a>
                  </li>
                  {/* <li className={style.item}>
                    <a href="/" className={style.link}>
                      <div className={style.group}>
                        <span className={classNames(style.icon, style.iconAccount)} /> Account
                      </div>
                    </a>
                  </li> */}
                </ul>
              </div>
            </CSSTransition>

            <CSSTransition
              in={activeMenu === 'shop'}
              timeout={500}
              classNames="menu-secondary"
              unmountOnExit
            >
              <div className={style.menu}>
                <ul className={classNames(style.list, style.offset)}>
                  <li className={classNames(style.item, style.back)}>
                    <DropdownItem goToMenu="main">
                      <span className={style.icon}>
                        <IconArrowRight />
                      </span>
                      Shop
                    </DropdownItem>
                  </li>
                  {
                    Links1.map((link, index) => (
                      <li key={index} className={style.item}>
                        {link.external ? (
                          <a href={link.to} className={style.link}>{link.name}</a>
                        ) : (
                          <Link onClick={toggleDrawer('left', false)} to={link.to} className={style.link}>{link.name}</Link>
                        )}
                      </li>
                    ))
                  }
                  <li className={style.item}>
                    <Link to={'/'} onClick={toggleDrawer('left', false)} className={style.link}>View All</Link>
                  </li>
                </ul>
              </div>
            </CSSTransition>
          </div>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;