/* eslint-disable no-useless-escape */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import IconCar from '../../assets/images/icons/car';
import IconCollect from '../../assets/images/icons/collect';
import IconPhone from '../../assets/images/icons/phone';
import IconResults from '../../assets/images/icons/results';
import { useTypedDispatch, useTypedSelector } from '../../hooks/productsTypedSelector';
import style from './style.module.scss';
import { find } from 'lodash';
import { clearClinicDataAction } from '../../store/actions/bookClinicVisit';
import { clearDoctorDataAction } from '../../store/actions/bookConsultation';
import { clearCartProductsAction } from '../../store/actions/cartProducts';
import { clearDeliveryDetails } from '../../store/actions/deliveryDetails';
import { clearPaymentOption } from '../../store/actions/paymentDetails';

const ConfirmedOrder: React.FC = () => {

  const history = useHistory();
  const dispatch = useTypedDispatch();
  const state = useTypedSelector(state => state);
  const { orderID } = useTypedSelector(state => state.order);

  const [ cryptoToken, setCryptoToken] = useState('');
  const [dateHash, setDateHash] = useState<any>([]);
  const [orderHash, setOrderHash] = useState('');

  const urlParamsHash = new URLSearchParams(window.location.search);
  const urlHash: string | null = urlParamsHash.get('hash');

  const [bloodAddressHome, setBloodAddressHome] = useState<any>([]);
  const [shippingAddress, setShippingAddress] = useState<any>([]);

  const expectItems = [
    {
      id: 1,
      icon: IconPhone,
      title: 'Speak with our doctor',
      text: 'Your order includes a compulsory doctor tele-consultation. Speak to our doctor to ensure that the service is right for you.',
    },
    {
      id: 2,
      icon: IconCar,
      title: 'Receive your order',
      text: 'Receive your order after completing the tele-consultation.',
    },
    {
      id: 3,
      icon: IconCollect,
      title: 'Collect and return your sample',
      text: 'Collect your sample in the comfort of your home, and return on the same day.',
    },
    {
      id: 4,
      icon: IconResults,
      title: 'Receive your results',
      text: 'Get certified lab results in a matter of days. If you need any medication or treatments, you could receive it through our partner clinics.',
    },
  ];

  function createToken(hashString: string) {

    // eslint-disable-next-line no-useless-escape
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line prefer-const
    const Base64 = {_keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', encode:function(e: string) {let t = ''; let n, r, i, s, o, u, a; let f = 0; e=Base64._utf8_encode(e); while(f<e.length){n=e.charCodeAt(f++); r=e.charCodeAt(f++); i=e.charCodeAt(f++); s=n>>2; o=(n&3)<<4|r>>4; u=(r&15)<<2|i>>6; a=i&63; if(isNaN(r)){u=a=64;} else if(isNaN(i)){a=64;}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a);} return t;}, decode:function(e: string) {let t = ''; let n, r, i; let s, o, u, a; let f = 0; e=e.replace(/[^A-Za-z0-9\+\/\=]/g, ''); while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++)); o=this._keyStr.indexOf(e.charAt(f++)); u=this._keyStr.indexOf(e.charAt(f++)); a=this._keyStr.indexOf(e.charAt(f++)); n=s<<2|o>>4; r=(o&15)<<4|u>>2; i=(u&3)<<6|a; t=t+String.fromCharCode(n); if(u!=64){t=t+String.fromCharCode(r);} if (a!=64){t=t+String.fromCharCode(i);}}t=Base64._utf8_decode(t); return t;}, _utf8_encode:function(e: string) {e=e.replace(/\r\n/g, '\n'); let t = ''; for(let n = 0; n<e.length; n++) {const r = e.charCodeAt(n); if(r<128){t+=String.fromCharCode(r);} else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192); t+=String.fromCharCode(r&63|128);} else {t+=String.fromCharCode(r>>12|224); t+=String.fromCharCode(r>>6&63|128); t+=String.fromCharCode(r&63|128);}}return t;}, _utf8_decode:function(e: string) {let t = ''; let n = 0; let r = 0; let c1 = 0; let c2 = 0; while(n<e.length) {r=e.charCodeAt(n); if(r<128){t+=String.fromCharCode(r); n++;} else if(r>191&&r<224) { const c2 = e.charCodeAt(n+1); t+=String.fromCharCode((r&31)<<6|c2&63); n+=2;} else {const c2 = e.charCodeAt(n+1); const c3 = e.charCodeAt(n+2); t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63); n+=3;}}return t;}};

    const cryptLetter = (letter: string) => {
      switch (letter) {
      case 'q':
        return letter = '1%';
      case 'Q':
        return letter = 'b&';
      case 'w':
        return letter = 'sX';
      case 'W':
        return letter = 'C';
      case 'e':
        return letter = '9';
      case 'E':
        return letter = 'm';
      case 'r':
        return letter = 'U';
      case 'R':
        return letter = ')';
      case 't':
        return letter = 'B*';
      case 'T':
        return letter = 'b&';
      case 'y':
        return letter = 'C';
      case 'Y':
        return letter = 'Ns';
      case 'u':
        return letter = '&g';
      case 'U':
        return letter = 'C1';
      case 'I':
        return letter = 'gX';
      case 'i':
        return letter = '@s';
      case 'O':
        return letter = 'Vd';
      case 'o':
        return letter = 'A3';
      case 'P':
        return letter = 'nC';
      case 'p':
        return letter = 'd7';
      case 'A':
        return letter = 'v3';
      case 'a':
        return letter = 'b9';
      case 'S':
        return letter = 'm*';
      case 's':
        return letter = 'Nm';
      case 'D':
        return letter = 'k6';
      case 'd':
        return letter = 'x7';
      case 'F':
        return letter = '9Lx';
      case 'f':
        return letter = 'l5';
      case 'G':
        return letter = 'a%';
      case 'g':
        return letter = 'bK';
      case 'H':
        return letter = 'z!';
      case 'h':
        return letter = '5c';
      case 'J':
        return letter = '7%';
      case 'j':
        return letter = '&n';
      case 'K':
        return letter = '@y';
      case 'k':
        return letter = '^t';
      case 'L':
        return letter = '$d';
      case 'l':
        return letter = 'Zv';
      case 'z':
        return letter = 'B';
      case 'Z':
        return letter = 'Mh';
      case 'X':
        return letter = 'Zz';
      case 'x':
        return letter = 'z%';
      case 'C':
        return letter = 'z!';
      case 'c':
        return letter = 'Cv';
      case 'V':
        return letter = '0J';
      case 'v':
        return letter = 'b7';
      case 'B':
        return letter = '!2';
      case 'b':
        return letter = '(m';
      case 'N':
        return letter = '&h';
      case 'n':
        return letter = 'V1';
      case 'M':
        return letter = 'Ad';
      case 'm':
        return letter = '%q';
      case '1':
        return letter = 'Ck';
      case '2':
        return letter = '2s';
      case '3':
        return letter = '%2';
      case '4':
        return letter = 'dc';
      case '5':
        return letter = 'X';
      case '6':
        return letter = 'Z';
      case '7':
        return letter = '1';
      case '8':
        return letter = '6';
      case '9':
        return letter = '5';
      case '0':
        return letter = 'K0';
      default:
        return letter;
      }
    };

    const cryptString = (string: string) => {
      const stringArray = string.split('').reverse();
      const result: string[] = [];
      stringArray.forEach((letter, index) => {
        index % 2 !== 0 ? result.push(cryptLetter(letter)) : result.push(letter);
      });
      return result.join('');
    };

    const startString = Base64.encode('S)$&ADjas12*@');
    const endString = Base64.encode('&@wiOH82)*');
    const hash = Base64.encode(hashString);

    const string = (startString + endString + hash).split('=').join('');
    const firstIter = cryptString(string);
    const token = cryptString(cryptString(firstIter));

    return token;
  }

  useEffect(() => {

    urlHash ? setCryptoToken(createToken(urlHash)) : setCryptoToken(createToken(String(orderID)));
    urlHash ? setOrderHash(urlHash) : setOrderHash(String(orderID));
    
    cryptoToken !== '' && orderHash !== null ? (
      axios.get(`${process.env.REACT_APP_API}/order/success-info?hash=${orderHash}`, {
        headers: {
          secret_token: cryptoToken, 
        }
      })
        .then(response => {
          setDateHash(response.data.data);

          setBloodAddressHome(
            find(response.data.data.addresses, {'address_name': 'blood collection'})
          );
    
          setShippingAddress(
            find(response.data.data.addresses, {'address_name': 'shipping'})
          );
        })
    ) : null;

    

    dispatch(clearCartProductsAction());
    dispatch(clearDoctorDataAction());
    dispatch(clearClinicDataAction());
    dispatch(clearDeliveryDetails());
    dispatch(clearPaymentOption());

  }, [cryptoToken, orderID]);


  // create google calendar event

  const dateTimeVisitClinic = dateHash.consultation && !dateHash.consultation.clinic_employee ?
    `https://calendar.google.com/calendar/r/eventedit?text=Clinic+Visit&dates=${dateHash.consultation.date_time.split(' ')[0].replace(/[^0-9]/g, '')}T${dateHash.consultation.date_time.split(' ')[1].replace(/[^0-9]/g, '').slice(0, -2)}/${dateHash.consultation.date_time.split(' ')[0].replace(/[^0-9]/g, '')}T${dateHash.consultation.date_time.split(' ')[1].replace(/[^0-9]/g, '').slice(0, -2)}&details=${dateHash.consultation.clinic.title}&location=Singapore`
    : null;

  const dateTimeConsultation = dateHash.consultation && dateHash.consultation.clinic_employee ?
    `https://calendar.google.com/calendar/r/eventedit?text=Tele-consultation&dates=${dateHash.consultation.date_time.split(' ')[0].replace(/[^0-9]/g, '')}T${dateHash.consultation.date_time.split(' ')[1].replace(/[^0-9]/g, '').slice(0, -2)}/${dateHash.consultation.date_time.split(' ')[0].replace(/[^0-9]/g, '')}T${dateHash.consultation.date_time.split(' ')[1].replace(/[^0-9]/g, '').slice(0, -2)}&details=Doctor: ${dateHash.consultation.clinic_employee.name}&location=Singapore`
    : null;
  
  const dateTimeBloodCollectionDetails = dateHash.blood_collection_time ?
    `https://calendar.google.com/calendar/r/eventedit?text=Blood+Collection+Details&dates=${dateHash.blood_collection_time.date.replace(/[^0-9]/g, '')}T${dateHash.blood_collection_time.from.replace(/[^0-9]/g, '').slice(0, -2)}/${dateHash.blood_collection_time.date.replace(/[^0-9]/g, '')}T${dateHash.blood_collection_time.to.replace(/[^0-9]/g, '').slice(0, -2)}&location=Singapore`
    : null;

  return (
    <>
      <div className={style.summaryWrapper}>
        <div className={style.summary}>
          <div className={style.header}>
            <div className={style.summaryTitle}>Thanks for your order</div>
            {
              dateHash.email ? (
                <div className={style.summaryDescription}>A confirmation email has been sent to {dateHash.email}.</div>
              ) : null
            }
          </div>
          <div className={style.orderNumber}>
            <div>
              <span>Order number:</span> {urlHash ? urlHash : orderID}
            </div>
          </div>  

          {dateHash.delivery_time ? (
            <div className={style.teleConsultation}>
              <div className={style.title}>Delivery Time: </div>
              <div>
                <div>Date: {dateHash.delivery_time.date}</div>
                <div>Time: {dateHash.delivery_time.from.slice(0, -3)} - {dateHash.delivery_time.to.slice(0, -3)}</div>
              </div>
            </div>
          ) : null}

          {dateHash.blood_collection_time ? (
            <div className={style.teleConsultation}>
              <div className={style.title}>Blood Collection Details:</div>
              <div>
                { bloodAddressHome && bloodAddressHome.length !== 0 ? (
                  <div>Address: {bloodAddressHome.country} {bloodAddressHome.street} {bloodAddressHome.postal_code}</div>
                ) : null }
                <div>Date: {dateHash.blood_collection_time.date}</div>
                <div>Time: {dateHash.blood_collection_time.from.slice(0, -3)} - {dateHash.blood_collection_time.to.slice(0, -3)}</div>
              </div>
              
              <div className={style.footer}>
                <a
                  href={dateTimeBloodCollectionDetails ? dateTimeBloodCollectionDetails : ''}
                  target='_blank'
                  className={style.button}
                  rel="noopener noreferrer"
                >
                  Add to calendar
                </a>
              </div>
            </div>
          ) : null}

          {dateHash.consultation ? (
              
            dateHash.consultation.clinic_employee ? (
              <div className={style.teleConsultation}>
                <div className={style.title}>Tele-consultation: </div>
                <div>
                  <div>Doctor: {dateHash.consultation.clinic_employee.name}</div>
                  <div>Date: {dateHash.consultation.date_time.split(' ')[0]}</div>
                  <div>Time: {dateHash.consultation.date_time.split(' ')[1].slice(0, -3)}</div>
                </div>
                <div className={style.footer}>
                  <a
                    href={dateTimeConsultation ? dateTimeConsultation : ''}
                    target='_blank'
                    className={style.button}
                    rel="noopener noreferrer"
                  >
                    Add to calendar
                  </a>
                </div>
              </div>
            ) : (
              <div className={style.teleConsultation}>
                <div className={style.title}>Clinic Visit:</div>
                <div>
                  <div>Clinic: {dateHash.consultation.clinic.title}</div>
                  <div>Address: {dateHash.consultation.clinic.description}</div>
                  <div>Date: {dateHash.consultation.date_time.split(' ')[0]}</div>
                  <div>Time: {dateHash.consultation.date_time.split(' ')[1].slice(0, -3)}</div>
                </div>
                <div className={style.footer}>
                  <a
                    href={dateTimeVisitClinic ? dateTimeVisitClinic : ''}
                    target='_blank'
                    className={style.button}
                    rel="noopener noreferrer"
                  >
                    Add to calendar
                  </a>
                </div>
              </div>
            )
                
          ) : null }
          
          {shippingAddress && shippingAddress.length !== 0 ? (
            <div className={style.teleConsultation}>
              <div className={style.title}>Delivery: </div>
              <div>{shippingAddress.street}, {shippingAddress.optional}, {shippingAddress.postal_code}, {shippingAddress.country}</div>
             
              {
                dateHash.fees ? (
                  <div>
                    {dateHash.fees[0].fee.name}, {dateHash.fees[0].fee.price}$
                  </div>
                ) : null
              }
            </div>
          ) : null }

        </div>
        <div className={style.continue}>
          <button onClick={() => history.push('/')}>Continue Shopping</button>
        </div>
      </div>
      <div className={style.expectNext}>
        <div className={style.title}>Here’s what you can expect next</div>
        <div className="container">
          <div className={style.expectItems}>
            {expectItems.map(item => (
              <div key={item.id} className={style.expectItem}>
                <div className={style.icon}><item.icon /></div>
                <div className={style.itemTitle}>{item.title}</div>
                <div className={style.text}>{item.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmedOrder;