import React, { useEffect, useRef, useState } from 'react';
import IconInfo from '../../assets/images/icons/info';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import useScroll from '../../hooks/useScroll';
import useWindowSize from '../../hooks/useWindowSize';
import CustomButton from '../CustomButton';
import CustomTooltip from '../CustomTooltip';
import style from './style.module.scss';
import {
  getCartSubTotal,
  getCartTotal,
  getConsultationAndHomeCollectionFee,
  getCartProductConsulation,
} from '../../store/selectors/cartProducts';
import { formateCartPrice } from '../../utils/formatting';

interface Props {
  onStartOrder: () => void,
}

const CartSummary: React.FC<Props> = ({ onStartOrder }) => {

  const { products } = useTypedSelector(state => state.cartProducts);
  const cartSubTotal = useTypedSelector(getCartSubTotal);
  const cartTotal = useTypedSelector(getCartTotal);
  const { consultationFee } = useTypedSelector(getConsultationAndHomeCollectionFee);
  const [openTooltip, setOpenTooltip] = useState(false);

  const summary = useRef() as React.MutableRefObject<HTMLDivElement>;
  const fixedElement = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [fixed, setFixed] = useState(style.posAbsoluteTop);

  const scrollY = useScroll();
  const [windowWidth] = useWindowSize();

  useEffect(() => {
    if (summary.current && fixedElement.current) {
      const headerHeight = 65;
      const fixedHeight = fixedElement.current.offsetHeight;
      const topSummary = summary.current.offsetTop - headerHeight;
      const bottomSummary = summary.current.offsetTop + summary.current.offsetHeight - headerHeight - fixedHeight;

      if ((topSummary < scrollY) && (bottomSummary > scrollY)) {
        setFixed(style.posFixed);
      } else {
        if (bottomSummary < scrollY) {
          setFixed(style.posAbsoluteBottom);
        } else {
          setFixed(style.posAbsoluteTop);
        }
      }
    }
  }, [scrollY, windowWidth]);

  return (
    <div className={style.summary} ref={summary}>
      <div className={fixed} ref={fixedElement}>
        <h2>SUMMARY</h2>
        <div className={style.content}>
          <div className={style.description}>
            <div>
              <div>Subtotal</div>
              <div>${formateCartPrice(cartSubTotal)}</div>
            </div>
            {consultationFee && <div>
              <div className={style.consultationFee}>
                <div>Consultation Fee</div>
                <CustomTooltip 
                  title="Item(s) in your cart includes a compulsory doctor consultation fee. You will receive a refund for your purchase if the doctor has determined that the service is not suitable for you." 
                  setOpenTooltip={setOpenTooltip}
                  openTooltip={openTooltip}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableTouchListener
                  disableFocusListener
                  disableHoverListener
                  placement="right"
                  arrow
                >
                  <button onClick={() => setOpenTooltip(true)}><IconInfo /></button>
                </CustomTooltip>
              </div>
              <div>${formateCartPrice(consultationFee.price)}</div>
            </div>}
          </div>
          <div className={style.total}>
            <div>
              <div>Total</div>
              <div>${formateCartPrice(cartTotal)}</div>
            </div>
            <CustomButton disabled={!products.length} onClick={onStartOrder} text="Checkout" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;