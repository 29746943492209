import { personalInfoReducer } from './personlaInfoReducer';
import { bookConsultationReducer } from './bookConsultationReducer';
import { combineReducers } from 'redux';
import { productsReducer } from './productsReducer';
import { productReducer } from './productReducer';
import { categoriesReducer } from './categoriesReducer';
import { categoryReducer } from './categoryReducer';
import { basketReducer } from './basketReducer';
import { bookClinicVisitReducer } from './bookClinicVisitReducer';
import { orderReducer } from './orderReducer';
import { bookBloodCollectionReducer } from './bookBloodCollectionReducer';
import { shippingReducer } from './shippingReducer';
import { deliveryDetailsReducer } from './deliveryDetailsReducer';
import { productsCategoryReducer } from './productsCategoryReducer';
import { searchResultsReducer } from './searchResultsReducer';
import { productFaqReducer } from './productFaqReducer';
import { paymentDetailsReducer } from './paymentDetailsReducer';
import { cartProductsReducer } from './cartProductsReducer';

export const rootReducer = combineReducers({
  products: productsReducer,
  productsCategory: productsCategoryReducer,
  product: productReducer,
  productFaq: productFaqReducer,
  categories: categoriesReducer,
  category: categoryReducer,
  basket: basketReducer,
  bookConsultation: bookConsultationReducer,
  bookClinicVisit: bookClinicVisitReducer,
  bookBloodCollection: bookBloodCollectionReducer,
  shipping: shippingReducer,
  personalInfo: personalInfoReducer,
  order: orderReducer,
  deliveryDetails: deliveryDetailsReducer,
  search: searchResultsReducer,
  paymentDetails: paymentDetailsReducer,
  cartProducts: cartProductsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;