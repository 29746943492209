import React from 'react';

const IconInfo: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="15" 
      height="14" 
      viewBox="0 0 15 14" 
      fill="none"
    >
      <path 
        d="M7.97426 14C11.8029 14 14.9729 10.8225 14.9729 7C14.9729 3.17059 11.7961 0 7.9674 0C4.14558 0 0.975586 3.17059 0.975586 7C0.975586 10.8225 4.15244 14 7.97426 14ZM7.97426 12.8333C4.73566 12.8333 2.14889 10.2392 2.14889 7C2.14889 3.76078 4.7288 1.16667 7.9674 1.16667C11.206 1.16667 13.7996 3.76078 13.8065 7C13.8134 10.2392 11.2129 12.8333 7.97426 12.8333ZM7.91251 4.55686C8.4134 4.55686 8.8045 4.15882 8.8045 3.66471C8.8045 3.16373 8.4134 2.76569 7.91251 2.76569C7.41849 2.76569 7.02052 3.16373 7.02052 3.66471C7.02052 4.15882 7.41849 4.55686 7.91251 4.55686ZM6.77351 10.7745H9.56612C9.84744 10.7745 10.067 10.5755 10.067 10.2941C10.067 10.0265 9.84744 9.81373 9.56612 9.81373H8.7153V6.32059C8.7153 5.95 8.53004 5.70294 8.18011 5.70294H6.89015C6.60883 5.70294 6.38927 5.91569 6.38927 6.18333C6.38927 6.46471 6.60883 6.66373 6.89015 6.66373H7.62433V9.81373H6.77351C6.49219 9.81373 6.27262 10.0265 6.27262 10.2941C6.27262 10.5755 6.49219 10.7745 6.77351 10.7745Z" 
        fill="black" 
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default IconInfo;