import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

interface Props {
  links: Array<{
    to: string,
    name: string | null,
    external: boolean,
  }> | null;
}

const Breadcrumb: React.FC<Props> = (list) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className={style.breadcrumb}>
        {
          list && list.links && list.links.map((link, index) => (
            <li key={index} className={style.item}>
              {
                link.external ? (
                  <a
                    href={link.to}
                    className={style.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {link.name}
                  </a>
                ) : (
                  link.to === '' ? (
                    <span className={''}>{link.name}</span> 
                  ) : (
                    <Link to={link.to} className={style.link}>{link.name}</Link>
                  )
                )
              }
            </li>
          ))
        }
      </ol>
    </nav>
  );
};

export default Breadcrumb;