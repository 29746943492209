import React from 'react';
import style from './style.module.scss';

interface Card {
  header: string, 
  content: string,
}

interface Props {
  data: any;
}

const SectionContentDefault: React.FC<Props> = ({data}) => {
  return (
    <div className={style.grid}>
      {
        <div className={style.cell}>
          <div className={style.card}>
            <div className={style.description}  dangerouslySetInnerHTML={{__html: data.attributes.content }} />
          </div>
        </div>
      }
    </div>
  );
};

export default SectionContentDefault;