import {
  BasketState,
  BasketAction,
  BasketActionTypes
} from '../../types/basket';

const initialState: BasketState = {
  counter: 0,
  products: [],
  loading: false,
  error: null
};

export const basketReducer = (state = initialState, action: BasketAction): BasketState => {
  switch (action.type) {
  case BasketActionTypes.FETCH_BASKET:
    return {...state, loading: true};
  case BasketActionTypes.FETCH_BASKET_SUCCESS:
    return {...state, loading: false, counter: action.payload};
  case BasketActionTypes.FETCH_BASKET_ERROR:
    return {...state, loading: false, error: action.payload};
  default:
    return state;
  }
};