import React from 'react';
import ProductCard from '../ProductCard';
import style from './style.module.scss';
import { Product } from '../../types/product';
import CustomSwiper from '../CustomSwiper';
import classNames from 'classnames';

interface Props {
  categoryId?: number;
  slide?: boolean;
  products: Product[];
}
const ProductsList: React.FC<Props> = ({categoryId, slide, products}) => {
  
  const category = categoryId;
  const noPreview = '/assets/images/no-preview.svg';
  
  const productList = (
    products
      .filter(product => !product.name.includes('Kit')) // temporary hide all Kits
      .map((product, index) =>
        <div key={index} className={style.cell}>
          <ProductCard
            id={product.id}
            title={product.name}
            link={`/products/${product.slug}`}
            image={product.preview_image === null ? noPreview : `${process.env.REACT_APP_API_DOMAIN}/products_images/${product.preview_image}`}
            price={product.price}
            description={product.summary}
            min_variation_price={product.min_variation_price}
          />
        </div>
      )
  );

  const productListCategory = (
    products
      .filter(product => product.category_id === category)
      .filter(product => !product.name.includes('Kit')) // temporary hide all Kits
      .map((product, index) =>
        <div key={index} className={style.cell}>
          <ProductCard
            id={product.id}
            title={product.name}
            link={`/products/${product.slug}`}
            image={product.preview_image === null ? noPreview : `${process.env.REACT_APP_API_DOMAIN}/products_images/${product.preview_image}`}
            price={product.price}
            description={product.summary}
            min_variation_price={product.min_variation_price}
          />
        </div>
      )
  );
  
  return (
    <div className={style.wrapper}>

      <div className={style.grid}>
        {
          slide ? (
            <div className={classNames('slider-default', style.slide)}>
              <CustomSwiper parameters={{ slidesPerView: 'auto', followFinger: false, loop: false, spaceBetween: 0 }} classesForButtons={['.slider-button-next', '.slider-button-prev']} >
                {
                  categoryId ? (
                    productListCategory
                  ) : (
                    productList
                  )
                }
              </CustomSwiper>
            </div>
          ) : (
            categoryId ? (
              productListCategory
            ) : (
              productList
            )
          )
        }
      </div>
    </div>
  );
};

export default ProductsList;
