import React from 'react';
import style from './style.module.scss';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
};

const Burger: React.FC<Props> = ({...props}) => {
  return (
    <div className={style.burger} {...props}>
      <span className={style.line}></span>
    </div>
  );
};

export default Burger;
