import {
  ShippingState,
  ShippingAction,
  ShippingActionTypes,
  ShippingOptionValues,
  DeliveryOptionValues
} from '../../types/shipping';

const initialState: ShippingState = {
  address: null,
  shippingOptions: [
    {
      id: 1,
      value: ShippingOptionValues.DURING_APPOINTMENT,
      text: 'I want all items to be delivered during the blood collection appointment',
    },
    {
      id: 2,
      value: ShippingOptionValues.SEPARATELY_APPOINTMENT,
      text: 'I want all items to be delivered separately from the blood collection appointment',
    }
  ],
  deliveryOptions: [
    {
      id: 1,
      value: DeliveryOptionValues.EXPRESS_DELIVERY,
      text: 'Express Delivery (within 4 hours) – Free',
    }
  ],
  activeDeliveryOption: null,
  activeShippingOption: null,
  loading: false,
  error: null
};

export const shippingReducer = (state = initialState, action: ShippingAction): ShippingState => {
  switch (action.type) {
  case ShippingActionTypes.FETCH_SHIPPING:
    return {...state, loading: true, error: null};
  case ShippingActionTypes.FETCH_SHIPPING_SUCCESS:
    return {...state, loading: false, error: null};
  case ShippingActionTypes.FETCH_SHIPPING_ERROR:
    return {...state, loading: false, error: action.payload};
  case ShippingActionTypes.CHANGE_ACTIVE_DELIVERY_OPTION:
    return {...state, activeDeliveryOption: state.deliveryOptions.find(option => option.value === action.payload) || null};
  case ShippingActionTypes.CHANGE_ACTIVE_SHIPPING_OPTION:
    return {...state, activeShippingOption: state.shippingOptions.find(option => option.value === action.payload) || null};
  case ShippingActionTypes.CHANGE_ADDRESS_SHIPPING_OPTION:
    return {...state, address: action.payload};
  case ShippingActionTypes.CLEAR_SHIPPING_DATA:
    return {...state};
  default:
    return state;
  }
};