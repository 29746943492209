import React from 'react';
import style from './style.module.scss';

interface Card {
  summary: string, 
}

interface Props {
  data: Array<{
    attributes: Card;
  }>;
}

const SectionContentInclude: React.FC<Props> = ({data}) => {
  return (
    <div className={style.grid}>
      {data.map((item, index) => {
        return <div key={index} className={style.cell}>
          <div className={style.card}>
            <div className={style.summary} dangerouslySetInnerHTML={{__html: item.attributes.summary}} />
          </div>
        </div>;
      }
      )}
    </div>
  );
};

export default SectionContentInclude;