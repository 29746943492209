import React from 'react';

const IconHelp: React.FC = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="none"
      viewBox="0 0 24 24"
    >
      <path 
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
        fillRule="evenodd"
        clipRule="evenodd"
      /> 
      <path
        d="M11.1037 13.44C11.0971 13.1207 11.1546 12.8034 11.2726 12.5067C11.3903 12.2166 11.5657 11.9535 11.7882 11.7333C12.0561 11.4756 12.3546 11.2518 12.6771 11.0667C12.9364 10.9057 13.1804 10.7212 13.4059 10.5156C13.5806 10.3482 13.7171 10.145 13.8059 9.92C13.8954 9.6877 13.9377 9.43993 13.9304 9.19111C13.9423 8.88643 13.8644 8.58498 13.7063 8.32422C13.5483 8.06346 13.3171 7.85489 13.0415 7.72445C12.7151 7.56622 12.3551 7.48994 11.9926 7.50222C11.6401 7.49442 11.2904 7.56739 10.9704 7.71556C10.6927 7.85338 10.4588 8.06574 10.2948 8.32889C10.1206 8.59558 10.0138 8.90064 9.98372 9.21778V9.33334H8.65039V9.22667C8.68667 8.71063 8.83857 8.20937 9.09484 7.76C9.35858 7.29799 9.74661 6.91921 10.2148 6.66667C10.7813 6.38967 11.4068 6.2554 12.0371 6.27556C12.6371 6.27248 13.2299 6.40624 13.7704 6.66667C14.2433 6.89257 14.6458 7.24286 14.9348 7.68C15.2139 8.1112 15.3593 8.6153 15.3526 9.12889C15.3648 9.68594 15.21 10.2339 14.9082 10.7022C14.5967 11.1548 14.1902 11.534 13.7171 11.8133C13.4432 11.9691 13.1869 12.1539 12.9526 12.3644C12.7782 12.5232 12.6441 12.7213 12.5615 12.9422C12.4778 13.1697 12.4386 13.4111 12.4459 13.6533V14.1689H11.1037V13.44ZM11.1037 17.4578C11.0213 17.3747 10.9562 17.2762 10.9119 17.1679C10.8677 17.0596 10.8453 16.9437 10.8459 16.8267C10.8441 16.7082 10.8659 16.5905 10.9102 16.4806C10.9545 16.3707 11.0203 16.2708 11.1037 16.1867C11.1886 16.1048 11.2888 16.0407 11.3987 15.9979C11.5086 15.9552 11.6259 15.9348 11.7437 15.9378C11.9795 15.9378 12.2056 16.0314 12.3723 16.1981C12.539 16.3648 12.6326 16.5909 12.6326 16.8267C12.6333 16.9437 12.6109 17.0596 12.5666 17.1679C12.5224 17.2762 12.4572 17.3747 12.3748 17.4578C12.2937 17.5427 12.1955 17.6095 12.0868 17.654C11.978 17.6984 11.8611 17.7194 11.7437 17.7156C11.6253 17.7174 11.5076 17.6956 11.3977 17.6513C11.2878 17.607 11.1878 17.5412 11.1037 17.4578Z"
      />
    </svg>
  );
};

export default IconHelp;