import { DeliveryDetailsActionTypes, DeliveryDetailsAction, ChangeActiveDeliveryDetailsOption, ChangeActiveDate, ChangeActiveTime, ChangeShippingAddress, DeliveryDetailsOption } from '../../types/deliveryDetails';
import { Dispatch } from 'react';
import axios from 'axios';
import { Address } from '../../types/shipping';

type ReturnValue = (dispatch: Dispatch<DeliveryDetailsAction>) => void;

export const fetchClinic = (): ReturnValue => {
  return async (dispatch: Dispatch<DeliveryDetailsAction>) => {
    try {
      dispatch({
        type: DeliveryDetailsActionTypes.FETCH_DELIVERY_DETAILS
      });
      const response = await axios.get('');
      dispatch({
        type: DeliveryDetailsActionTypes.FETCH_DELIVERY_DETAILS_SUCCESS,
        payload: response.data
      });
    } catch (e) {
      dispatch({
        type: DeliveryDetailsActionTypes.FETCH_DELIVERY_DETAILS_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const changeActiveDeliveryDetailsOptionAction = (payload: DeliveryDetailsOption | null): ChangeActiveDeliveryDetailsOption => ({type: DeliveryDetailsActionTypes.CHANGE_ACTIVE_DELIVERY_DETAILS_OPTION, payload});
export const changeActiveDateAction = (payload: string): ChangeActiveDate => ({type: DeliveryDetailsActionTypes.CHNAGE_ACTIVE_DATE, payload});
export const changeActiveTimeAction = (payload: string | null): ChangeActiveTime => ({type: DeliveryDetailsActionTypes.CHNAGE_ACTIVE_TIME, payload});
export const changeShippingAddress = (payload: Address): ChangeShippingAddress => ({type: DeliveryDetailsActionTypes.CHANGE_SHIPPING_ADDRESS, payload});
export const clearDeliveryDetails = (): DeliveryDetailsAction => ({type: DeliveryDetailsActionTypes.CLEAR_DELIVERY_DETAILS});
export const clearDeliveryDetailsActiveTime = (): DeliveryDetailsAction => ({type: DeliveryDetailsActionTypes.CLEAR_DELIVERY_DETAILS_ACTIVE_TIME});
export const clearDeliveryDetailsAddress = (): DeliveryDetailsAction => ({type: DeliveryDetailsActionTypes.CLEAR_DELIVERY_DETAILS_ADDRESS});