import React from 'react';

const IconResults: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="56" 
      height="56" 
      viewBox="0 0 56 56" 
      fill="none"
    >
      <path 
        d="M41.4403 7.83984H14.5603C13.9662 7.83984 13.3965 8.07584 12.9764 8.49592C12.5563 8.91601 12.3203 9.48576 12.3203 10.0798V45.9198C12.3203 46.5139 12.5563 47.0837 12.9764 47.5038C13.3965 47.9238 13.9662 48.1598 14.5603 48.1598H41.4403C42.0344 48.1598 42.6042 47.9238 43.0242 47.5038C43.4443 47.0837 43.6803 46.5139 43.6803 45.9198V10.0798C43.6803 9.48576 43.4443 8.91601 43.0242 8.49592C42.6042 8.07584 42.0344 7.83984 41.4403 7.83984ZM41.4403 45.3598C41.4403 45.5084 41.3813 45.6508 41.2763 45.7558C41.1713 45.8608 41.0288 45.9198 40.8803 45.9198H15.1203C14.9718 45.9198 14.8294 45.8608 14.7243 45.7558C14.6193 45.6508 14.5603 45.5084 14.5603 45.3598V10.6398C14.5603 10.4913 14.6193 10.3489 14.7243 10.2439C14.8294 10.1388 14.9718 10.0798 15.1203 10.0798H40.8803C41.0288 10.0798 41.1713 10.1388 41.2763 10.2439C41.3813 10.3489 41.4403 10.4913 41.4403 10.6398V45.3598Z" 
        fill="black"
      />
      <path 
        d="M19.5603 29.6797C19.2858 29.6947 19.0271 29.8132 18.8365 30.0114C18.6459 30.2096 18.5376 30.4727 18.5333 30.7476C18.5291 31.0225 18.6292 31.2889 18.8136 31.4929C18.9979 31.6969 19.2527 31.8234 19.5267 31.8469H36.4387C36.7132 31.8319 36.9719 31.7133 37.1625 31.5151C37.353 31.3169 37.4614 31.0539 37.4657 30.7789C37.4699 30.504 37.3698 30.2377 37.1854 30.0337C37.0011 29.8297 36.7462 29.7032 36.4723 29.6797H19.5603Z" 
        fill="black"
      />
      <path 
        d="M31.5282 16.3961C31.5409 16.2476 31.523 16.098 31.4754 15.9567C31.4279 15.8154 31.3518 15.6854 31.2518 15.5748C31.1519 15.4642 31.0302 15.3753 30.8944 15.3138C30.7587 15.2522 30.6117 15.2192 30.4626 15.2169C30.3136 15.2146 30.1656 15.243 30.028 15.3003C29.8904 15.3577 29.766 15.4427 29.6627 15.5502C29.5594 15.6576 29.4793 15.7852 29.4273 15.925C29.3754 16.0647 29.3529 16.2136 29.361 16.3625V24.8689C29.3751 25.1436 29.4929 25.4027 29.6907 25.594C29.8884 25.7852 30.1513 25.8943 30.4263 25.8993C30.7013 25.9043 30.968 25.8047 31.1725 25.6208C31.377 25.4368 31.5041 25.1821 31.5282 24.9081V16.3961Z" 
        fill="black"
      />
      <path 
        d="M26.6395 17.5172C26.6523 17.3687 26.6343 17.2191 26.5868 17.0778C26.5392 16.9365 26.4631 16.8065 26.3631 16.6959C26.2632 16.5853 26.1415 16.4964 26.0058 16.4349C25.87 16.3733 25.723 16.3403 25.5739 16.338C25.4249 16.3357 25.2769 16.3641 25.1393 16.4214C25.0017 16.4788 24.8774 16.5638 24.774 16.6713C24.6707 16.7787 24.5906 16.9063 24.5387 17.046C24.4868 17.1858 24.4642 17.3347 24.4723 17.4836V24.87C24.4865 25.1447 24.6043 25.4038 24.802 25.5951C24.9997 25.7863 25.2626 25.8954 25.5376 25.9004C25.8127 25.9054 26.0793 25.8058 26.2838 25.6219C26.4884 25.4379 26.6155 25.1832 26.6395 24.9092V17.5172Z" 
        fill="black"
      />
      <path 
        d="M21.751 18.6368C21.7756 18.483 21.7668 18.3257 21.7253 18.1756C21.6837 18.0255 21.6104 17.8861 21.5103 17.7668C21.4102 17.6475 21.2855 17.5512 21.1449 17.4843C21.0043 17.4174 20.8509 17.3815 20.6952 17.379C20.5395 17.3766 20.385 17.4077 20.2424 17.4702C20.0997 17.5326 19.9722 17.6251 19.8683 17.7412C19.7645 17.8572 19.6869 17.9943 19.6407 18.143C19.5945 18.2918 19.5808 18.4487 19.6006 18.6032V24.8696C19.6147 25.1443 19.7325 25.4034 19.9303 25.5946C20.128 25.7859 20.3909 25.895 20.6659 25.9C20.9409 25.9049 21.2076 25.8054 21.4121 25.6214C21.6166 25.4375 21.7437 25.1828 21.7678 24.9088V18.6368H21.751Z" 
        fill="black"
      />
      <path 
        d="M36.3993 15.277C36.412 15.1284 36.3941 14.9789 36.3465 14.8376C36.299 14.6963 36.2229 14.5663 36.1229 14.4557C36.023 14.3451 35.9013 14.2562 35.7655 14.1946C35.6298 14.1331 35.4828 14.1001 35.3337 14.0978C35.1846 14.0955 35.0367 14.1239 34.8991 14.1812C34.7615 14.2385 34.6371 14.3236 34.5338 14.431C34.4305 14.5385 34.3503 14.6661 34.2984 14.8058C34.2465 14.9456 34.224 15.0945 34.2321 15.2434V24.8698C34.2462 25.1445 34.364 25.4036 34.5618 25.5948C34.7595 25.7861 35.0224 25.8952 35.2974 25.9002C35.5724 25.9051 35.8391 25.8056 36.0436 25.6216C36.2481 25.4377 36.3752 25.183 36.3993 24.909V15.277Z" 
        fill="black"
      />
      <path 
        d="M19.5603 34.4336C19.2858 34.4486 19.0271 34.5672 18.8365 34.7653C18.6459 34.9635 18.5376 35.2266 18.5333 35.5015C18.5291 35.7765 18.6292 36.0428 18.8136 36.2468C18.9979 36.4508 19.2527 36.5773 19.5267 36.6008H36.4387C36.7132 36.5858 36.9719 36.4672 37.1625 36.269C37.353 36.0709 37.4614 35.8078 37.4657 35.5329C37.4699 35.2579 37.3698 34.9916 37.1854 34.7876C37.0011 34.5836 36.7462 34.4571 36.4723 34.4336H19.5603Z" 
        fill="black"
      />
      <path 
        d="M19.5603 39.1992C19.2858 39.2142 19.0271 39.3328 18.8365 39.531C18.6459 39.7292 18.5376 39.9922 18.5333 40.2672C18.5291 40.5421 18.6292 40.8084 18.8136 41.0124C18.9979 41.2164 19.2527 41.3429 19.5267 41.3664H36.4387C36.7132 41.3514 36.9719 41.2329 37.1625 41.0347C37.353 40.8365 37.4614 40.5734 37.4657 40.2985C37.4699 40.0236 37.3698 39.7572 37.1854 39.5532C37.0011 39.3492 36.7462 39.2227 36.4723 39.1992H19.5603Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconResults;