import React from 'react';
import style from './style.module.scss';
import InputMask from 'react-input-mask';

interface Props {
  label?: React.ReactChild | string,
  placeholder: string,
  value: string,
  type: string,
  name: string,
  onChange: (value: string, name: string) => void,
  mask?: string,
  alert?: boolean,
  message?: string
}

const CustomInput: React.FC<Props> = ({label, placeholder, value, onChange, type, name, mask, alert, message}) => {
  return (
    <div className={style.inputWrapper}>
      {label ? <div className={style.label}>{label}</div> : <div className={style.label}>&nbsp;</div>}
      {mask ? 
        <InputMask 
          mask={mask} 
          style={alert ? {borderColor: 'red'} : {}}
          type={type}  
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value, name)} 
        /> : 
        <input 
          style={alert ? {borderColor: 'red'} : {}}
          type={type}  
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value, name)}
        />}
      {
        message ? (
          <div className={style.message}>
            {message}
          </div>
        ): null
      }
    </div>
  );
};

export default CustomInput;