import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
interface Props {
  id: number;
  title: string;
  link: string;
  image: string;
  price: number | null;
  description: string;
  min_variation_price?: number | null,
}

const ProductCard: React.FC<Props> = ({title, link, image, price, description, min_variation_price}) => {
  return (
    <div className={style.card}>
      <div className={style.media}>
        <Link to={link} className={style.preview} style={{backgroundImage: `url(${image})`}}></Link>
      </div>
      <div className={style.panel}>
        <div className={style.body}>
          <strong className={style.title}>
            <Link to={link}>{title}</Link>
          </strong>
          <div className={style.description}>{description}</div>
        </div>
        {
          !min_variation_price && price === null ? (
            <div className={style.footer}>
              <div className={style.cell}>
              </div>
            </div>
          ) : (
            <div className={style.footer}>
              <div className={style.cell}>
                <span className={style.price}>
                  {price === null ? `From $${min_variation_price}` : `$${price}`}
                </span>
              </div>
            </div>
          )
        }        
      </div>
    </div>
  );
};

export default ProductCard;