import React, {useEffect} from 'react';
import { 
  BrowserRouter as Router, 
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  Shop,
  Product,
  NotFound,
  Category,
  Cart,
  Layout,
  SearchResults
} from './scenes';
import { useLocation } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ScrollToTop from './components/ScrollToTop';
import Order from './scenes/Order';
import ConfirmedOrder from './scenes/ConfirmedOrder';

const Content = () => {

  const location = useLocation();

  useEffect(() => {
    setTimeout(()=> {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },1);
    
  }, [location]);

  return (
    <Layout isCheckout={location.pathname === '/order'}>
      <TransitionGroup>
        <CSSTransition
          timeout={500}
          classNames='fade'
          key={location.key}
        >
          <Switch location={location}>
            <Route exact path="/" component={Shop} />
            <Route path="/products/:slug" component={Product} />
            <Route path="/cart" component={Cart} />
            <Route path="/category/:name" component={Category} />
            <Route path="/order" component={Order} />
            <Route path="/confirmed-order" component={ConfirmedOrder} />
            <Route path="/search-results" component={SearchResults} />
            <Route exact path="/products">
              <Redirect to="/" />
            </Route>
            <Route path="*" component={NotFound} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </Layout>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Content />
    </Router>
  );
};

export default App;
