import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import OtherProduct from '../../components/OtherProducts';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import { fetchProductsCategory } from '../../store/actions/productsCategory';
import { ProductsCategoryState } from '../../types/productsСategory';
import style from './style.module.scss';

export const NotFound: React.FC = (): JSX.Element => {

  const dispatch = useDispatch();
  const { list }: ProductsCategoryState = useTypedSelector(state => state.productsCategory);
  const productsRandom = list.slice(0, 4);

  useEffect(() => {
    dispatch(fetchProductsCategory(Number(1)));
  }, [dispatch]);

  return (
    <>
      <div className={style.page404}>
        <div className="container">
          <div className={style.head}>
            <h2 className={style.title}>Page not found</h2>
          </div>
          <div className={style.body}>
            <div className={style.description}>
              <p>Sorry, we don’t have what you’re looking for, but why not take a look at our popular services or read our blog posts below.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <OtherProduct title={'Popular Services'} products={productsRandom} />
      </div>
    </>
  );
};