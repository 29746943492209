import React from 'react';

const IconMessages: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="25" 
      height="24" 
      viewBox="0 0 23 19" 
      fill="none"
    >
      <path 
        d="M5.55469 17.9453C5.96875 17.9453 6.25 17.8047 6.75 17.3672L9.36719 15.0234C9.92969 15.6406 10.7812 15.9688 11.8672 15.9688H14.5625L17.1719 18.2031C17.6484 18.6094 17.9062 18.8203 18.2812 18.8203C18.8203 18.8203 19.125 18.4375 19.125 17.8438V15.9688H19.4531C21.4141 15.9688 22.7188 14.7891 22.7188 12.7422V8.125C22.7188 6.07812 21.4141 4.89844 19.4531 4.89844H18.2266V4.30469C18.2266 2.17969 16.9688 0.953125 14.8438 0.953125H3.66406C1.625 0.953125 0.28125 2.17969 0.28125 4.30469V11.5C0.28125 13.625 1.625 14.8516 3.66406 14.8516H4.69531V16.9844C4.69531 17.5859 5 17.9453 5.55469 17.9453ZM5.875 14.3281C5.875 13.8828 5.60938 13.6719 5.26562 13.6719H3.74219C2.35938 13.6719 1.53906 12.9219 1.53906 11.4609V4.34375C1.53906 2.89062 2.35938 2.125 3.74219 2.125H14.7656C16.1484 2.125 16.9688 2.89062 16.9688 4.34375V4.89844H11.8672C9.82031 4.89844 8.60938 6.07812 8.60938 8.125V12.7422C8.60938 13.1641 8.65625 13.5469 8.75781 13.8984L5.875 16.5547V14.3281ZM17.9531 17.4062L15.4453 15.1562C15.1328 14.8828 14.9453 14.7969 14.5156 14.7969H11.9453C10.6406 14.7969 9.85938 14.0859 9.85938 12.7031L9.86719 8.16406C9.86719 6.78906 10.6406 6.07031 11.9453 6.07031H19.3828C20.6797 6.07031 21.4609 6.78906 21.4609 8.16406V12.7031C21.4609 14.0859 20.6797 14.7969 19.3828 14.7969H18.5547C18.2188 14.7969 17.9531 14.9922 17.9531 15.4375V17.4062Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconMessages;