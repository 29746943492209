import React from 'react';

const IconCollect: React.FC = () => {

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="56" 
      height="56" 
      viewBox="0 0 56 56"   
      fill="none"
    >
      <path 
        d="M40.3821 13.5857V18.8497H15.6189V13.5857H40.3821ZM40.9421 11.3457H15.0589C14.6133 11.3457 14.186 11.5227 13.871 11.8378C13.5559 12.1528 13.3789 12.5801 13.3789 13.0257V19.4097C13.3789 19.8553 13.5559 20.2826 13.871 20.5976C14.186 20.9127 14.6133 21.0897 15.0589 21.0897H40.9421C41.3877 21.0897 41.815 20.9127 42.13 20.5976C42.4451 20.2826 42.6221 19.8553 42.6221 19.4097V13.0257C42.6221 12.5801 42.4451 12.1528 42.13 11.8378C41.815 11.5227 41.3877 11.3457 40.9421 11.3457Z" 
        fill="black"
      />
      <path 
        d="M38.6731 21.0681V41.2953C38.6731 41.5924 38.5551 41.8772 38.3451 42.0873C38.1351 42.2973 37.8502 42.4153 37.5531 42.4153H18.4459C18.1489 42.4153 17.864 42.2973 17.654 42.0873C17.4439 41.8772 17.3259 41.5924 17.3259 41.2953V21.0681H38.6731ZM40.9131 18.8281H15.0859V41.2953C15.0859 42.1865 15.4399 43.0411 16.0701 43.6712C16.7002 44.3013 17.5548 44.6553 18.4459 44.6553H37.5531C38.4443 44.6553 39.2989 44.3013 39.929 43.6712C40.5591 43.0411 40.9131 42.1865 40.9131 41.2953V18.8281Z" 
        fill="black"
      />
      <path 
        d="M24.8131 24.2148H15.8363C15.4219 24.2148 15.0859 24.5508 15.0859 24.9652V25.1444C15.0859 25.5589 15.4219 25.8948 15.8363 25.8948H24.8131C25.2276 25.8948 25.5635 25.5589 25.5635 25.1444V24.9652C25.5635 24.5508 25.2276 24.2148 24.8131 24.2148Z" 
        fill="black"
      />
      <path 
        d="M24.8131 28.6055H15.8363C15.4219 28.6055 15.0859 28.9414 15.0859 29.3559V29.5351C15.0859 29.9495 15.4219 30.2855 15.8363 30.2855H24.8131C25.2276 30.2855 25.5635 29.9495 25.5635 29.5351V29.3559C25.5635 28.9414 25.2276 28.6055 24.8131 28.6055Z" 
        fill="black"
      />
      <path 
        d="M24.8131 32.9961H15.8363C15.4219 32.9961 15.0859 33.3321 15.0859 33.7465V33.9257C15.0859 34.3401 15.4219 34.6761 15.8363 34.6761H24.8131C25.2276 34.6761 25.5635 34.3401 25.5635 33.9257V33.7465C25.5635 33.3321 25.2276 32.9961 24.8131 32.9961Z" 
        fill="black"
      />
      <path 
        d="M24.8131 37.3848H15.8363C15.4219 37.3848 15.0859 37.7207 15.0859 38.1352V38.3144C15.0859 38.7288 15.4219 39.0648 15.8363 39.0648H24.8131C25.2276 39.0648 25.5635 38.7288 25.5635 38.3144V38.1352C25.5635 37.7207 25.2276 37.3848 24.8131 37.3848Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconCollect;