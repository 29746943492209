import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import style from './style.module.scss';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';

export interface IFaqQuestion {
  id: number, 
  question: string,
  answer: any,
}

interface Props {
  data: IFaqQuestion[] | [],
  multiply?: boolean,
  headline?: string,
}

const CustomAccordion: React.FC<Props> = ({headline,  data, multiply }) => {

  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [faqOpen, setFaqOpen] = React.useState(false);

  const theme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 'none',
            boxShadow: 'none',
            '&:before': {
              content: 'none',
            },
            '&:after': {
              position: 'absolute',
              left: '0',
              bottom: '0',
              right: '0',
              height: '1px',
              content: '""',
              opacity: multiply ? '1' : '0',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
              transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            paddingLeft: '0px',
            height: '66px',
            width: '100%'
          },
          content: {
            margin: '0'
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            paddingLeft: '0',
            paddingRight: '0',
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontWeight: 'bold',
            fontFamily: 'Avenir Next',
            fontSize: '1.5rem',
            lineHeight: '1.2rem',
          },
          body2: {
            color: 'rgba(128,128,128,1)',
            fontSize: '1.5rem',
            lineHeight: '2.3rem'
          }
        }
      }
    },
  });

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeSingleFaq = (open: boolean) => () => {
    setFaqOpen(open);
  };

  return (
    <div className={style.section}>
      <ThemeProvider theme={theme}>
        {multiply 
          ? (<>

            {
              headline ?
                <h2 className={style.headline}>{headline}</h2> : (
                  <h2 className={style.title}>FAQ</h2>
                )
            }
            
            {data?.map(question => (
              <Accordion key={question.id} expanded={expanded === question.id} onChange={handleChange(question.id)}>
                <AccordionSummary
                  expandIcon={<span className='accordion-icon' />}
                >
                  <Typography variant='body1'>
                    {question.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'div'} variant='body2'>
                    <div className={style.content} dangerouslySetInnerHTML={{__html: question.answer}} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </>) 
          : (
            <>
              <Accordion expanded={faqOpen} onChange={handleChangeSingleFaq(!faqOpen)}>
                <AccordionSummary
                  expandIcon={<span className='accordion-icon' />}
                >
                  <Typography variant='body1'>
                    {
                      headline ?
                        <h2 className={style.headline}>{headline}</h2> : (
                          <h2 className={style.title}>FAQ</h2>
                        )
                    }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'div'} variant='body2'>
                    {data?.map(question => (
                      <div key={question.id} className={style.singleQuestion}>
                        <div>{question.question}</div>
                        <div>{question.answer}</div>
                      </div>
                    ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>)}
      </ThemeProvider>
    </div>
  );
};

export default CustomAccordion;