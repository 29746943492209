import React from 'react';
// styles
import style from './style.module.scss';

interface Card {
  id: number,
  content: string,
  image: string,
}

interface Props {
  list: Card[];
}

const DescriptionPoints: React.FC<Props> = ({list}) => {
  return (
    <div className={style.description}>
      <ul className={style.list}>
        {
          list.map((item, index) => (
            <li key={index} className={style.item}>
              <span className={style.icon}  style={{backgroundImage: `url(${process.env.REACT_APP_API_DOMAIN}/products_images/${item.image})`}}></span>
              {item.content}
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default DescriptionPoints;