import React, {useMemo, useState} from 'react';
import CheckoutLayoutFooter from '../../components/CheckoutLayoutFooter';
import CheckoutLayoutHeader from '../../components/CheckoutLayoutHeader';
import LayoutFooter from '../../components/LayoutFooter';
import LayoutHeader from '../../components/LayoutHeader';
import OrderSummary from '../../components/OrderComponents/OrderSummary';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import classNames from 'classnames';
import style from './style.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import { getCartTotal } from '../../store/selectors/cartProducts';
import { formateCartPrice } from '../../utils/formatting';

export interface Layout {
  children: React.ReactNode,
  isCheckout?: boolean,
}

export function Layout({ children, isCheckout }: Layout): JSX.Element {
  const total = useTypedSelector(getCartTotal);

  const [panel, setPanel] = useState<boolean>(false);

  const togglePanelClick = () => {
    setPanel(!panel);
  };

  const [windowWidth] = useWindowSize();

  return isCheckout ? (
    <>
      <CheckoutLayoutHeader />
      {
        windowWidth <= 1023 ? (
          <div className={classNames(style.panel, panel ? style.open : '')}>
            <div className={style.inner}>
              <div className={style.head}>
                <div className={style.label}>Checkout</div>
                <div className={style.total} onClick={() => togglePanelClick()} aria-hidden="true">
                  <span className={style.price}>${formateCartPrice(total)}</span>
                </div> 
              </div>
              <div className={style.body}>
                <OrderSummary />
              </div>
            </div>
          </div>
        ) : null
      }
      <main className={classNames(style.main, style.mainCheckout)}>
        {children}
      </main>
      <CheckoutLayoutFooter />
    </>
  ) : (
    <>
      <LayoutHeader />
      <main className={style.main}>
        {children}
      </main>
      <LayoutFooter />
    </>
  );
}