import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProductsList from '../../components/ProductsList';
import SearchControlDefault from '../../components/SearchControlDefault';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import { fetchProductsCategory } from '../../store/actions/productsCategory';
import { ProductsCategoryState } from '../../types/productsСategory';
import style from './style.module.scss';

export const SearchResults: React.FC = (): JSX.Element => {

  const searchResult = useTypedSelector(state => state.search.search);
  const searchText = useTypedSelector(state => state.search.text);
  const dispatch = useDispatch();
  const { list }: ProductsCategoryState = useTypedSelector(state => state.productsCategory);
  const productsRandom = list.slice(0, 4);

  useEffect(() => {
    dispatch(fetchProductsCategory(Number(2)));
  }, [dispatch]);
  
  return (
    <div className={style.search}>
      <div className="container">
        <div className={style.header}>
          <h1 className={style.title}>Search results</h1>
          <div className={style.control}>
            <SearchControlDefault />
          </div>
        </div>
        <div className={style.body}>
          {
            searchText === '' ? (
              <div className={style.list}>
                <ProductsList products={searchResult}  />
              </div>
            ) : (
              searchResult.length > 0 ? (
                <div className={style.list}>
                  <ProductsList products={searchResult}  />
                </div>
              ) : (
                <>
                  <div className={style.notFound}>
                    <p>Sorry we can’t find any results for</p>
                    <p>
                      <strong>&apos;{searchText}&apos;</strong>
                    </p>
                    <p>Try another search term, or take a look at our popular services below.</p>
                  </div>
                  <div className={style.list}>
                    <ProductsList products={productsRandom}  />
                  </div>
                </>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};
