import { ChangeActiveDate, ChangeActiveDoctor, ChangeActiveTime, ClearDoctorData, Doctor, DoctorsAction, DoctorsActionTypes } from '../../types/bookConsultation';
import { Dispatch } from 'react';
import axios, { AxiosPromise } from 'axios';
import { format, parseISO } from 'date-fns';
import userPreview from '../../assets/images/icons/user.svg';

type ReturnValue = (dispatch: Dispatch<DoctorsAction>) => void;

export const fetchDoctorsCheckout = (): any => {
  return async (dispatch: Dispatch<DoctorsAction>) => {
    try {
      dispatch({
        type: DoctorsActionTypes.FETCH_DOCTORS
      });
      const dateNow = new Date();
      const startDate = format(parseISO(dateNow.toISOString()), 'dd-MM-yyyy');
      const endDate = format(parseISO(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy');
      const response = await axios.get(`${process.env.REACT_APP_API}/availability/main-consultation?start_date=${startDate}&end_date=${endDate}`);
      const doctors = response.data.data.clinic_employees;

      const promises: AxiosPromise[] = [];
      doctors.forEach((item: any) => {
        const dateNow = new Date();
        const startDate = format(parseISO(dateNow.toISOString()), 'dd-MM-yyyy');
        const endDate = format(parseISO(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy');
        promises.push(axios.get(`${process.env.REACT_APP_API}/availability/clinic-employee/${item.id}?start_date=${startDate}&end_date=${endDate}`));
      });
      axios.all(promises)
        .then(axios.spread((...items) => {
          const doctorsWithDates: Doctor[] = [];
          items.forEach((item, index) => {
            const preview = doctors[index].image !== null ? `${process.env.REACT_APP_API_DOMAIN}/employees_images/${doctors[index].image}` : userPreview;
            doctorsWithDates.push({
              type: 'doctor',
              id: doctors[index].id,
              name: doctors[index].name,
              photo: preview,
              disabledDates: item.data.data,
            });
          });
          dispatch({
            type: DoctorsActionTypes.FETCH_DOCTORS_SUCCESS,
            payload: doctorsWithDates
          });
          dispatch({
            type: DoctorsActionTypes.CHANGE_ACTIVE_DATE_DOCTOR,
            payload: Object.entries(doctorsWithDates[0].disabledDates).map((el, index) => {
              return [el[0], Object.values(el[1]).map(elem => Object.values(elem)).flat().some(el => el)];
            }).filter(el => el[1])[0][0] as string
          });
        }));
    } catch (e) {
      dispatch({
        type: DoctorsActionTypes.FETCH_DOCTORS_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const fetchDoctors = (doctors: any[]): ReturnValue => {
  return async (dispatch: Dispatch<DoctorsAction>) => {
    try {
      dispatch({
        type: DoctorsActionTypes.FETCH_DOCTORS
      });
      const promises: AxiosPromise[] = [];
      doctors.forEach((item) => {
        const dateNow = new Date();
        const startDate = format(parseISO(dateNow.toISOString()), 'dd-MM-yyyy');
        const endDate = format(parseISO(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy');
        promises.push(axios.get(`${process.env.REACT_APP_API}/availability/clinic-employee/${item.id}?start_date=${startDate}&end_date=${endDate}`));
      });
      axios.all(promises)
        .then(axios.spread((...items) => {
          const doctorsWithDates: Doctor[] = [];
          items.forEach((item, index) => {
            const preview = doctors[index].image !== null ? `${process.env.REACT_APP_API_DOMAIN}/employees_images/${doctors[index].image}` : userPreview;
            doctorsWithDates.push({
              type: 'doctor',
              id: doctors[index].id,
              name: doctors[index].name,
              photo: preview,
              disabledDates: item.data.data,
            });
          });
          dispatch({
            type: DoctorsActionTypes.FETCH_DOCTORS_SUCCESS,
            payload: doctorsWithDates
          });

          if (doctorsWithDates.length) {
            dispatch({
              type: DoctorsActionTypes.CHANGE_ACTIVE_DATE_DOCTOR,
              payload: Object.entries(doctorsWithDates[0].disabledDates).map((el, index) => {
                return [el[0], Object.values(el[1]).map(elem => Object.values(elem)).flat().some(el => el)];
              }).filter(el => el[1])[0][0] as string
            });
          }
        }));
    } catch (e) {
      dispatch({
        type: DoctorsActionTypes.FETCH_DOCTORS_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const changeCalendarMonth = (doctors: any[], dates: string[]): any => {
  return async (dispatch: Dispatch<DoctorsAction>) => {
    try {
      const promises: AxiosPromise[] = [];
      doctors.forEach((item) => {
        promises.push(axios.get(`${process.env.REACT_APP_API}/availability/clinic-employee/${item.id}?start_date=${dates[0]}&end_date=${dates[1]}`));
      });
      axios.all(promises)
        .then(axios.spread((...items) => {
          const doctorsWithDates: Doctor[] = [];
          items.forEach((item, index) => {
            const preview = doctors[index].image !== null ? `${process.env.REACT_APP_API_DOMAIN}/employees_images/${doctors[index].image}` : userPreview;
            doctorsWithDates.push({
              type: 'doctor',
              id: doctors[index].id,
              name: doctors[index].name,
              photo: preview,
              disabledDates: item.data.data,
            });
          });
          dispatch({
            type: DoctorsActionTypes.CHANGE_MONTH,
            payload: doctorsWithDates
          });
        }));
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeActiveDateAction = (payload:  string): ChangeActiveDate => ({type: DoctorsActionTypes.CHANGE_ACTIVE_DATE_DOCTOR, payload});
export const changeActiveDoctorAction = (payload: number): ChangeActiveDoctor => ({type: DoctorsActionTypes.CHANGE_ACTIVE_DOCTOR, payload});
export const changeActiveTimeAction = (payload: string | null): ChangeActiveTime => ({type: DoctorsActionTypes.CHANGE_ACTIVE_TIME_DOCTOR, payload});
export const clearDoctorDataAction = (): ClearDoctorData => ({type: DoctorsActionTypes.CLEAR_DOCTOR_DATA});

