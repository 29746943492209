import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import DateFnsUtils from '@date-io/date-fns';
import style from './style.module.scss';
import { initialBookingTime } from './consts';
import withDisableTime from '../../withDisableTime';
import DateAndTimePicker from '../../DateAndTimePicker';
import BookTypePicker from '../../BookTypePicker';
import BookTitle from '../../BookTitle';
import BookNotice from '../../BookNotice';
import BookButtons from '../../BookButtons';
import { changeActiveClinicAction, changeActiveDateAction, changeActiveTimeAction, changeCalendarMonth, clearClinicDataAction, fetchClinic } from '../../../../store/actions/bookClinicVisit';
import { changeStepAction, clearStepAction } from '../../../../store/actions/order';
import { getCartProductsContainTypes } from '../../../../store/selectors/cartProducts';
import { ServiceLocationType, StepTypes } from '../../../../types/order';
import { ProductTypes } from '../../../../types/product';
import { getOrderState } from '../../../../store/selectors/order';
import { format, parseISO } from 'date-fns';

const BookClinicVisit: React.FC = () => {
  const { clinics, activeClinic, activeDate, activeTime, loading } = useTypedSelector(state => state.bookClinicVisit);
  const { serviceLocation } = useTypedSelector(getOrderState);
  const isOrderContainBloodCollection = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.BLOOD_TEST]));
  const isOrderContainOnlineConsultation = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.ONLINE_CONSULTATION]));
  const isOrderContainOtherProducts = useTypedSelector(state => getCartProductsContainTypes(state, [
    ProductTypes.TESTING_KIT,
    ProductTypes.PRESCRIPTION,
    ProductTypes.BLOOD_TEST,
  ]));
  const [bookingTime, setBookingTime] = useState(initialBookingTime);
  const dispatch = useTypedDispatch();

  const changeClinic = useCallback((clinicId) => {
    if (activeClinic && activeClinic.id !== clinicId) {
      dispatch(changeActiveClinicAction(clinicId));
      dispatch(changeActiveTimeAction(null));

      const getClinic = clinics.find((el) => {
        return el.id === clinicId;
      });

      if (getClinic) {
        dispatch(changeActiveDateAction(Object.keys(getClinic.disabledDates)[0]));
      }
      
    }
  }, [activeClinic, dispatch]);

  const changeDate = (date: string) => {
    dispatch(changeActiveDateAction(date));
    dispatch(changeActiveTimeAction(null));
  };

  const changeTime = (time: string) => {
    dispatch(changeActiveTimeAction(time));
  };

  const onClickBack = useCallback(() => {
    if ((isOrderContainOtherProducts && isOrderContainOnlineConsultation) || 
        serviceLocation === ServiceLocationType.CLINIC) {
      dispatch(changeStepAction(StepTypes.SERVICE_LOCATION));
    } else if (isOrderContainOtherProducts) {
      dispatch(changeStepAction(StepTypes.BOOK_CONSULTATION));
    } else {
      dispatch(clearStepAction());
    }
    dispatch(clearClinicDataAction());
  }, [dispatch, isOrderContainOtherProducts, isOrderContainOnlineConsultation, serviceLocation]);

  const onClickContinue = useCallback(() => {
    if (isOrderContainBloodCollection && serviceLocation === ServiceLocationType.HOME) {
      dispatch(changeStepAction(StepTypes.BOOK_BLOOD_COLLECTION));
    } else {
      dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    }
  }, [dispatch, isOrderContainBloodCollection, serviceLocation]);

  useEffect(() => {
    dispatch(fetchClinic());
  }, []);

  const onChangeMonth = useCallback((date) => {
    const dates = [format(parseISO(date.toISOString()), 'dd-MM-yyyy').toString(), format(parseISO(new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy').toString()];
    dispatch(changeCalendarMonth(dates));
  }, []);

  const withoutPastTimeDates = useMemo(() => {
    if(activeClinic && Object.keys(activeClinic.disabledDates)[0] === activeDate && Object.keys(activeClinic.disabledDates).length !== 0) {
      const dateNow = new Date().getTime();
      const newDisableDates = {...activeClinic.disabledDates};
      const keys = Object.keys(activeClinic.disabledDates[activeDate]);
      
      for(let i = 0; i <= keys.length; i++) {
        for(const key in activeClinic.disabledDates[activeDate][keys[i]]) {
          if(new Date(`${activeDate} ${key}`).getTime() < dateNow) {
            newDisableDates[activeDate][keys[i]][key] = false;
          }
        }
      }
      return newDisableDates;
    } else if(activeClinic && activeClinic.disabledDates) {
      return activeClinic.disabledDates;
    }
  }, [activeClinic, activeDate]);

  return clinics.length && !loading ? (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={style.wrapper}>
        <BookTitle 
          title="Book Clinic Visit"
          description="You have opted for in-clinic services. Book your preferred date, time and partner clinics."  
        />
        <BookTypePicker 
          typeData={clinics}
          activeTypeData={activeClinic}
          changeActiveTypeData={changeClinic}
          title='Select a clinic:'
        />
        <DateAndTimePicker 
          activeDate={activeDate}
          activeTime={activeTime}
          changeDate={changeDate}
          changeTime={changeTime} 
          onChangeMonth={onChangeMonth}
          bookingDates={withoutPastTimeDates}
          bookingTime={activeClinic ? activeClinic.disabledDates[activeDate] : bookingTime}
          title={'Select appointment time:'}
          withShortView
        />
        <BookNotice
          title="Bring the following on your visit:"
          component={(
            <div>Valid government-issued photo ID, such as your NRIC, employment pass, or passport.</div>
          )}
        />
        <BookButtons 
          onClickBack={onClickBack} 
          onClickContinue={onClickContinue} 
          activeContinueButton={Boolean(activeTime)}
          buttonText="Continue" 
        />
      </div>
    </MuiPickersUtilsProvider>
  ) : (
    <svg className='spinner' viewBox='0 0 50 50'>
      <circle
        className='path'
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='5'
      ></circle>
    </svg>
  );
};

export default BookClinicVisit;