import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import { changeActiveDateAction, changeActiveTimeAction, changeShippingAddress, clearDeliveryDetailsActiveTime } from '../../../../store/actions/deliveryDetails';
import DateFnsUtils from '@date-io/date-fns';
import style from './style.module.scss';
import { initialBookingTime } from './consts';
import DateAndTimePicker from '../../DateAndTimePicker';
import BookTitle from '../../BookTitle';
import BookButtons from '../../BookButtons';
import { StepTypes } from '../../../../types/order';
import { changeStepAction } from '../../../../store/actions/order';
import AddressForm from '../../AddressForm';
import { Address } from '../../../../types/shipping';
import { changeActiveDeliveryDetailsOptionAction } from '../../../../store/actions/deliveryDetails';
import RadioButtonsGroup from '../../../CustomRadioButtonsGroup';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { ProductFeeTypes } from '../../../../types/product';
import { getCartProductFees } from '../../../../store/selectors/cartProducts';
import { clearDeliveryDetails } from '../../../../store/actions/deliveryDetails';

const DeliveryDetails: React.FC = () => {

  const [bookingDates, setBookingDates] = useState<any>({});

  const dispatch = useTypedDispatch();
  const { activeDate, activeTime, address } = useTypedSelector(state => state.deliveryDetails);
  const cartProductFees = useTypedSelector(getCartProductFees);

  const [shippingAddress, setShippingAddress] = useState<Address>(address);

  const { activeDeliveryOption } = useTypedSelector(state => state.deliveryDetails);

  const [bookingTime, setBookingTime] = useState(initialBookingTime);

  const changeDate = (date: string) => {
    dispatch(changeActiveDateAction(date));
    dispatch(changeActiveTimeAction(null));
  };

  const changeTime = (time: string) => {
    dispatch(changeActiveTimeAction(time));
  };

  const onClickBack = useCallback(() => {
    dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    dispatch(clearDeliveryDetails());
  }, [dispatch]);

  const onClickContinue = useCallback(() => {
    dispatch(changeShippingAddress(shippingAddress));
    dispatch(changeStepAction(StepTypes.PAYMENT_DETAILS));
    
  }, [dispatch, shippingAddress]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[]);

  const getActualFees = useCallback(() => {
    return cartProductFees
      .filter(fee => ![ProductFeeTypes.CONSULTATION_FEE, ProductFeeTypes.HOME_COLLECTION_FEE].includes(fee.type))
      .map(fee => ({
        id: fee.id,
        value: `${fee.name} (${fee.price ? `$${fee.price}` : 'FREE' })`,
        text: `${fee.name} (${fee.price ? `$${fee.price}` : 'FREE' })`,
        price: fee.price
      }));
  }, [cartProductFees]);

  const onChangeDelivery = useCallback((type) => {
    dispatch(clearDeliveryDetailsActiveTime());
    const checkedFee = getActualFees().find(item => item.value === type);

    if (checkedFee) {
      dispatch(changeActiveDeliveryDetailsOptionAction(checkedFee));
    }
  }, [dispatch, getActualFees]);

  useEffect(() => {
    if (activeDeliveryOption && activeDeliveryOption.id) {
      const dateNow = new Date();
      const startDate = format(parseISO(dateNow.toISOString()), 'dd-MM-yyyy');
      const endDate = format(parseISO(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy');
      axios.get(`${process.env.REACT_APP_API}/availability/delivery?start_date=${startDate}&end_date=${endDate}&fee_id=${activeDeliveryOption.id}`)
        .then(res => {
          const result = {...res.data.data};
          for (const key in result) {
            const tempTimes = [
              ...result[key].map((el: any) => ({[`${el.from}-${el.to}`]: true}))
            ];

            const resultTimes: any = {};
            tempTimes.forEach((el: any, index: number) => {
              resultTimes[Object.keys(el)[0]] = true;
            });

            result[key] = {'Choose the time': {...resultTimes}};
          }
          setBookingDates(result);
        });
    }
  }, [activeDeliveryOption]);

  const onChangeMonth = useCallback((date) => {
    if (activeDeliveryOption && activeDeliveryOption.id) {
      const dates = [format(parseISO(date.toISOString()), 'dd-MM-yyyy').toString(), format(parseISO(new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy').toString()];
      axios.get(`${process.env.REACT_APP_API}/availability/delivery?start_date=${dates[0]}&end_date=${dates[1]}&fee_id=${activeDeliveryOption.id}`)
        .then(res => {
          const result = {...res.data.data};
          for (const key in result) {
            const tempTimes = [
              ...result[key].map((el: any) => ({[`${el.from}-${el.to}`]: true}))
            ];

            const resultTimes: any = {};
            tempTimes.forEach((el: any, index: number) => {
              resultTimes[Object.keys(el)[0]] = true;
            });

            result[key] = {'Choose the time': {...resultTimes}};
          }

          setBookingDates((prev: any) => ({...prev, ...result}));
        });
    }
  }, [activeDeliveryOption]);

  const isActiveButton = useMemo(() => {
    if (activeDeliveryOption && Object.keys(bookingDates).length) {
      if (activeTime) {
        return true;
      } else {
        return false;
      }
    } else if (shippingAddress.additionalInfo.length === 0 || shippingAddress.postalCode.length === 0  || shippingAddress.streetAddress.length === 0 || activeDeliveryOption === null) {
      return false;
    } else {
      return true;
    }
  }, [activeDeliveryOption, bookingDates, activeTime, shippingAddress]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={style.wrapper}>
        <BookTitle 
          title="Enter Delivery Details"
        />
        <AddressForm
          border={false}
          title="Shipping address:"
          address={shippingAddress}
          setAddress={setShippingAddress}
        />
        <div className={style.deliveryOptions}>
          <div className={style.title}>Select delivery option:</div>
          <RadioButtonsGroup
            options={getActualFees()}
            activeOption={activeDeliveryOption}
            onChange={onChangeDelivery}
          />
        </div>
        {activeDeliveryOption &&
        Object.keys(bookingDates).length ? 
          <DateAndTimePicker 
            activeDate={activeDate}
            activeTime={activeTime}
            changeDate={changeDate}
            changeTime={changeTime} 
            bookingDates={bookingDates}
            bookingTime={bookingDates && bookingDates[activeDate] ? bookingDates[activeDate] : bookingTime}
            onChangeMonth={onChangeMonth}
            title={'Select delivery time:'}
            withShortView={false}
            range
          /> : null}
        <BookButtons 
          onClickBack={onClickBack} 
          onClickContinue={onClickContinue}
          activeContinueButton={isActiveButton}
          // activeContinueButton
          buttonText="Continue to Payment" 
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DeliveryDetails;