import React from 'react';
import style from './style.module.scss';

const Links = [
  {
    name: 'Facebook',
    to: 'https://www.facebook.com/fernehealth',
    image: '/assets/images/icons/icon-facebook.webp'
  },
  {
    name: 'Instragram',
    to: 'https://www.instagram.com/fernehealth.sg',
    image: '/assets/images/icons/icon-instagram.webp'
  },
  {
    name: 'TikTok',
    to: 'https://www.tiktok.com/@fernehealth',
    image: '/assets/images/icons/icon-tiktok.webp'
  },
  {
    name: 'LinkedIn',
    to: 'https://www.linkedin.com/company/ferne-health',
    image: '/assets/images/icons/icon-linkedin.webp'
  }
];

const SocialLinks: React.FC = () => {
  return (
    <ul className={style.list}>
      {
        Links.map((link, index) => (
          <li className={style.item} key={index}>
            <a href={link.to} className={style.link}>
              <img src={link.image} alt={link.name} className={style.icon} />
            </a>
          </li>
        ))
      }
    </ul>
  );
};

export default SocialLinks;