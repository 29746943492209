import React from 'react';
import style from './style.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const CustomButton: React.FC<Props> = ({ text, ...props }) => {
  return <button className={style.button} {...props}>{text}</button>;
};

export default CustomButton;