import React from 'react';
import CustomSwiper from '../CustomSwiper';
import style from './style.module.scss';
import AddButton from '../AddButton';

interface Card {
  id: number;
  name: string;
  summary: string;
  product_id: number;
  price: number;
}

interface Props {
  data: any;
  onAdd: (index: number) => void;
}

const ProductVariations: React.FC<Props> = ({data, onAdd}) => {
  const result = Object.values(data);

  const slides = (
    result.map((item: any, index: number) => (
      <div key={index} className={style.cell}>
        <div className={style.card}>
          <div className={style.header}>
            <strong className={style.title}>{item['name']}</strong>
            <div className={style.price}>${item['price']}</div>
          </div>
          <div className={style.body}>
            {
              item['summary'] !== null ? (
                <ul className={style.list}>
                  {
                    item['summary'].split(',').map((el: string, i: number) => (
                      <li key={i} className={style.item}>{el}</li>
                    )
                    )
                  }
                </ul>
              ) : null
            }
          </div>
          <div className={style.footer}>
            <AddButton
              onAdd={() => onAdd(index)}
              full
            />
          </div>
        </div>
      </div>
    )
    ));

  return (
    <div className={style.variations}>
      <div className='swiper-stretch pagination-relative'>
        <CustomSwiper
          parameters={{ slidesPerView: 'auto', followFinger: false, simulateTouch: false, loop: false, spaceBetween: 10 }}
          classesForButtons={['.swiper-button-next', '.swiper-button-prev']}
        >
          {slides}
        </CustomSwiper>
      </div>
    </div>
  );
};

export default ProductVariations;