import React from 'react';
import style from './style.module.scss';

interface Card {
  description: string,
  header: string, 
  symptoms: string,
}

interface Props {
  data: Array<{
    attributes: Card;
  }>;
}

const SectionContentSymptoms: React.FC<Props> = ({data}) => {
  
  return (
    <div className={style.grid}>
      {data.map((item, index) => {
        return <div key={index} className={style.cell}>
          <div className={style.card}>
            <div className={style.title}>
              {item.attributes.header}
            </div>
            {
              item && item.attributes.symptoms ? (
                <div className={style.symptoms} dangerouslySetInnerHTML={{__html: item.attributes.symptoms}} />
              ) : null
            }
            <div className={style.description}>
              {item.attributes.description}
            </div>
          </div>
        </div>;
      }
      )}
    </div>
  );
};

export default SectionContentSymptoms;