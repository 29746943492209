import React from 'react';
import Swiper, { ReactIdSwiperChildren } from 'react-id-swiper';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
// Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

// styles
import './style.scss';

SwiperCore.use([Pagination, Navigation, Autoplay]);

interface Props {
  parameters?: SwiperOptions;
  classesForButtons: [string, string];
  children: ReactIdSwiperChildren;
}

const CustomSwiper: React.FC<Props> = ({ parameters, children, classesForButtons }) => {
  return (
    <Swiper
      {...parameters} 
      pagination={{
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }}
      navigation={{
        nextEl: classesForButtons[0],
        prevEl: classesForButtons[1], 
      }}
    >
      {children}
    </Swiper>
  );
};

export default CustomSwiper;