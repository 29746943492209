import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import { changeActiveDateAction, changeActiveDoctorAction, changeActiveTimeAction, changeCalendarMonth, clearDoctorDataAction, fetchDoctorsCheckout } from '../../../../store/actions/bookConsultation';
import DateFnsUtils from '@date-io/date-fns';
import style from './style.module.scss';
import { initialBookingTime } from './consts';
import DateAndTimePicker from '../../DateAndTimePicker';
import BookTypePicker from '../../BookTypePicker';
import BookTitle from '../../BookTitle';
import BookNotice from '../../BookNotice';
import BookButtons from '../../BookButtons';
import { StepTypes } from '../../../../types/order';
import { changeStepAction } from '../../../../store/actions/order';
import { getCartProductsContainTypes } from '../../../../store/selectors/cartProducts';
import { ProductTypes } from '../../../../types/product';
import { format, parseISO } from 'date-fns';

const BookConsultation: React.FC = () => {
  const { doctors, activeDoctor, activeDate, activeTime, loadingBookConsultation } = useTypedSelector(state => state.bookConsultation);

  const isOrderContainClinicVisit = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.CLINIC_VISIT]));

  const isOrderContainBloodCollection = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.BLOOD_TEST]));

  const [bookingTime, setBookingTime] = useState(initialBookingTime);
  const dispatch = useTypedDispatch();

  const changeDoctor = useCallback((doctorId) => {
    if (activeDoctor && activeDoctor.id !== doctorId) {
      dispatch(changeActiveDoctorAction(doctorId));
      dispatch(changeActiveTimeAction(null));

      const getDoctor = doctors.find((el) => {
        return el.id === doctorId;
      });

      if (getDoctor) {
        dispatch(changeActiveDateAction(Object.keys(getDoctor.disabledDates)[0]));
      }

    }
  }, [activeDoctor, dispatch]);

  const changeDate = (date: string) => {
    if (activeDoctor) {
      dispatch(changeActiveDateAction(date));
      dispatch(changeActiveTimeAction(null));
    }
    
  };

  const changeTime = (time: string) => {
    dispatch(changeActiveTimeAction(time));
  };

  const onClickBack = useCallback(() => {
    dispatch(changeStepAction(StepTypes.SERVICE_LOCATION));
    dispatch(clearDoctorDataAction());
  }, [dispatch]);

  const onClickContinue = useCallback(() => {
    if (isOrderContainClinicVisit) {
      dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT));
    } else if (isOrderContainBloodCollection) {
      dispatch(changeStepAction(StepTypes.BOOK_BLOOD_COLLECTION));
    } else {
      dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    }

  }, [dispatch, isOrderContainClinicVisit, isOrderContainBloodCollection]);

  useEffect(() => {
    dispatch(fetchDoctorsCheckout());
  }, []);

  const onChangeMonth = useCallback((date) => {
    const dates = [format(parseISO(date.toISOString()), 'dd-MM-yyyy').toString(), format(parseISO(new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy').toString()];
    if (doctors) {
      dispatch(changeCalendarMonth(doctors, dates));
    }
  }, [doctors]);

  const withoutPastTimeDates = useMemo(() => {
    if (activeDoctor && Object.keys(activeDoctor.disabledDates)[0] === activeDate && Object.keys(activeDoctor.disabledDates).length !== 0) {
      const dateNow = new Date().getTime();
      const newDisableDates = {...activeDoctor.disabledDates};
      const keys = Object.keys(activeDoctor.disabledDates[activeDate]);
      
      for (let i = 0; i <= keys.length; i++) {
        for (const key in activeDoctor.disabledDates[activeDate][keys[i]]) {
          if (new Date(`${activeDate} ${key}`).getTime() < dateNow) {
            newDisableDates[activeDate][keys[i]][key] = false;
          }
        }
      }
      return newDisableDates;
    } else if (activeDoctor && activeDoctor.disabledDates) {
      return activeDoctor.disabledDates;
    }
  }, [activeDoctor, activeDate]);

  return !loadingBookConsultation && doctors.length > 0 ? (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={style.wrapper}>
        <BookTitle 
          title="Book Consultation"
          description="Please note that consultation is required for your order. You will receive a tele-consultation with our doctor (approx. 5 - 10 min) to discuss your needs and preferences."  
        />
        <BookTypePicker 
          typeData={doctors}
          activeTypeData={activeDoctor}
          changeActiveTypeData={changeDoctor}
          title='Select a doctor:'
        />
        <DateAndTimePicker 
          activeDate={activeDate}
          activeTime={activeTime}
          changeDate={changeDate}
          changeTime={changeTime} 
          onChangeMonth={onChangeMonth}
          bookingDates={withoutPastTimeDates}
          bookingTime={activeDoctor ? activeDoctor.disabledDates[activeDate] : bookingTime}
          title={'Select appointment time:'}
          withShortView
        />
        <BookNotice
          title="Prepare the following for your tele-consultation:"
          component={(
            <ul>
              <li>Mobile phone, tablet, or laptop that is compatible for WhatsApp video calls.</li>
              <li>Valid government-issued photo ID, such as your NRIC, employment pass, or passport.</li>
            </ul>
          )}
        />
        <BookButtons 
          onClickBack={onClickBack} 
          onClickContinue={onClickContinue} 
          activeContinueButton={Boolean(activeTime)}
          buttonText="Continue" 
        />
      </div>
    </MuiPickersUtilsProvider>
  ) : (
    <svg className='spinner' viewBox='0 0 50 50'>
      <circle
        className='path'
        cx='25'
        cy='25'
        r='20'
        fill='none'
        strokeWidth='5'
      ></circle>
    </svg>
  );
};

export default BookConsultation;