import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import DateFnsUtils from '@date-io/date-fns';
import style from './style.module.scss';
import BookTitle from '../../BookTitle';
import BookButtons from '../../BookButtons';
import { ServiceLocationType, StepTypes } from '../../../../types/order';
import { changeStepAction } from '../../../../store/actions/order';
import PaymentForm from '../../PaymentForm';
import { Address, BillingAddressOption, BillingAddressOptionValues, PaymentDetailsOption, PaymentDetailsOptionValues } from '../../../../types/paymentDetails';
import { changeActiveBillingAddressOptionAction, changeActivePaymentOptionAction, changeBillingAddressAction, changePromoCodeAction } from '../../../../store/actions/paymentDetails';
import RadioButtonsGroup from '../../../CustomRadioButtonsGroup';
import BillingAddressForm from '../../BillingAddressForm';
import { getCartProductsContainTypes } from '../../../../store/selectors/cartProducts';
import { ProductTypes } from '../../../../types/product';
import { getOrderState } from '../../../../store/selectors/order';

const PaymentDetails: React.FC = () => {

  const dispatch = useTypedDispatch();
  const { serviceLocation } = useTypedSelector(getOrderState);
  const { activePaymentOption, paymentOptions, activeBillingAddressOption, billingAddressOptions, billingAddress, promoCode } = useTypedSelector(state => state.paymentDetails);
  const isOrderContainBloodCollection = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.BLOOD_TEST]));

  const setAddress = useCallback((address) => {
    dispatch(changeBillingAddressAction(address));
  }, [dispatch]);

  const onClickBack = useCallback(() => {
    if (!isOrderContainBloodCollection && serviceLocation !== ServiceLocationType.CLINIC) {
      dispatch(changeStepAction(StepTypes.DELIVERY_DETAILS));
    } else {
      dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    }
    
  }, [dispatch, isOrderContainBloodCollection, serviceLocation]);

  const onClickContinue = useCallback(() => {
    dispatch(changeStepAction(StepTypes.REVIEW_ORDER));
  }, [dispatch]);

  const onChangePayment = useCallback((option: PaymentDetailsOptionValues)  => {
    dispatch(changeActivePaymentOptionAction(option));
  }, [dispatch]);

  const onChangeBillingAddressActiveOption = useCallback((option: string)  => {
    const chooseOption = billingAddressOptions.find(item => item.value === option);
    dispatch(changeActiveBillingAddressOptionAction(chooseOption as BillingAddressOption));
  }, [dispatch]);

  const checkPayment = paymentOptions.find(item => item.value === activePaymentOption) === undefined ? false : true;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={style.wrapper}>
        <div className={style.titleWrapper}>
          <BookTitle
            title="Enter Payment Details"
          />
        </div>
        <div className={style.paymentContainer}>
          <div className={style.title}>Select payment method:</div>
          <PaymentForm 
            options={paymentOptions} 
            activeOption={paymentOptions.find(item => item.value === activePaymentOption) && paymentOptions.find(item => item.value === activePaymentOption)} 
            onChange={onChangePayment} 
            optionsWithBorder 
          />
        </div>
        <div className={style.billingAddress}>
          <div className={style.title}>Billing address:</div>
          <RadioButtonsGroup activeOption={activeBillingAddressOption} onChange={onChangeBillingAddressActiveOption} options={billingAddressOptions} />
          {activeBillingAddressOption.value === BillingAddressOptionValues.USE_A_DIFFERENT_BILLING_ADDRESS && <BillingAddressForm address={billingAddress} setAddress={setAddress} />}
        </div>
        <BookButtons 
          onClickBack={onClickBack} 
          onClickContinue={onClickContinue} 
          activeContinueButton={checkPayment}
          buttonText="Continue to Review" 
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default PaymentDetails;