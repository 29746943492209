import React from 'react';
import style from './style.module.scss';
import LogoFerne from '../../assets/images/logo-ferne';

const CheckoutLayoutHeader: React.FC = () => {
  return (
    <div className={style.header}>
      <div className={style.wrapper}>
        <div className="container">
          <div className={style.inner}>
            <div className={style.panel}>
              <a href="https://www.fernehealth.com" className={style.logo}>
                <LogoFerne />
              </a>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutLayoutHeader;