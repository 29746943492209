import React from 'react';

const IconArrowLeft: React.FC = () => {

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="7" 
      height="12" 
      viewBox="0 0 7 12" 
      fill="none"
    >
      <path 
        d="M0.806563 6.00317C0.806563 6.16148 0.86989 6.3198 0.990213 6.42746L6.01209 11.329C6.11975 11.4367 6.25907 11.5 6.41739 11.5C6.74669 11.5 7 11.253 7 10.9237C7 10.7654 6.93667 10.6197 6.83535 10.5121L2.2251 6.00317L6.83535 1.48791C6.93667 1.38659 7 1.24093 7 1.07628C7 0.746978 6.74669 0.5 6.41739 0.5C6.25907 0.5 6.11975 0.563328 6.00576 0.670984L0.990213 5.57254C0.86989 5.69286 0.806563 5.83851 0.806563 6.00317Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconArrowLeft;