import React, { useEffect, useRef, useState } from 'react';
import IconInfo from '../../../assets/images/icons/info';
import useScroll from '../../../hooks/useScroll';
import useWindowSize from '../../../hooks/useWindowSize';
import CustomTooltip from '../../CustomTooltip';
import style from './style.module.scss';
import { useTypedSelector } from '../../../hooks/productsTypedSelector';
import { Product } from '../../../types/product';
import noPreview from '../../../assets/images/no-preview.svg';
import { getCartProducts, getCartSubTotal, getCartTotal, getConsultationAndHomeCollectionFee } from '../../../store/selectors/cartProducts';
import { formateCartPrice } from '../../../utils/formatting';

interface ProductItem {
  id: number,
  name: string,
  image: string,
  price: number,
  count: number,
}

interface IOrderSummary {
  shipping: number | string,
  products: ProductItem[],
}

const OrderSummary: React.FC = () => {
  const products = useTypedSelector(getCartProducts);
  const subtotal = useTypedSelector(getCartSubTotal);
  const total = useTypedSelector(getCartTotal);
  const { consultationFee, homeCollectionFee } = useTypedSelector(getConsultationAndHomeCollectionFee);

  const { activeDeliveryOption } = useTypedSelector(state => state.deliveryDetails);

  const parseProducts = (products: Product[]) => {

    const parsedProducts = [...products].map(item => {
      switch (item.variations_type) {
      case 'single_product':
        if (item.product_type === 'online_consultation') {
          return {
            id: item.id,
            picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.preview_image}` : noPreview,
            title: item.name,
            quantity: 0,
            quantityEditable: true,
            price: item.price,
            initialProduct: item,
            consultation: {
              date: item.consultation.date,
              time: item.consultation.time,
              doctor: item.consultation.doctor,
            }
          };
        } else {
          return {
            id: item.id,
            picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.preview_image}` : noPreview,
            description: item.description ?? null,
            title: item.name,
            quantity: 0,
            quantityEditable: true,
            price: item.price,
            initialProduct: item,
          };
        }
      case 'multiple_products':
        return {
          id: item.id,
          picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.preview_image}` : noPreview,
          description: item.description ?? null,
          title: item.name,
          quantity: 0,
          quantityEditable: false,
          price: item.product_variation[0].price,
          variationName: item.product_variation[0].name,
          variationId: item.product_variation[0].id,
          initialProduct: item,
        };
      default:
        return {
          id: item.id,
          picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.preview_image}` : noPreview,
          description: item.description ?? null,
          title: item.name,
          quantity: 0,
          quantityEditable: item.variations_type === 'single_product',
          price: item.price,
          initialProduct: item,
        };
      }
    });
  
    const filteredProducts = [...parsedProducts].filter((v, i, a) => a.findIndex(t => {
      if (t.id === v.id && t.variationId === v.variationId) {
        t.quantity += 1;
        return true;
      }
  
      return false;
    }) === i);
  
    return filteredProducts;
  };

  const [summaryInfo, setSummaryInfo] = useState<IOrderSummary | null>(null);

  const [windowWidth] = useWindowSize();

  useEffect(() => {
    setSummaryInfo(prev => ({
      shipping: '--',
      products: parseProducts(products).map(item => ({
        id: item.id,
        name: item.title,
        image: item.picture,
        price: item.price * item.quantity,
        count: item.quantity,
      }))
    }));
  }, [products]);

  const [consultationFeeTooltip, setConsultationFeeTooltip] = useState(false);
  const [phlebotomistFeeTooltip, setPhlebotomistFeeTooltip] = useState(false);

  const summary = useRef() as React.MutableRefObject<HTMLDivElement>;
  const fixedElement = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [fixed, setFixed] = useState(style.posAbsoluteTop);

  const scrollY = useScroll();

  useEffect(() => {
    if (summary.current && fixedElement.current) {
      const headerHeight = 65;
      const fixedHeight = fixedElement.current.offsetHeight;
      const topSummary = summary.current.offsetTop - headerHeight;
      const bottomSummary = summary.current.offsetTop + summary.current.offsetHeight - headerHeight - fixedHeight;

      if ((topSummary < scrollY) && (bottomSummary > scrollY)) {
        setFixed(style.posFixed);
      } else {
        if (bottomSummary < scrollY) {
          setFixed(style.posAbsoluteBottom);
        } else {
          setFixed(style.posAbsoluteTop);
        }
      }
    }
  }, [scrollY, windowWidth]);
  
  return (
    <div className={style.summary} ref={summary}>
      <div className={windowWidth >= 1023 ? fixed : style.mobile} ref={windowWidth >= 1023 ? fixedElement : null}>
        <div className={style.content}>
          <div className={style.products}>
            <div className={style.inner}>
              {summaryInfo && summaryInfo.products.map((product, index) => (
                <div key={`${product.id}-${index}`} className={style.product}>
                  <div className={style.productImage}>
                    <img src={product.image} alt='' />
                    <span className={style.counter}>
                      {product.count}
                    </span>
                  </div>
                  <div>
                    <div className={style.productName}>{product.name}</div>
                    <div className={style.productPrice}>${formateCartPrice(product.price)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={style.description}>
            <div>
              <div>Subtotal</div>
              <div>${formateCartPrice(subtotal)}</div>
            </div>
            {consultationFee ? <div>
              <div className={style.consultationFee}>
                <div>Consultation Fee</div>
                <CustomTooltip 
                  title="Item(s) in your cart includes a compulsory doctor consultation fee. You will receive a refund for your purchase if the doctor has determined that the service is not suitable for you." 
                  setOpenTooltip={setConsultationFeeTooltip}
                  openTooltip={consultationFeeTooltip}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableTouchListener
                  disableFocusListener
                  disableHoverListener
                  placement="right"
                  arrow
                >
                  <button onClick={() => setConsultationFeeTooltip(true)}><IconInfo /></button>
                </CustomTooltip>
              </div>
              <div>${formateCartPrice(consultationFee.price)}</div>
            </div> : null}
            {homeCollectionFee ? <div>
              <div className={style.phlebotomistFee}>
                <div>Phlebotomist Fee</div>
                <CustomTooltip 
                  title="Blood collection service is required for your order. There is an additional fee of $30 for at-home collection." 
                  setOpenTooltip={setPhlebotomistFeeTooltip}
                  openTooltip={phlebotomistFeeTooltip}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableTouchListener
                  disableFocusListener
                  disableHoverListener
                  placement="right"
                  arrow
                >
                  <button onClick={() => setPhlebotomistFeeTooltip(true)}><IconInfo /></button>
                </CustomTooltip>
              </div>
              <div>${formateCartPrice(homeCollectionFee.price)}</div>
            </div> : null}
            {activeDeliveryOption && activeDeliveryOption.price ? <div>
              <div className={style.phlebotomistFee}>
                <div>Delivery Fee</div>
              </div>
              <div>{`$${formateCartPrice(activeDeliveryOption.price)}`}</div>
            </div> : null}
          </div>
          <div className={style.total}>
            <div>
              <div>Total</div>
              <div>${formateCartPrice(total)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;