import React, { useEffect } from 'react';
import ListCategory from '../../components/ListCategory';
import ProductsList from '../../components/ProductsList';
import { useRouteMatch } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import CategoryDescription from '../../components/CategoryDiscription';
import style from './style.module.scss';
import Faq from '../../components/Faq';
import BannerText from '../../components/BannerText';
import bannerImg1 from '../../assets/images/bannerText1.webp';
import bannerImg2 from '../../assets/images/bannerText2.webp';
import bannerImg3 from '../../assets/images/bannerText3.webp';
import bannerImg4 from '../../assets/images/bannerText4.webp';
import { ProductsCategoryState } from '../../types/productsСategory';
import { fetchProductsCategory } from '../../store/actions/productsCategory';
import { useDispatch } from 'react-redux';
import { fetchCategories } from '../../store/actions/categories';
import { CategoriesState } from '../../types/categories';
import { fetchCategory } from '../../store/actions/category';

export interface MatchParams {
  name: string;
}

export const Category: React.FC = (): JSX.Element => {

  const banner1TextData = [
    {
      id: 1,
      image: bannerImg1,
      title: 'Reviewed by Doctors',
      info: <div>Our partner clinic will be reviewing all samples and screening results.</div>,
    },
    {
      id: 2,
      image: bannerImg2,
      title: 'Full Privacy',
      info: (
        <div>
          <div>Results will be delivered to you with full privacy. Your results will be between you and the doctors.</div>
          <a href="/">Learn more</a>
        </div>
      ),
    },
    {
      id: 3,
      image: bannerImg3,
      title: 'Results Consultation',
      info: <div>A doctor consultation is included with every kit purchase.</div>,
    },
    {
      id: 4,
      image: bannerImg4,
      title: 'Tell Your Partner Anonymously',
      info: <div>If you tested positive, we could help you anonymously message your sexual partners in the past few months to notify them that they may have been exposed to an STD. This is at your request, we will not send anything on your behalf unless you give consent.</div>,
    },
  ];

  const { params } = useRouteMatch<MatchParams>();
  const slug: string = params.name;
  const dispatch = useDispatch();
  const { list }: ProductsCategoryState = useTypedSelector(state => state.productsCategory);
  const { categories, loading }: CategoriesState = useTypedSelector(state => state.categories);
  const { data }: any = useTypedSelector(state => state.category);
  const currentCategory = categories.find(el => el.slug === slug);
  const categoryID = currentCategory?.id;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (categories.length === 0) {
      dispatch(fetchCategories());
    }

    if (!loading) {
      dispatch(fetchProductsCategory(Number(categoryID)));
      dispatch(fetchCategory(Number(categoryID)));
    }
   
  }, [slug, categoryID]);

  return (
    <div>
      <div className="container">
        <ListCategory params={params} list={categories} />
        <div className={style.section}>
          <CategoryDescription slug={slug} />
          <ProductsList products={list} />
        </div>
      </div>
      <div className={style.section}>
        <BannerText type="type1" blocks={banner1TextData} globalTitle="Your Results" />
      </div>
      {
        data.faqs !== undefined && data.faqs.length !== 0 && data.faqs[0].questions.length !== 0 ? (
          <div className="container">
            <div className={style.section}>
              <Faq faq={data.faqs[0].questions} multiply />
            </div>
          </div>
        ) : null
      }
    </div>
  );
};