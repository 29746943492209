import React from 'react';
import IconHelp from '../../assets/images/icons/help';
import style from './style.module.scss';

const Help: React.FC = () => {

  return (
    <a href="https://www.fernehealth.com/faq" className={style.help}>
      <IconHelp />
    </a>
  );
};

export default Help;