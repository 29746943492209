import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import style from './style.module.scss';
import classNames from 'classnames';
import { StylesProvider } from '@material-ui/styles';
import './overrides.scss';

export interface Option {
  id: number,
  value: string,
  text: string,
  price?: number,
}

interface Props {
  options: Option[],
  activeOption: Option | null,
  onChange: (type: string) => void, 
  optionsWithBorder?: boolean,
}

const RadioButtonsGroup: React.FC<Props> = ({options, activeOption, onChange, optionsWithBorder}) => {
  return (
    <StylesProvider injectFirst>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label=""
          name="radio-buttons-group"
          onChange={(e) => onChange(e.target.value)}
          defaultValue=""
        >
          {options.map(option => (
            <div key={option.id} className={classNames(style.radioItem, `${optionsWithBorder && style.radioWrapper} ${activeOption && activeOption.id === option.id ? style.active : null}`)}>
              <FormControlLabel
                value={option.value}
                control={<Radio checked={activeOption && activeOption.id === option.id ? true : false} />}
                label={option.text}
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </StylesProvider>
  );
};

export default RadioButtonsGroup;