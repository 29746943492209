export interface Address {
  [key: string]: string,
}

export interface DisabledDates {
  [key: string]: {
    [key: string]: string[],
  },
}

export interface BloodCollectionState {
  address: Address | null,
  activeDate: string,
  activeTime: string | null,
  disableDates: DisabledDates,
  loading: boolean;
  error: null | string;
}

export enum BloodCollectionActionTypes {
  FETCH_BLOOD_COLLECTION = 'FETCH_BLOOD_COLLECTION',
  FETCH_BLOOD_COLLECTION_SUCCESS = 'FETCH_BLOOD_COLLECTION_SUCCESS',
  FETCH_BLOOD_COLLECTION_ERROR = 'FETCH_BLOOD_COLLECTION_CLINICS_ERROR',
  CHANGE_ACTIVE_DATE_BLOOD_COLLECTION = 'CHANGE_ACTIVE_DATE_BLOOD_COLLECTION',
  CHANGE_ADDRESS_BLOOD_COLLECTION = 'CHANGE_ADDRESS_BLOOD_COLLECTION',
  CHANGE_ACTIVE_TIME_BLOOD_COLLECTION = 'CHANGE_ACTIVE_TIME_BLOOD_COLLECTION',
  CLEAR_BLOOD_COLLECTION_DATA = 'CLEAR_BLOOD_COLLECTION_DATA',
}

export interface FetchBloodCollectionAction {
  type: BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION;
}

export interface FetchBloodCollectionSuccessAction {
  type: BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION_SUCCESS;
  payload: DisabledDates;
}

export interface FetchBloodCollectionErrorAction {
  type: BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION_ERROR;
  payload: string;
}

export interface ChangeActiveDate {
  type: BloodCollectionActionTypes.CHANGE_ACTIVE_DATE_BLOOD_COLLECTION;
  payload: string,
}

export interface ChangeActiveTime {
  type: BloodCollectionActionTypes.CHANGE_ACTIVE_TIME_BLOOD_COLLECTION;
  payload: string | null,
}

export interface ClearBloodCollectionData {
  type: BloodCollectionActionTypes.CLEAR_BLOOD_COLLECTION_DATA;
}

export interface ChangeAddressBloodCollection {
  type: BloodCollectionActionTypes.CHANGE_ADDRESS_BLOOD_COLLECTION;
  payload: Address,
}

export type BloodCollectionAction = FetchBloodCollectionAction | FetchBloodCollectionSuccessAction | FetchBloodCollectionErrorAction | ChangeActiveDate | ChangeActiveTime | ClearBloodCollectionData | ChangeAddressBloodCollection;