import React, { useEffect } from 'react';
import BookTitle from '../../BookTitle';
import style from './style.module.scss';
import PersonalInfoForm from '../../PersonalInfoForm';
import { useTypedSelector } from '../../../../hooks/productsTypedSelector';

const PersonalInfo: React.FC = () => {

  const { genderOptions, nationalityOptions, personalInfo } = useTypedSelector(state => state.personalInfo);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[]);
  
  return (
    <div className={style.wrapper}>
      <BookTitle
        title="Fill out your info"
      />
      <PersonalInfoForm genderOptions={genderOptions} nationalityOptions={nationalityOptions} personalInfo={personalInfo} />
    </div>
  );
};

export default PersonalInfo;