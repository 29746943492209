import { ServiceLocationType, StepTypes } from './../../types/order';
import { OrderAction, OrderActionTypes, OrderID } from '../../types/order';

export const changeStepAction = (payload: StepTypes): OrderAction => ({
  type: OrderActionTypes.CHANGE_STEP,
  payload
});

export const setServiceLocationAction = (payload: ServiceLocationType): OrderAction => ({
  type: OrderActionTypes.SET_SERVICE_LOCATION,
  payload,
});

export const setChangeOrderIDAction = (payload: OrderID): OrderAction => ({
  type: OrderActionTypes.CHANGE_ORDER_ID,
  payload,
});

export const clearStepAction = (): OrderAction => ({
  type: OrderActionTypes.CLEAR_STEP
});

export const clearServiceLocationAction = (): OrderAction => ({
  type: OrderActionTypes.CLEAR_SERVICE_LOCATION,
});