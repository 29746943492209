import React from 'react';
import style from './style.module.scss';
import IconArrowLeft from '../../../assets/images/icons/arrowLeft';
import CustomButton from '../../CustomButton';
import { useTypedSelector } from '../../../hooks/productsTypedSelector';
import PaymentPayPalButton from '../../PaymentPayPalButton';
import PaymentPayPal from '../../PaymentPayPal';
import PaymentEmpty from '../../PaymentEmpty';
import { isEmpty } from 'lodash';
import { getCartTotal, getConsultationAndHomeCollectionFee } from '../../../store/selectors/cartProducts';
import { getDeliveryActiveOption } from '../../../store/selectors/deliveryDetails';
import PaymentAtome from '../../PaymentAtome';
import { useToasts, ToastProvider } from 'react-toast-notifications';
import PaymentStripe from '../../PaymentStripe';

interface Props {
  activeContinueButton: boolean,
  buttonText: string,
  onClickBack: () => void,
  onClickContinue: () => void,
  payment?: boolean,
}

const BookButtons: React.FC<Props> = ({ activeContinueButton, buttonText, onClickBack, onClickContinue, payment}) => {

  // create order object
  const state = useTypedSelector(state => state);
  const { personalInfo } = useTypedSelector(state => state.personalInfo);
  const { activePaymentOption } = useTypedSelector(state => state.paymentDetails);
  const { products } = useTypedSelector(state => state.cartProducts);
  const cartTotal = useTypedSelector(getCartTotal);
  const { consultationFee, homeCollectionFee } = useTypedSelector(getConsultationAndHomeCollectionFee);
  const deliveryActiveOption = useTypedSelector(getDeliveryActiveOption);

  const listProductsCart = products.map(product => {
    return {
      'product_id': product.id,
      'quantity': 1,
      'price': product.price === null ? product.product_variation[0].price : product.price,
      'variation_id': product.product_variation?.length ? product.product_variation[0].id : null,
    };
  });

  let deliveryID;
  let homeCollectionID;
  
  deliveryActiveOption !== null ? (
    deliveryID = deliveryActiveOption.id
  ) : deliveryID  = null;

  homeCollectionFee !== null ? (
    homeCollectionID = homeCollectionFee.id
  ) : homeCollectionID = null;

  // add consultation product
  (consultationFee !== null) && (consultationFee !== undefined) ? (
    listProductsCart.push({
      'product_id': consultationFee.id,
      'quantity': 1,
      'price': consultationFee.price,
      'variation_id': null
    })
  ) : null;

  const formatDate = personalInfo.dateOfBirth.split('/');
  const validFormatDate = formatDate[2] + '-' + formatDate[1] + '-' + formatDate[0];

  const bookConsultationProduct = products.find(({ consultation }) => consultation.doctor);
  const bookConsultationOrderData = {
    date_time: `${bookConsultationProduct?.consultation.date} ${bookConsultationProduct?.consultation.time}`,
    employee_id: bookConsultationProduct?.clinic_employees.find(emplyee => emplyee.name === bookConsultationProduct.consultation.doctor)?.id
  };

  const feesList = [];

  deliveryID !== null ? feesList.push({'fee_id': deliveryID }) : null;
  homeCollectionID != null ? feesList.push({'fee_id': homeCollectionID }) : null;

  const orderTotal = {
    'price': cartTotal.toFixed(2),
    'products': listProductsCart,
    'full_name': personalInfo.firstName + ' ' + personalInfo.lastName,
    'phone': personalInfo.contact,
    'email': personalInfo.email,
    'payment_service': activePaymentOption.toLowerCase() === 'credit_card' ? 'stripe' : activePaymentOption.toLowerCase(),
    'addresses': [],
  };
 
  !isEmpty(personalInfo.dateOfBirth) ? Object.assign(orderTotal, {'date_of_birth': validFormatDate}) : null;
  !isEmpty(personalInfo.gender) ? Object.assign(orderTotal, {'gender': personalInfo.gender}) : null;
  !isEmpty(personalInfo.allergies) ? Object.assign(orderTotal, {'allergies': personalInfo.allergies}) : null;
  !isEmpty(personalInfo.passport) ? Object.assign(orderTotal, {'nric': personalInfo.passport}) : null;
  !isEmpty(personalInfo.nationality) ? Object.assign(orderTotal, {'nationality': personalInfo.nationality}) : null;
  Object.assign(orderTotal, {'fees': feesList});

  // add consultation
  !isEmpty(state.bookConsultation.activeTime) ? Object.assign(orderTotal, {
    'consultation': {
      'employee_id': state.bookConsultation.activeDoctor?.id,
      'clinic_id': null,
      'date_time': state.bookConsultation.activeDate + ' ' + state.bookConsultation.activeTime,
    }
  }) : Object.assign(orderTotal, {
    'consultation': {
      'employee_id': bookConsultationOrderData.employee_id,
      'clinic_id': null,
      'date_time': bookConsultationOrderData.date_time,
    }
  });

  !isEmpty(state.bookClinicVisit.activeTime) ? Object.assign(orderTotal, {
    'consultation': {
      'employee_id': null,
      'clinic_id': state.bookClinicVisit.activeClinic?.id,
      'date_time': state.bookClinicVisit.activeDate + ' ' + state.bookClinicVisit.activeTime,
    }
  }) : null;

  // add book blood time
  !isEmpty(state.bookBloodCollection.activeTime) ? Object.assign(orderTotal, {
    'blood_collection_time': {
      'from': state.bookBloodCollection.activeTime,
      'to': `${Number(state.bookBloodCollection.activeTime?.split(':')[1]) === 45 ? Number(state.bookBloodCollection.activeTime?.split(':')[0]) >= 9  ?  Number(state.bookBloodCollection.activeTime?.split(':')[0]) + 1 : '0' + (Number(state.bookBloodCollection.activeTime?.split(':')[0]) + Number(1)) : state.bookBloodCollection.activeTime?.split(':')[0]}:${Number(state.bookBloodCollection.activeTime?.split(':')[1]) === 45 ? '00' : Number(state.bookBloodCollection.activeTime?.split(':')[1]) + 15}`,
      'date': state.bookBloodCollection.activeDate,
    },
  }) : null;

  // add delivery time
  !isEmpty(state.deliveryDetails.activeTime) ? Object.assign(orderTotal, {
    'delivery_time': {
      'from': state.deliveryDetails.activeTime?.split('-')[0],
      'to': state.deliveryDetails.activeTime?.split('-')[1],
      'date': state.deliveryDetails.activeDate,
    },
  }) : null;

  // add address - SHIPPING
  !isEmpty(state.deliveryDetails.address.streetAddress) ? Object.assign(orderTotal, {
    'addresses': [...orderTotal.addresses, {
      'address_name': 'shipping',
      'first_name': personalInfo.firstName,
      'last_name': personalInfo.lastName,
      'street': state.deliveryDetails.address.streetAddress,
      'optional': state.deliveryDetails.address.additionalInfo,
      'postal_code': state.deliveryDetails.address.postalCode,
      'country': 'Singapore'
    }],
  }) : null;

  // add address - BLOOD COLLECTION
  !isEmpty(state.bookBloodCollection.address) ? Object.assign(orderTotal, {
    'addresses': [...orderTotal.addresses, {
      'address_name': 'blood collection',
      'first_name': personalInfo.firstName,
      'last_name': personalInfo.lastName,
      'street': state.bookBloodCollection.address?.streetAddress,
      'postal_code': state.bookBloodCollection.address?.postalCode,
      'optional': state.bookBloodCollection.address?.additionalInfo,
      'country': 'Singapore'
    }],
  }) : null;

  // add address - BILLING
  !isEmpty(state.paymentDetails.billingAddress.streetAddress) ? Object.assign(orderTotal, {
    'addresses': [...orderTotal.addresses, {
      'address_name': 'billing',
      'first_name': state.paymentDetails.billingAddress.firstName,
      'last_name': state.paymentDetails.billingAddress.lastName,
      'street': state.paymentDetails.billingAddress.streetAddress,
      'optional': state.paymentDetails.billingAddress.additionalInfo,
      'postal_code': state.paymentDetails.billingAddress.postalCode,
      'country': 'Singapore'
    }],
  }) : null;
 
  // end create order object

  return (
    <div className={style.buttonsContainer}>
      <ToastProvider>
        <button onClick={onClickBack}><IconArrowLeft /><span>Back</span></button>
        <div>

          {
            activePaymentOption === 'ATOME' && payment ? (
              <PaymentAtome order={orderTotal} text={buttonText} />
            ) : null
          }
          {
            activePaymentOption === 'CREDIT_CARD' && payment ? (
              <PaymentStripe order={orderTotal} />
            ) : null
          }

          {
            activePaymentOption === 'PAYPAL' && payment ? (
              <PaymentPayPalButton order={orderTotal} />
            ) : null
          }

          {
            activePaymentOption === 'NONE' && payment ? (
              <PaymentEmpty text={buttonText} />
            ) : null
          }
            
          {
            !payment ? (
              <CustomButton 
                type="button" 
                onClick={onClickContinue} 
                disabled={!activeContinueButton} 
                text={buttonText} 
              />
            ) : null }
        </div>
      </ToastProvider>
    </div>
  );
};

export default BookButtons;