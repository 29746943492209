import React from 'react';

const IconClinic: React.FC = () => {

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="23" 
      height="23" 
      viewBox="0 0 23 23" 
      fill="none"
    >
      <path 
        d="M11.4999 20.6988C16.5445 20.6988 20.6999 16.5366 20.6999 11.5032C20.6999 6.46105 16.5357 2.29883 11.4999 2.29883C6.45533 2.29883 2.29993 6.46105 2.29993 11.5032C2.29993 16.5366 6.45533 20.6988 11.4999 20.6988ZM6.47294 12.2952V10.6761C6.47294 10.0249 6.92194 9.57611 7.57342 9.57611H9.58069V7.5874C9.58069 6.91863 10.0209 6.47865 10.6812 6.47865H12.2923C12.9614 6.47865 13.4016 6.91863 13.4016 7.5874V9.57611H15.4C16.0603 9.57611 16.5093 10.0249 16.5093 10.6761V12.2952C16.5093 12.9552 16.0603 13.4039 15.4 13.4039H13.4016V15.3927C13.4016 16.0614 12.9614 16.5014 12.2923 16.5014H10.6812C10.0209 16.5014 9.58069 16.0614 9.58069 15.3927V13.4039H7.58222C6.92194 13.4039 6.47294 12.9552 6.47294 12.2952Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconClinic;