import React from 'react';
import style from './style.module.scss';

const CheckoutLayoutFooter: React.FC = () => {
  return (
    <footer className={style.footer}>
      <div className="container">
        <div className={style.footerText}>
          <span>Need help? </span><a href="mailto:contact@fernehealth.com">Email us</a> or WhatsApp us at <span className={style.phone}><a href="https://api.whatsapp.com/send/?phone=+6584971626">+65 8497 1626</a></span>
        </div>
        <div className={style.footerLinks}>
          <a href="https://fernehealth.com/returnpolicy" target="_blank" rel="noreferrer">Refund Policy</a>
          <a href="https://www.fernehealth.com/term-of-use" target="_blank" rel="noreferrer">Term of Use</a>
          <a href="https://www.fernehealth.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default CheckoutLayoutFooter;