import React from 'react';

const IconCreditCard: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="20" 
      height="16" 
      viewBox="0 0 20 16" 
      fill="none"
    >
      <path 
        d="M17.132 0H2.86796C1.00084 0 0 0.992431 0 2.84273V4.17157H20V2.84273C20 0.992431 19.0076 0 17.132 0ZM3.86039 12.8932C3.36417 12.8932 3.03616 12.5568 3.03616 12.0858V10.5299C3.03616 10.0505 3.36417 9.72246 3.86039 9.72246H5.92094C6.41716 9.72246 6.74516 10.0505 6.74516 10.5299V12.0858C6.74516 12.5568 6.41716 12.8932 5.92094 12.8932H3.86039ZM2.86796 15.9041H17.132C19.0076 15.9041 20 14.9033 20 13.053V6.19008H0V13.053C0 14.9117 1.00084 15.9041 2.86796 15.9041Z" 
        fill="black"
      />
    </svg>
  );
};

export default IconCreditCard;