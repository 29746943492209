import React from 'react';

const IconBriefcase: React.FC = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        d="M19.2349 7.00471C19.8849 7.00471 20.5083 7.26058 20.9679 7.71602C21.4275 8.17146 21.6857 8.78917 21.6857 9.43327V20.6143C21.6832 21.2576 21.4241 21.8739 20.9651 22.3288C20.506 22.7837 19.8841 23.0403 19.2349 23.0429H4.7651C4.11589 23.0403 3.49401 22.7837 3.03495 22.3288C2.57588 21.8739 2.31684 21.2576 2.31426 20.6143V9.43327C2.31426 8.78917 2.57247 8.17146 3.03209 7.71602C3.49172 7.26058 4.1151 7.00471 4.7651 7.00471H19.2349ZM19.2349 5.54758H4.7651C3.7251 5.54758 2.72768 5.95696 1.99229 6.68567C1.25689 7.41438 0.84375 8.40272 0.84375 9.43327V20.6143C0.84375 21.6449 1.25689 22.6332 1.99229 23.3619C2.72768 24.0906 3.7251 24.5 4.7651 24.5H19.2349C20.2749 24.5 21.2723 24.0906 22.0077 23.3619C22.7431 22.6332 23.1563 21.6449 23.1563 20.6143V9.43327C23.1563 8.40272 22.7431 7.41438 22.0077 6.68567C21.2723 5.95696 20.2749 5.54758 19.2349 5.54758Z"
        stroke="none"
      />
      <path
        d="M8.01953 9.10297V5.54757C8.01953 4.50156 8.43887 3.49839 9.1853 2.75876C9.93172 2.01912 10.9441 1.60359 11.9997 1.60359C13.0553 1.60359 14.0677 2.01912 14.8141 2.75876C15.5605 3.49839 15.9799 4.50156 15.9799 5.54757V9.10297"
        fill="none"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round" 
      />
    </svg>
  );
};

export default IconBriefcase;