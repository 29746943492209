import React, {useEffect} from 'react';
import BannerDefault from '../../components/BannerDefault';
import ListCategory from '../../components/ListCategory';
import ProductsList from '../../components/ProductsList';
import { useRouteMatch } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import CategoryDescription from '../../components/CategoryDiscription';
import style from './style.module.scss';
import Quiz from '../../components/Quiz';
import { MatchParams } from '..';
import { CategoriesState } from '../../types/categories';
import { fetchCategories } from '../../store/actions/categories';
import { useDispatch } from 'react-redux';
import { fetchProducts } from '../../store/actions/products';

export const Shop: React.FC = (): JSX.Element => {
  
  const { params, url } = useRouteMatch<MatchParams>();
  const dispatch = useDispatch();
  const { products } = useTypedSelector(state => state.products);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
  }, [dispatch]);
  
  const { categories }: CategoriesState = useTypedSelector(state => state.categories);
  
  return (
    <div>
      <BannerDefault title={'Your one-stop sexual health & wellness solution'} image={'/assets/images/ferne-hero-shop.jpg'} />
      <div className="container">
        <ListCategory params={params} url={url} list={categories} />
      </div> 
      <div className="container">
        {categories.map((category, index: number) =>
          <div className={style.section} key={index}>
            <CategoryDescription slug={category.slug} />
            <ProductsList categoryId={category.id} products={products}  />
          </div>
        )}
      </div>
      <Quiz />
    </div>
  );
};