import isEmpty from 'lodash/isEmpty';
import { Product } from '../types/product';

export const calculateCartSubTotal = (products: Product[]): number => {
  return !isEmpty(products) ? products.reduce((acc, item) => {
    if (item.price === null) {
      return acc + item.product_variation[0].price;
    }
    return acc + item.price;
  }, 0) : 0; 
};

export const calculateCartTotal = (...prices: number[]): number => {
  return prices.reduce((acc, current) => {
    acc += current * 100;
    
    return acc;
  }, 0) / 100;
};