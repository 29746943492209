import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import { changeActiveDateAction, changeActiveTimeAction, changeAddressBloodCollectionAction, clearBloodCollectionDataAction } from '../../../../store/actions/bookBloodCollection';
import DateFnsUtils from '@date-io/date-fns';
import style from './style.module.scss';
import { initialBookingTime } from './consts';
import withDisableTime from '../../withDisableTime';
import DateAndTimePicker from '../../DateAndTimePicker';
import BookTitle from '../../BookTitle';
import BookNotice from '../../BookNotice';
import BookButtons from '../../BookButtons';
import { StepTypes } from '../../../../types/order';
import { changeStepAction } from '../../../../store/actions/order';
import { getCartProductsContainTypes } from '../../../../store/selectors/cartProducts';
import AddressForm from '../../AddressForm';
import { ProductTypes } from '../../../../types/product';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { BloodCollectionActionTypes } from '../../../../types/bookBloodCollection';

export interface Address {
  [key: string]: string,
}

const BookBloodCollection: React.FC = () => {

  const [bookingDates, setBookingDates] = useState<any>({});

  const { activeDate, activeTime, disableDates } = useTypedSelector(state => state.bookBloodCollection);
  const isOrderContainClinicVisit = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.CLINIC_VISIT]));
  const isOrderContainOtherProducts = useTypedSelector(state => getCartProductsContainTypes(state, [
    ProductTypes.TESTING_KIT,
    ProductTypes.PRESCRIPTION,
    ProductTypes.SUPPLEMENTS,
  ]));
  const isOrderContainOnlineConsultation = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.ONLINE_CONSULTATION]));
  const [bookingTime, setBookingTime] = useState(initialBookingTime);
  const [address, setAddress] = useState<Address>({
    streetAddress: '',
    additionalInfo: '',
    postalCode: '',
  });

  const dispatch = useTypedDispatch();

  const changeDate = (date: string) => {
    dispatch(changeActiveDateAction(date));
    dispatch(changeActiveTimeAction(null));
  };

  const changeTime = (time: string) => {
    dispatch(changeActiveTimeAction(time)); 
  };

  const onClickBack = useCallback(() => { 
    if (isOrderContainClinicVisit) {
      dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT));
    } else if (isOrderContainOnlineConsultation) {
      dispatch(changeStepAction(StepTypes.SERVICE_LOCATION));
    } else {
      dispatch(changeStepAction(StepTypes.BOOK_CONSULTATION));
    }
 
    dispatch(clearBloodCollectionDataAction());
  }, [dispatch, isOrderContainOnlineConsultation, isOrderContainClinicVisit]);

  const onClickContinue = useCallback(() => {
    if (isOrderContainOtherProducts) {
      dispatch(changeStepAction(StepTypes.SHIPPING));
    } else {
      dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    }

    dispatch(changeAddressBloodCollectionAction(address));
  }, [dispatch, address]);

  const isValidAddressForm = useMemo(() => {
    for (const key in address) {
      if (!address[key].length) {
        return false;
      }
    }
    
    return true;
  }, [address]);

  useEffect(() => {
    setBookingTime(withDisableTime({allTime: initialBookingTime, disableTime: disableDates[activeDate]}));
  }, [activeDate, disableDates]);

  useEffect(() => {
    const dateNow = new Date();
    const startDate = format(parseISO(dateNow.toISOString()), 'dd-MM-yyyy');
    const endDate = format(parseISO(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy');
    axios.get(`${process.env.REACT_APP_API}/availability/blood-collection?start_date=${startDate}&end_date=${endDate}`)
      .then(res => {
        setBookingDates(res.data.data);
        dispatch({
          type: BloodCollectionActionTypes.CHANGE_ACTIVE_DATE_BLOOD_COLLECTION,
          payload: Object.entries(res.data.data).map((el: any, index) => {
            return [el[0], Object.values(el[1]).map((elem: any) => Object.values(elem)).flat().some(el => el)];
          }).filter(el => el[1])[0][0]
        });

      });

  }, []);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[]);

  const onChangeMonth = useCallback((date) => {
    const dates = [format(parseISO(date.toISOString()), 'dd-MM-yyyy').toString(), format(parseISO(new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()), 'dd-MM-yyyy').toString()];
    axios.get(`${process.env.REACT_APP_API}/availability/blood-collection?start_date=${dates[0]}&end_date=${dates[1]}`)
      .then(res => {
        setBookingDates((prev: any) => ({...prev, ...res.data.data}));
      });
  }, []);

  const withoutPastTimeDates = useMemo(() => {
    if(Object.keys(bookingDates)[0] === activeDate) {
      const dateNow = new Date().getTime();
      const newDisableDates = {...bookingDates};
      const keys = Object.keys(bookingDates[activeDate]);
      
      for(let i = 0; i <= keys.length; i++) {
        for(const key in bookingDates[activeDate][keys[i]]) {
          if(new Date(`${activeDate} ${key}`).getTime() < dateNow) {
            newDisableDates[activeDate][keys[i]][key] = false;
          }
        }
      }
      return newDisableDates;
    } else if(bookingDates) {
      return bookingDates;
    }
  }, [bookingDates, activeDate]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={style.wrapper}>
        <BookTitle 
          title="Book at-home blood collection"
          description="Blood collection will be performed by our phlebotomist at your home or any preferred location. This would take 5 - 10 minutes. Our phlebotomists have been fully vaccinated against COVID-19."  
        />
        <DateAndTimePicker 
          activeDate={activeDate}
          activeTime={activeTime}
          changeDate={changeDate}
          changeTime={changeTime}
          bookingDates={withoutPastTimeDates}
          bookingTime={Object.keys(bookingDates).length ? bookingDates[activeDate] : bookingTime}
          onChangeMonth={onChangeMonth}
          title={'Select appointment time:'}
          withShortView
        />
        <BookNotice
          title="Prepare the following for your appointment:"
          component={(
            <ul>
              <li>Valid government-issued photo ID, such as your NRIC, employment pass, or passport.</li>
              <li>Wear comfortable outfit that is easy to roll your sleeves up for blood collection.</li>
            </ul>
          )}
        />
        <AddressForm
          border
          title="Preferred address for the appointment:"
          address={address}
          setAddress={setAddress}
        />
        <BookButtons 
          onClickBack={onClickBack} 
          onClickContinue={onClickContinue} 
          activeContinueButton={Boolean(activeTime && isValidAddressForm)} 
          buttonText="Continue" 
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default BookBloodCollection;