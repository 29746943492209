export interface Clinic {
  type: 'clinic',
  id: number,
  name: string,
  address: string,
  disabledDates: {
    [key: string | number | symbol]: {
      [key: string | number | symbol]: {
        [key: string | number | symbol]: boolean;
      },
    },
  },
}

export interface ClinicsState {
  clinics: Clinic[];
  activeClinic: Clinic | undefined;
  activeDate: string,
  activeTime: string | null,
  loading: boolean;
  error: null | string;
}

export enum ClinicsActionTypes {
  FETCH_CLINICS = 'FETCH_CLINICS',
  FETCH_CLINICS_SUCCESS = 'FETCH_CLINICS_SUCCESS',
  FETCH_CLINICS_ERROR = 'FETCH_CLINICS_ERROR',
  CHANGE_ACTIVE_CLINIC = 'CHANGE_ACTIVE_CLINIC',
  CHANGE_ACTIVE_DATE_CLINIC = 'CHANGE_ACTIVE_DATE_CLINIC',
  CHANGE_ACTIVE_TIME_CLINIC = 'CHANGE_ACTIVE_TIME_CLINIC',
  CLEAR_CLINIC_DATA = 'CLEAR_CLINIC_DATA',
  CHANGE_MONTH = 'CHANGE_MONTH'
}

export interface FetchClinicsAction {
  type: ClinicsActionTypes.FETCH_CLINICS;
}

export interface FetchClinicsSuccessAction {
  type: ClinicsActionTypes.FETCH_CLINICS_SUCCESS;
  payload: Clinic[];
}

export interface ChangeMonthAction {
  type: ClinicsActionTypes.CHANGE_MONTH;
  payload: Clinic[];
}

export interface FetchClinicsErrorAction {
  type: ClinicsActionTypes.FETCH_CLINICS_ERROR;
  payload: string;
}

export interface ChangeActiveClinic {
  type: ClinicsActionTypes.CHANGE_ACTIVE_CLINIC;
  payload: number,
}

export interface ChangeActiveDate {
  type: ClinicsActionTypes.CHANGE_ACTIVE_DATE_CLINIC;
  payload: string,
}

export interface ChangeActiveTime {
  type: ClinicsActionTypes.CHANGE_ACTIVE_TIME_CLINIC;
  payload: string | null,
}

export interface ClearClinicData {
  type: ClinicsActionTypes.CLEAR_CLINIC_DATA;
}

export type ClinicsAction = FetchClinicsAction | FetchClinicsSuccessAction | FetchClinicsErrorAction | ChangeActiveClinic | ChangeActiveDate | ChangeActiveTime | ClearClinicData | ChangeMonthAction;