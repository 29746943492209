import { CartProductsAction, CartProductsActionTypes } from '../../types/cartProducts';
import { Product } from '../../types/product';

export const addProductToCartAction = (payload: Product): CartProductsAction => ({
  type: CartProductsActionTypes.ADD_PRODUCT_TO_CART,
  payload,
});

export const removeProductFromCartAction = (payload: {id: number, variationId?: number}): CartProductsAction => ({
  type: CartProductsActionTypes.REMOVE_PRODUCT_FROM_CART,
  payload,
});

export const decrementProductFromCartAction = (payload: Product): CartProductsAction => ({
  type: CartProductsActionTypes.DECREMENT_PRODUCT_FROM_CART,
  payload,
});

export const clearCartProductsAction = (): CartProductsAction => ({
  type: CartProductsActionTypes.CLEAR_CART,
});