import {
  DeliveryDetailsState,
  DeliveryDetailsAction,
  DeliveryDetailsActionTypes,
} from '../../types/deliveryDetails';

import { format } from 'date-fns';

const initialState: DeliveryDetailsState = {
  details: [],
  activeDate: format(new Date(), 'yyyy-MM-dd'),
  activeTime: '',
  address: {
    streetAddress: '',
    additionalInfo: '',
    postalCode: '',
  },
  loading: false,
  error: null,
  activeDeliveryOption: null,
};

export const deliveryDetailsReducer = (state = initialState, action: DeliveryDetailsAction): DeliveryDetailsState => {
  switch (action.type) {
  case DeliveryDetailsActionTypes.FETCH_DELIVERY_DETAILS:
    return {...state, loading: true, error: null, details: []};
  case DeliveryDetailsActionTypes.FETCH_DELIVERY_DETAILS_SUCCESS:
    return {...state, loading: false, error: null, details: action.payload};
  case DeliveryDetailsActionTypes.FETCH_DELIVERY_DETAILS_ERROR:
    return {...state, loading: false, error: action.payload, details: []};
  case DeliveryDetailsActionTypes.CHANGE_ACTIVE_DELIVERY_DETAILS_OPTION:
    return {...state, activeDeliveryOption: action.payload || null};
  case DeliveryDetailsActionTypes.CHNAGE_ACTIVE_DATE:
    return {...state, activeDate: action.payload};
  case DeliveryDetailsActionTypes.CHNAGE_ACTIVE_TIME:
    return {...state, activeTime: action.payload};
  case DeliveryDetailsActionTypes.CHANGE_SHIPPING_ADDRESS:
    return {...state, address: action.payload};
  case DeliveryDetailsActionTypes.CLEAR_DELIVERY_DETAILS:
    return {...state, activeDeliveryOption: null};
  case DeliveryDetailsActionTypes.CLEAR_DELIVERY_DETAILS_ACTIVE_TIME:
    return {...state, activeTime: ''};
  case DeliveryDetailsActionTypes.CLEAR_DELIVERY_DETAILS_ADDRESS:
    return {...state, address: {
      streetAddress: '',
      additionalInfo: '',
      postalCode: '',
    }};
  default:
    return state;
  }
};