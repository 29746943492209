import React from 'react';

const IconPlus: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="10" 
      height="10" 
      viewBox="0 0 10 10" 
      fill="none"
    >
      <path 
        d="M5 10C5.29697 10 5.54545 9.76841 5.54545 9.48337V5.53444H9.46061C9.75152 5.53444 10 5.29097 10 5C10 4.70903 9.75152 4.4715 9.46061 4.4715H5.54545V0.516627C5.54545 0.231591 5.29697 0 5 0C4.70303 0 4.46061 0.231591 4.46061 0.516627V4.4715H0.539394C0.248485 4.4715 0 4.70903 0 5C0 5.29097 0.248485 5.53444 0.539394 5.53444H4.46061V9.48337C4.46061 9.76841 4.70303 10 5 10Z" 
        fill="#C7C7C7"
      />
    </svg>
  );
};

export default IconPlus;