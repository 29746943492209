export interface BookingTime {
  [key: string | number | symbol]: {
    [key: string | number | symbol]: boolean,
  },
}

export const initialBookingTime: BookingTime = {
  'Morning': {
    '9:00': true,
    '9:15': true,
    '9:30': true,
    '9:45': true,
    '10:00': true,
    '10:15': true,
    '10:30': true,
    '10:45': true,
    '11:00': true,
    '11:15': true,
    '11:30': true,
    '11:45': true,
  },
  'Afternoon': {
    '12:00': true,
    '12:15': true,
    '12:30': true,
    '12:45': true,
    '13:00': true,
    '13:15': true,
    '13:30': true,
    '13:45': true,
    '14:00': true,
    '14:15': true,
    '14:30': true,
    '14:45': true,
    '15:00': true,
    '15:15': true,
    '15:30': true,
    '15:45': true,
    '16:00': true,
    '16:15': true,
    '16:30': true,
    '16:45': true,
    '17:00': true,
    '17:15': true,
    '17:30': true,
    '17:45': true,
  },
  'Evening': {
    '18:00': true,
    '18:15': true,
    '18:30': true,
    '18:45': true,
    '19:00': true,
    '19:15': true,
    '19:30': true,
    '19:45': true,
    '20:00': true,
    '20:15': true,
    '20:30': true,
    '20:45': true,
  }
};