import React from 'react';
import style from './style.module.scss';
import userPreview from '../../assets/images/icons/user.svg';

interface Props {
  data: Array<{
    id: number,
    name: string,
    image: string,
    excerpt: string,
    description: string,
    clinic_id: number,
  }> | undefined;
}

const ClinicEmployees: React.FC<Props> = ({data}) => {
  return (
    <div className={style.grid}>
      {
        data ? data.map((item, index) => {
          const preview = item.image !== null ? `${process.env.REACT_APP_API_DOMAIN}/employees_images/${item.image}` : userPreview;
          return (
            <div key={index} className={style.cell}>
              <div className={style.card}>
                <div className={style.head}>
                  <div className={style.preview} style={{backgroundImage: `url(${preview})`}}></div>
                  <strong className={style.name}>{item.name}</strong>
                  <div className={style.excerpt}>{item.excerpt}</div>
                </div>
                <div className={style.body}>
                  <div className={style.description} dangerouslySetInnerHTML={{__html: item.description}} />
                </div>
              </div>
            </div>
          );
        }
        ) : null
      }
    </div>
  );
};

export default ClinicEmployees;