import {
  ProductFaqState,
  ProductFaqAction,
  ProductFaqActionTypes
} from '../../types/productFaq';

const initialState: ProductFaqState = {
  faqList: [],
  loading: false,
  error: null
};

export const productFaqReducer = (state = initialState, action: ProductFaqAction): ProductFaqState => {
  switch (action.type) {
  case ProductFaqActionTypes.FETCH_PRODUCT_FAQ:
    return {loading: true, error: null, faqList: []};
  case ProductFaqActionTypes.FETCH_PRODUCT_FAQ_SUCCESS:
    return {loading: false, error: null, faqList: action.payload};
  case ProductFaqActionTypes.FETCH_PRODUCT_FAQ_ERROR:
    return {loading: false, error: action.payload, faqList: []};
  default:
    return state;
  }
};