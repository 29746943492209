import React from 'react';
import style from './style.module.scss';

const Quiz: React.FC = () => {

  return (
    <div className={style.quiz}>
      <div className="container">
        <div className={style.panel}>
          <div className={style.head}>
            <div className={style.lines}>
              <strong className={style.title}>Your Health, <br /> Your Rules.</strong>
            </div>
          </div>
          <div className={style.body}>
            <div className={style.description}>
              <p>Ready to take control of your health, but not sure how to get started? Take a 1 minute quiz to find the right home-based screening kit and telemedicine service for you</p>
            </div>
          </div>
          <div className={style.footer}>
            <a
              href="https://fernehealth.typeform.com/to/FKP2QBCE?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx"
              target="_blank"
              rel="noreferrer"
              className={style.button}
            >TAKE THE QUIZ</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;