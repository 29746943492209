import React from 'react';
import styleType1 from './type1.module.scss';
import styleType2 from './type2.module.scss';

interface Block {
  id: number,
  image: string,
  title: string,
  info: React.ReactNode,
}

interface Props {
  globalTitle: string,
  type: 'type1' | 'type2';
  blocks: Block[];
}

const BannerText: React.FC<Props> = ({type, blocks, globalTitle}) => {

  const style = type === 'type1' ? styleType1 : styleType2;

  return (
    <div className={style.wrapper}>
      <div className="container">
        <div className={style.banner}>
          <h2>{globalTitle}</h2>
          <div className={style.blocks}>
            {blocks.map(block => (
              <div key={block.id}>
                <img src={block.image} alt="block" />
                <div>
                  <div className={style.title}>{block.title}</div>
                  {block.info}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerText;