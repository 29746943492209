import React, { useCallback } from 'react';
import CartProduct from '../CartProduct';
import style from './style.module.scss';
import noPreview from '../../assets/images/no-preview.svg';
import { Product } from '../../types/product';
import { useTypedDispatch } from '../../hooks/productsTypedSelector';
import { removeProductFromCartAction } from '../../store/actions/cartProducts';
import { clearDoctorDataAction } from '../../store/actions/bookConsultation';

export interface ICartProduct {
  id: number,
  slug: string,
  picture: string,
  title: string,
  quantity: number,
  quantityEditable: boolean,
  price: number,
  description?: string | null,
  consultation?: {
    date: string,
    time: string,
    doctor: string,
  },
  variationName?: string,
  variationId?: number,
  initialProduct: Product,
}

interface Props {
  products: Product[]
}

const parseProducts = (products: Product[]) => {

  const parsedProducts = [...products].map(item => {
    switch (item.variations_type) {
    case 'single_product':
      if (item.product_type === 'online_consultation') {
        return {
          id: item.id,
          slug: item.slug,
          picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.main_image}` : noPreview,
          title: item.name,
          quantity: 0,
          quantityEditable: true,
          price: item.price,
          initialProduct: item,
          consultation: {
            date: item.consultation.date,
            time: item.consultation.time,
            doctor: item.consultation.doctor,
          }
        };
      } else {
        return {
          id: item.id,
          slug: item.slug,
          picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.main_image}` : noPreview,
          description: item.description ?? null,
          title: item.name,
          quantity: 0,
          quantityEditable: true,
          price: item.price,
          initialProduct: item,
        };
      }
    case 'multiple_products':
      return {
        id: item.id,
        slug: item.slug,
        picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.main_image}` : noPreview,
        description: item.description ?? null,
        title: item.name,
        quantity: 0,
        quantityEditable: true,
        price: item.product_variation[0].price,
        variationName: item.product_variation[0].name,
        variationId: item.product_variation[0].id,
        initialProduct: item,
      };
    default:
      return {
        id: item.id,
        slug: item.slug,
        picture: item?.main_image ? `${process.env.REACT_APP_API_DOMAIN}/products_images/${item?.main_image}` : noPreview,
        description: item.description ?? null,
        title: item.name,
        quantity: 0,
        quantityEditable: item.variations_type === 'single_product',
        price: item.price,
        initialProduct: item,
      };
    }
  });

  const filteredProducts = [...parsedProducts].filter((v, i, a) => a.findIndex(t => {
    if (t.id === v.id && t.variationId === v.variationId) {
      t.quantity += 1;
      return true;
    }

    return false;
  }) === i);

  return filteredProducts;
};

const CartProductList: React.FC<Props> = ({products}) => {
  const dispatch = useTypedDispatch();
  const removeProductFromCart = useCallback((id: number, variationId?: number) => {
    dispatch(clearDoctorDataAction());
    variationId 
      ? dispatch(removeProductFromCartAction({id, variationId}))
      : dispatch(removeProductFromCartAction({id}));
  }, [dispatch]);

  return (
    <div className={style.productList}>
      <h2>SHOPPING BAG</h2>
      <div className={style.products}>
        {parseProducts(products).map((product, index) => (
          <CartProduct
            key={`${product.id}-${index}`}
            product={product}
            id={product.id}
            onRemove={removeProductFromCart}
          />
        ))}
      </div>
    </div>
  );
};

export default CartProductList;