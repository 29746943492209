import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '../../hooks/productsTypedSelector';
import { setChangeOrderIDAction } from '../../store/actions/order';
import style from './style.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

const PaymentEmpty: React.FC<Props> = ({text}) => {

  const history = useHistory();
  const dispatch = useTypedDispatch();

  const { personalInfo } = useTypedSelector(state => state.personalInfo);
  const { activeDate, activeTime, activeClinic } = useTypedSelector(state => state.bookClinicVisit);

  const formatDate = personalInfo.dateOfBirth.split('/');
  const validFormatDate = formatDate[2] + '-' + formatDate[1] + '-' + formatDate[0];

  const order = {
    'full_name': personalInfo.firstName + ' ' + personalInfo.lastName,
    'phone': personalInfo.contact,
    'email': personalInfo.email,
    'gender': personalInfo.gender.toLowerCase(),
    'nric': personalInfo.passport,
    'allergies': personalInfo.allergies,
    'nationality': personalInfo.nationality.toLowerCase(),
    'date_of_birth': validFormatDate,
    'consultation': {
      'employee_id': null,
      'clinic_id': activeClinic?.id,
      'date_time': activeDate + ' ' + activeTime
    }
  };

  const handClick = () => {

    fetch(`${process.env.REACT_APP_API}/order/no-payment/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(order)
    })
      .then((response) => response.json())
      .then((details) => {
        dispatch(setChangeOrderIDAction(details.data.hash));
      })
      .catch((err) => {})
      .finally(() => {
        history.push('/confirmed-order');
      });

  };
  return <button className={style.button} onClick={handClick}>{text}</button>;
};

export default PaymentEmpty;