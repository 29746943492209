import React from 'react';
import style from './style.module.scss';

interface Props {
  title: string, 
  description?: string,
}

const BookTitle: React.FC<Props> = ({title, description}) => {
  return (
    <div className={style.title}>
      <h2>{title}</h2>
      <p>{description && description}</p>
    </div>
  );
};

export default BookTitle;