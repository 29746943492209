import {
  DoctorsState,
  DoctorsAction,
  DoctorsActionTypes
} from '../../types/bookConsultation';
import { format } from 'date-fns';

const initialState: DoctorsState = {
  doctors: [],
  activeDoctor: undefined,
  activeDate: format(new Date(), 'yyyy-MM-dd'),
  activeTime: null,
  loadingBookConsultation: true,
  error: null
};

export const bookConsultationReducer = (state = initialState, action: DoctorsAction): DoctorsState => {
  switch (action.type) {
  case DoctorsActionTypes.FETCH_DOCTORS:
    return {...state, loadingBookConsultation: true, error: null, activeDoctor: undefined};
  case DoctorsActionTypes.FETCH_DOCTORS_SUCCESS:
    return {...state, loadingBookConsultation: false, error: null, doctors: action.payload, activeDoctor: action.payload[0]};
  case DoctorsActionTypes.FETCH_DOCTORS_ERROR:
    return {...state, loadingBookConsultation: false, error: action.payload, doctors: [], activeDoctor: undefined};
  case DoctorsActionTypes.CHANGE_MONTH: {
    const newDoctors = [...state.doctors].map((item) => {
      return {
        ...item,
        disabledDates: {
          ...item.disabledDates,
          ...action.payload.find(el => el.id === item.id)?.disabledDates
        }
      };
    });
    const newState = {...state, loadingBookConsultation: false, error: null, doctors: newDoctors, activeDoctor: newDoctors.find(item => state.activeDoctor && item.id === state.activeDoctor.id)};

    return {...newState};
  }
  case DoctorsActionTypes.CHANGE_ACTIVE_DOCTOR:
    return {...state, activeDoctor: state.doctors.find(doctor => doctor.id === action.payload)};
  case DoctorsActionTypes.CHANGE_ACTIVE_DATE_DOCTOR:
    return {...state, activeDate: action.payload};
  case DoctorsActionTypes.CHANGE_ACTIVE_TIME_DOCTOR:
    return {...state, activeTime: action.payload};
  case DoctorsActionTypes.CLEAR_DOCTOR_DATA:
    return {...state, ...initialState};
  default:
    return state;
  }
};