import {
  ClinicsState,
  ClinicsAction,
  ClinicsActionTypes
} from '../../types/bookClinicVisit';
// doctors 
import { format } from 'date-fns';

const initialState: ClinicsState = {
  clinics: [],
  activeClinic: undefined,
  activeDate: format(new Date(), 'yyyy-MM-dd'),
  activeTime: null,
  loading: false,
  error: null
};

export const bookClinicVisitReducer = (state = initialState, action: ClinicsAction): ClinicsState => {
  switch (action.type) {
  case ClinicsActionTypes.FETCH_CLINICS:
    return {...state, loading: true, error: null, clinics: [], activeClinic: undefined};
  case ClinicsActionTypes.FETCH_CLINICS_SUCCESS:
    return {...state, loading: false, error: null, clinics: action.payload, activeClinic: action.payload[0]};
  case ClinicsActionTypes.CHANGE_MONTH:
    return {...state, activeDate: state.activeDate, activeClinic: state.activeClinic && {...state.activeClinic, disabledDates: {...state.activeClinic?.disabledDates, ...action.payload.find(el => state.activeClinic && el.id === state.activeClinic.id)?.disabledDates}}, clinics: [...state.clinics].map((item, index) => ({...item, disabledDates: {...item.disabledDates, ...action.payload[index].disabledDates}}))};
  case ClinicsActionTypes.FETCH_CLINICS_ERROR:
    return {...state, loading: false, error: action.payload, clinics: [], activeClinic: undefined};
  case ClinicsActionTypes.CHANGE_ACTIVE_CLINIC:
    return {...state, activeClinic: state.clinics.find(clinic => clinic.id === action.payload)};
  case ClinicsActionTypes.CHANGE_ACTIVE_DATE_CLINIC:
    return {...state, activeDate: action.payload};
  case ClinicsActionTypes.CHANGE_ACTIVE_TIME_CLINIC:
    return {...state, activeTime: action.payload};
  case ClinicsActionTypes.CLEAR_CLINIC_DATA:
    return {...state, activeTime: null, activeDate: format(new Date(), 'yyyy-MM-dd'), activeClinic: state.clinics[0]};
  default:
    return state;
  }
};