import { BloodCollectionActionTypes, BloodCollectionAction, ChangeActiveDate, ChangeActiveTime, ClearBloodCollectionData, ChangeAddressBloodCollection, Address } from './../../types/bookBloodCollection';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<BloodCollectionAction>) => void;

export const fetchBloodCollectionDisableDates = (): ReturnValue => {
  return async (dispatch: Dispatch<BloodCollectionAction>) => {
    try {
      dispatch({
        type: BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION
      });
      const response = await axios.get('');
      dispatch({
        type: BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION_SUCCESS,
        payload: response.data
      });
    } catch (e) {
      dispatch({
        type: BloodCollectionActionTypes.FETCH_BLOOD_COLLECTION_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const changeActiveDateAction = (payload:  string): ChangeActiveDate => ({type: BloodCollectionActionTypes.CHANGE_ACTIVE_DATE_BLOOD_COLLECTION, payload});
export const changeActiveTimeAction = (payload: string | null): ChangeActiveTime => ({type: BloodCollectionActionTypes.CHANGE_ACTIVE_TIME_BLOOD_COLLECTION, payload});
export const clearBloodCollectionDataAction = (): ClearBloodCollectionData => ({type: BloodCollectionActionTypes.CLEAR_BLOOD_COLLECTION_DATA});
export const changeAddressBloodCollectionAction = (payload: Address): ChangeAddressBloodCollection => ({type: BloodCollectionActionTypes.CHANGE_ADDRESS_BLOOD_COLLECTION, payload});

