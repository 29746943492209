import React, {ChangeEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import style from './style.module.scss';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';

// calendar
import DateAndTimePicker from '../../components/OrderComponents/DateAndTimePicker';
import BookTypePicker from '../../components/OrderComponents/BookTypePicker';
import { changeActiveDateAction, changeActiveDoctorAction, changeActiveTimeAction, fetchDoctors } from '../../store/actions/bookConsultation';
import { BookingTime, initialBookingTime } from '../../components/OrderComponents/StepComponents/BookConsultation/consts';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import { Doctor } from '../../types/bookConsultation';
import { Clinic } from '../../types/bookClinicVisit';
import { Product } from '../../types/product';
import AddButton from '../AddButton';


interface Props {
  variations: any;
  questions: any;
  onAdd: (index: number) => void;
  typeData: Doctor[] | Clinic[], 
  activeTypeData: Doctor | Clinic | undefined, 
  changeActiveTypeData: (doctorId: number) => void,
  activeDate: string,
  activeTime: string | null, 
  changeDate: (date: string) => void, 
  changeTime: (value: string) => void, 
  bookingTime: BookingTime | { [key: string]: string[]; [key: number]: string[]; [key: symbol]: string[]; }, 
  withShortView?: boolean,
  range?: boolean,
  onChangeMonth?: (date: any) => void,
  bookingDates? : any,
  product: Product,
}

interface ToggleRadioButtonProps {
  id?: string;
  checked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name: string;
  value: number | string;
  price?: number;
  group: string;
  note?: string;
  priceText?: string;
}

export const ToggleRadioButton = ({id, checked, onChange, name, value, group, price, note, priceText}: ToggleRadioButtonProps) => {

  return (
    <label className={style.btn}>
      <input
        type='radio'
        value={value}
        name={group}
        checked={checked}
        className={style.control}
        onChange={onChange}
      />
      <div className={style.panel}>
        <div className={style.name}>{name}</div>
        {
          note ? (
            <div className={style.note}>{note}</div>
          ) : null
        }
      </div>

      {
        price ? (
          <div className={style.price}>
            {priceText ? priceText + ' ' : null}
            ${price}
          </div>
        ) : null
      }

    </label>
  );
};

const ProductVariationsSteps: React.FC<Props> = ({
  variations, 
  questions, 
  onAdd, 
  typeData, 
  activeTypeData, 
  changeActiveTypeData,
  changeDate, 
  changeTime, 
  bookingTime, 
  withShortView,
  range,
  onChangeMonth,
  bookingDates,
  product
}) => {
  
  const dispatch = useDispatch();
  const {products} = useTypedSelector(state => state.cartProducts);
  const [indexProduct, setIndexProduct] = useState<number>(-1);
  const [resetRadioButton, setResetRadioButton] = useState<boolean | undefined>(false);
  const [bookDoctorTime, setBookDoctorTime] = useState<boolean>(false);
  const [checkedBrand, setCheckedBrand] = useState<number | string>('');
  const [productIDcheck, setProductIDcheck] = useState<number>(-1);
  const [productsNoBrands, setProductsNoBrands] = useState<any>([]);
  const [brandID, setBrandID] = useState<number | null>(null);
  const [brandGroupResult, setBrandGroupResult] = useState<any>([]);
  const [productsBrands, setProductsBrands] = useState<any>([]);
  const [productsBrandsID, setProductsBrandsID] = useState<any>([]);
  const [isBrand, setBrand] = useState<number>(-1);
  const [unicBrand, setUnicBrand] = useState<any>([]);
  const [brandName, setBrandName] = useState<any>([]);
  const [checkedIDAnswer, setCheckedIDAnswer] = useState<number | string>('');
  const [currentIndexProduct, setCurrentIndexProduct] = useState<number | undefined>(undefined);
  const [currentAnswer, setCurrentAnswer] = useState<string>('');

  function getIndex(value: number, arr: any, prop: string) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1;
  }

  function handleClickRadio(value: string, index: number, id: number) {
    setCheckedBrand(Number(value));
    setProductIDcheck(index);
    setBookDoctorTime(false);

    const productIndexPosition = getIndex(id, variations, 'id');
    setIndexProduct(Number(productIndexPosition));
  }

  function handleClickDetectBrand(value: string, brand: number, id: number, answer: string) {
    setCheckedIDAnswer(Number(value));
    setBrand(brand);
    setProductIDcheck(id);
    setBookDoctorTime(false);
    setResetRadioButton(false);
    setCurrentAnswer(answer);

    setCurrentIndexProduct(undefined);
    setIndexProduct(-1);

    if (brand === 0) {
      setBrandID(null);
    }
    
    
  }

  function handleClickBrand (brand: string, idBrand: number, idProduct: number, product: boolean) {
    setBrandName(brand);
    setBrandID(Number(idBrand));

    if (product) {
      const productIndexPosition = getIndex(idProduct, variations, 'id');
      setIndexProduct(productIndexPosition);
    }
  }

  function handleClickNoBrand (brand: string, index: number, id: number, product: any) {
  
    const productIndexPosition = getIndex(id, productsNoBrands, 'id');
    
    setResetRadioButton(true);
    setBrandID(null);

    setCurrentIndexProduct(id);
    
    // refactoring
    if (brand && brand.includes('consultation')) {

      setBookDoctorTime(true);
    } else {
      setBookDoctorTime(false);
    }
   
    setIndexProduct(Number(productIndexPosition));
  }

  useEffect(() => {

    // we define products without brands
    const noBrands = filter(variations, function (item: any) {
      return item.brand === null;
    });
    setProductsNoBrands(noBrands);

    // we define products with brands
    const brands = filter(variations, function (item: any) {
      return item.brand !== null;
    });
    setProductsBrands(brands);

    // identifying products with unique brands
    const unicBrands: any[] = uniqBy(productsBrands, function (item: any) {
      return item.brand.name;
    });
    setUnicBrand(unicBrands);

    const noBrandsID = filter(productsNoBrands, { 'question_answers': [{ id: productIDcheck}] });
    
    setProductsBrandsID(noBrandsID);
  
    const brandIDResult = filter(productsBrands, { 'brand': { id: Number(brandID)}, 'question_answers': [{  'answer': currentAnswer}] });
    setBrandGroupResult(brandIDResult);

  }, [productIDcheck, brandID, indexProduct, currentAnswer]);


  // calendar
  const { doctors, activeDoctor, activeDate, activeTime } = useTypedSelector(state => state.bookConsultation);
  
  const changeDoctor = useCallback((doctorId) => {
    if (activeDoctor && activeDoctor.id !== doctorId) {
      dispatch(changeActiveDoctorAction(doctorId));
      dispatch(changeActiveTimeAction(null));
    }
    const getDoctor = doctors.find((el) => {
      return el.id === doctorId;
    });

    if (getDoctor) {
      dispatch(changeActiveDateAction(Object.keys(getDoctor.disabledDates)[0]));
    }
  }, [activeDoctor, dispatch]);



  // end calendar

  const [added, setAdded] = useState(false);
  function isAdded() {
    setAdded(true);

    setTimeout(() => {
      setAdded(false);
    }, 3000);

  }

  const availableConsultation = useMemo(() => {
    return [...products].filter(item => item.consultation !== null).length !== 0;
    //return [...products].filter(item => item.product_type === 'online_consultation').length !== 0 && product && product.product_type === 'online_consultation';
  }, [products]);

  return (
    <div className={style.variations}>
      
      {
        questions !== null ? (
          <React.Fragment>

            {/* Detect id answer & brand (0 || 1) */}
            <div className={style.module}>
              <div className={style.head}>
                <strong className={style.title}>{questions.question}</strong>
              </div>
              <div className={style.body}>
                <div className={style.grid}>
                  {
                    questions.answers.map((item: any, index: number) => {
                      return <div key={index} className={style.cell}>
                        <ToggleRadioButton
                          onChange={(event) => handleClickDetectBrand(event.target.value, item.branded, item.id, item.answer)}
                          name={item.answer}
                          group={'brand'}
                          value={item.id}
                        />
                      </div>;
                    })
                  }
                </div>
              </div>
            </div>
            {/* brand ? */}

            {
              isBrand === 0 ? (
                <div className={style.module}>
                  <div className={style.head}>
                    <strong className={style.title}>Your option:</strong>
                  </div>
                  <div className={style.body}>
                    <div className={style.grid}>
                      { productsBrandsID.map((item: any, index: number) => {
                        return <div key={index} className={style.cell}>
                          <ToggleRadioButton
                            onChange={(event) => {handleClickNoBrand(event.target.value, index, item.id, item);} }
                            name={item.name}
                            group={'noBrandGroup'}
                            checked={item.id === currentIndexProduct ? true : false}
                            value={item.name}
                            price={item.price}
                          />
                        </div>;
                      })
                      }
                    </div>
                  </div>
                </div>
              ) : null
            }
           
            {
              doctors && activeDoctor ? (
                <div className={style.module} style={{display: bookDoctorTime ? 'block' : 'none'}}>
                  <div className={style.body}>
                    {products.filter(item => item.product_type === 'online_consultation').length !== 0 || availableConsultation ? (
                      <div className={style.warning}>There is already a consultation in the cart</div>
                    ) : (
                      <div className={style.doctors}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <BookTypePicker 
                            typeData={doctors}
                            activeTypeData={activeDoctor}
                            changeActiveTypeData={changeDoctor}
                            title='Select a doctor:'
                          />
                          <DateAndTimePicker 
                            activeDate={activeDate}
                            activeTime={activeTime}
                            onChangeMonth={onChangeMonth}
                            changeDate={changeDate}
                            changeTime={changeTime}
                            bookingDates={activeDoctor && activeDoctor.disabledDates}
                            bookingTime={activeDoctor ? activeDoctor.disabledDates[activeDate] : bookingTime}
                            title={'Select appointment time:'}
                            withShortView
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  </div>
                </div>
              ) : null
            }

            {
              isBrand === 1 ? (
                <div className={style.module}>
                  <div className={style.head}>
                    <strong className={style.title}>Which brand would you like?</strong>
                  </div>
                  <div className={style.body}>
                    <div className={style.grid}>
                      {
                        unicBrand.map((item: any, index: number) => {
                          return <div key={index} className={style.cell}>
                            <ToggleRadioButton
                              onChange={(event) => {handleClickBrand(event.target.value, item.brand_id, item.id, false);} }
                              name={item.brand.name}
                              group={'brandGroup'}
                              price={item.price}
                              priceText={'From'}
                              value={item.brand_id}
                            />
                          </div>;
                        })
                      }
                    </div>
                  </div>
                </div>
              ) : null
            }

            {
              brandGroupResult.length > 0 ? (
                <div className={style.module}>
                  <div className={style.head}>
                    <strong className={style.title}>Your options:</strong>
                  </div>
                  <div className={style.body}>
                    <div className={style.grid}>
                      {
                        brandGroupResult.map((item: any, index: number) => {
                          return <div key={index} className={style.cell}>
                            <ToggleRadioButton
                              onChange={(event) => {handleClickBrand(event.target.value, item.brand_id, item.id, true);} }
                              name={item.name}
                              price={item.price}
                              group={'brandGroupResult'}
                              value={index - 1}
                            />
                          </div>;
                        })
                      }
                    </div>
                  </div>
                </div>
              ) : null
            }
            
          </React.Fragment>

        ) : (
          // Morning-After Pill
          <React.Fragment>
            <div className={style.module}>
              <div className={style.head}>
                <strong className={style.title}>Your options:</strong>
              </div>
              <div className={style.body}>
                <div className={style.grid}>
                  {
                    variations.map((item: any, index: number) => {
                      return <div key={index} className={style.cell}>
                        <ToggleRadioButton
                          onChange={(event) => handleClickRadio(event.target.value, index, item.id)}
                          name={item.name}
                          value={item.product_id}
                          group={'optionsPill'}
                          price={item.price}
                          note={item.summary}
                        />
                      </div>;
                    })
                  }
                </div>
              </div>
            </div>
            <div className={style.module}>
              <div className={style.head}>
                <strong className={style.title}>Have you ever taken morning-after pill?</strong>
              </div>
              <div className={style.body}>
                <div className={style.grid}>
                  <div className={style.cell}>
                    <ToggleRadioButton
                      onChange={(event) => setCheckedBrand(event.target.value)}
                      value={'yes'}
                      name={'Yes'}
                      group={'togglePill'}
                    />
                  </div>
                  <div className={style.cell}>
                    <ToggleRadioButton
                      onChange={(event) => setCheckedBrand(event.target.value)}
                      value={'no'}
                      name={'No'}
                      group={'togglePill'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
      
      <div className={style.footer}>
        <AddButton
          onAdd={() => product && onAdd(indexProduct)}
          disabled={indexProduct === -1 || (bookDoctorTime && !activeTime) || (bookDoctorTime && products.filter(item => item.product_type === 'online_consultation').length !== 0)}
        />
      </div>

    </div>
  );
};

export default ProductVariationsSteps;