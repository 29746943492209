import { OrderAction } from './../../types/order';
import { OrderState, OrderActionTypes } from '../../types/order';

const initialState: OrderState = {
  step: null,
  serviceLocation: null,
  products: [],
  orderID: null,
};

export const orderReducer = (state = initialState, action: OrderAction): OrderState => {
  switch (action.type) {
  case OrderActionTypes.CHANGE_STEP:
    return { ...state, step: action.payload };
  case OrderActionTypes.CHANGE_ORDER_ID:
    return { ...state, orderID: action.payload };
  case OrderActionTypes.CLEAR_STEP:
    return { ...state, step: null };
  case OrderActionTypes.SET_SERVICE_LOCATION:
    return { ...state, serviceLocation: action.payload };
  case OrderActionTypes.CLEAR_SERVICE_LOCATION:
    return { ...state, serviceLocation: null };
  default:
    return state;
  }
};