import React from 'react';

const IconHeart: React.FC = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="25" 
      height="24" 
      viewBox="0 0 25 24" 
      fill="none"
    >
      <path 
        d="M2.98828 9.18555C2.98828 13.2578 6.54297 17.252 11.9043 20.7285C12.0801 20.8359 12.3242 20.9434 12.5 20.9434C12.6758 20.9434 12.9199 20.8359 13.1055 20.7285C18.4668 17.252 22.0215 13.2578 22.0215 9.18555C22.0215 5.8457 19.7168 3.46289 16.6406 3.46289C14.873 3.46289 13.3398 4.37109 12.5 5.77734C11.6699 4.38086 10.127 3.46289 8.35938 3.46289C5.2832 3.46289 2.98828 5.8457 2.98828 9.18555ZM4.18945 9.18555C4.18945 6.5 5.95703 4.67383 8.34961 4.67383C10.166 4.67383 11.2891 5.79688 11.9629 6.91992C12.168 7.24219 12.3047 7.33984 12.5 7.33984C12.6953 7.33984 12.8223 7.23242 13.0469 6.91992C13.7402 5.80664 14.8438 4.67383 16.6504 4.67383C19.043 4.67383 20.8105 6.5 20.8105 9.18555C20.8105 12.8477 16.9922 16.7148 12.6758 19.5957C12.5977 19.6445 12.5391 19.6836 12.5 19.6836C12.4609 19.6836 12.4121 19.6445 12.334 19.5957C8.01758 16.7148 4.18945 12.8477 4.18945 9.18555Z" 
        fill="black"
      />
      <rect 
        x="4" 
        y="9" 
        width="17" 
        height="1" 
        fill="black"
      />
      <rect 
        x="4" 
        y="6.5" 
        width="17" 
        height="1" 
        fill="black"
      />
      <rect 
        x="4" 
        y="11.5" 
        width="17" 
        height="1" 
        fill="black"
      />
      <rect 
        x="6" 
        y="14" 
        width="13" 
        height="1" 
        fill="black"
      />
      <rect 
        x="8" 
        y="16.5" 
        width="9" 
        height="1" 
        fill="black"
      />
    </svg>
  );
};

export default IconHeart;