import React from 'react';
// Icons
// import IconCube from '../../assets/images/icons/cube';
import IconDashedSquare from '../../assets/images/icons/dashedSquare';
import IconHeart from '../../assets/images/icons/heart';
import IconMessages from '../../assets/images/icons/messages';
import IconStethoscope from '../../assets/images/icons/stethoscope';
import useWindowSize from '../../hooks/useWindowSize';
import CustomSwiper from '../CustomSwiper';
// styles
import style from './style.module.scss';

const BannerAbout: React.FC = () => {

  const SlidesData = [
    // {
    //   id: 1, 
    //   icon: <IconCube />,
    //   title: 'Free island-wide delivery',
    //   text: 'We offer free delivery on all orders in Singapore.',
    // },
    {
      id: 2, 
      icon: <IconStethoscope />,
      title: 'In collaboration with Singapore licensed clinics',
      text: 'All consultations, test results and prescriptions are executed by our partner clinics doctors.',
    },
    {
      id: 3, 
      icon: <IconDashedSquare />,
      title: 'Delivered in discreet packaging',
      text: 'No worries, all items will be delivered in an unmarked envelope.',
    },
    {
      id: 4, 
      icon: <IconHeart />,
      title: 'Healthcare for everyone',
      text: 'Our doctors and professionals have pledged to be LGBTQ+ affirming.',
    },
    {
      id: 5, 
      icon: <IconMessages />,
      title: 'End-to-end customer care',
      text: 'We are here with you on this journey with our friendly team of customer care representatives.',
    }
  ];

  const [windowWidth] = useWindowSize();

  const slides = (
    SlidesData.map(slide => (
      <div
        className={style.cell}
        key={slide.id}
      >
        <div className={style.slide}>
          {slide.icon}
          <div>
            <span>{slide.title}</span>
            <p>{slide.text}</p>
          </div>
        </div>
      </div>
    ))
  );

  return (
    <div className={style.container}>
      <div className='container'>
        {windowWidth > 1024 
          ? <CustomSwiper parameters={{ slidesPerView: 2, slidesPerGroup: 2, loop: true, followFinger: false, spaceBetween: 0, autoplay: { delay: 5000 } }} classesForButtons={['.bannerNextButtonSwiper', '.bannerPrevButtonSwiper']} >
            {slides}
          </CustomSwiper>
          : slides}
      </div>
    </div>
  );
};

export default BannerAbout;