import React, { useCallback, useMemo, useState, useEffect } from 'react';
import IconInfo from '../../../assets/images/icons/info';
import { Option, PersonalInfo } from '../../../types/personalInfo';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CustomTextarea from '../../CustomTextarea';
import CustomTooltip from '../../CustomTooltip';
import BookButtons from '../BookButtons';
import { useTypedDispatch, useTypedSelector } from '../../../hooks/productsTypedSelector';
import { changeStepAction, clearStepAction } from '../../../store/actions/order';
import { ServiceLocationType, StepTypes } from '../../../types/order';
import style from './style.module.scss';
import { clearBloodCollectionDataAction } from '../../../store/actions/bookBloodCollection';
import { changePersonalInfo, clearPersonalInfo } from '../../../store/actions/personalInfo';
import { getCartProducts } from '../../../store/selectors/cartProducts';
import { getCartProductsContainTypes } from '../../../store/selectors/cartProducts';
import { ProductTypes } from '../../../types/product';
import { getOrderState } from '../../../store/selectors/order';
import { getShippingState } from '../../../store/selectors/shipping';

interface Props {
  genderOptions: Option[], 
  nationalityOptions: Option[],
  personalInfo: PersonalInfo,
}

const PersonalInfoForm: React.FC<Props> = ({genderOptions, nationalityOptions, personalInfo}) => {
  const dispatch = useTypedDispatch();
  const [alerts, setAlerts] = useState(false);

  const cartProducts = useTypedSelector(state => state.cartProducts.products);
  const findSupplements = cartProducts.map(item => item.product_type === 'supplements');
  const someSupplements = findSupplements.includes(false);

  // validate form
  const [validateDateOfBirth, setValidateDateOfBirth] = useState(false);
  const [messageDateOfBirth, setMessageDateOfBirth] = useState('');

  const [validatePhoneNumber, setValidatePhoneNumber] = useState(false);
  const [messageValidatePhone, setMessageValidatePhone] = useState('');

  const [validateEmailString, setValidateEmailString] = useState(false);
  const [messageEmailWarning, setMessageEmailWarning] = useState('');

  const validatePhone = (dateNumber: string) => {
    if (dateNumber.length >= 8) {
      setMessageValidatePhone('');
      return true;
    } else {
      setMessageValidatePhone('Phone number must be at least 8 digits.');
      return false;
    }
  };

  const validateDate = ( dateString: string) => {
  
    const regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;

    const dtCurrent = new Date();
    const parts = dateString.split('/');
    const dtDOB = new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
    const validYear = dtCurrent.getFullYear() - dtDOB.getFullYear();
  
    setMessageDateOfBirth('');

    if (regex.test(dateString) &&  validYear >= 0) {

      if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 16) {
        setMessageDateOfBirth('You must be 16 years old and above to purchase our home-based services. Alternatively, you could visit one of our partner clinics.');
        return false;
      } else {
        setMessageDateOfBirth('');
        return true;
      }
    } else {
      setMessageDateOfBirth('Enter a valid date.');
      return false;
    }

  };

  const validateEmail = ( dataString: string) => {
    if (dataString.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && dataString.length > 2) {
      setMessageEmailWarning('');
      return true;
    } else {
      setMessageEmailWarning('You have entered an invalid email address');
      return false;
    }
  };

  useEffect(()=>{

    if (formValues.dateOfBirth && formValues.dateOfBirth.length > 9 && validateDate(formValues.dateOfBirth)) {
      setValidateDateOfBirth(true);
    }
    
  },[]);

  const checkFormValues = useCallback((formValues): boolean => {

    return someSupplements ? (
      formValues.firstName.length < 2 
      || formValues.dateOfBirth.length < 1 
      || formValues.dateOfBirth.indexOf('_') !== -1
      || !validateDateOfBirth
      || formValues.gender === '' 
      || formValues.nationality === '' 
      || formValues.passport.length < 2
      || !validateEmail
      || !validatePhone
      || formValues.allergies.length < 1
    ) : (
      formValues.firstName.length < 2 
      || formValues.lastName.length < 2 
      || !validateEmail
      || !validatePhone
      || formValues.allergies.length < 1
    );
  }, [validateDateOfBirth, validateEmail]);

  const { serviceLocation } = useTypedSelector(getOrderState);
  const products = useTypedSelector(getCartProducts);
  const isOrderContainOtherProducts = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.TESTING_KIT, ProductTypes.PRESCRIPTION, ProductTypes.SUPPLEMENTS]));
  const isOrderContainSupplements = useTypedSelector(state =>
    getCartProductsContainTypes(state, [ProductTypes.SUPPLEMENTS]));
  const isOrderContainBloodCollection = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.BLOOD_TEST]));
  const isOrderContainClinicVisit = useTypedSelector(state => getCartProductsContainTypes(state, [ProductTypes.CLINIC_VISIT]));
  const [formValues, setFormValues] = useState<PersonalInfo>(personalInfo);

  const { activeShippingOption } = useTypedSelector(getShippingState);
  
  const shippingOption = activeShippingOption?.value;

  const onClickBack = useCallback(() => {
    if (isOrderContainBloodCollection && isOrderContainOtherProducts && serviceLocation === ServiceLocationType.HOME) {
      dispatch(changeStepAction(StepTypes.SHIPPING));
    } else if (isOrderContainBloodCollection && serviceLocation === ServiceLocationType.HOME) {
      dispatch(changeStepAction(StepTypes.BOOK_BLOOD_COLLECTION));
    } else if (isOrderContainClinicVisit || serviceLocation === ServiceLocationType.CLINIC) {
      dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT));
    } else if (isOrderContainOtherProducts) {
      dispatch(changeStepAction(StepTypes.BOOK_CONSULTATION));
    } else {
      dispatch(clearStepAction());
    }
    dispatch(clearBloodCollectionDataAction());
    dispatch(clearPersonalInfo());
  }, [dispatch, isOrderContainBloodCollection, isOrderContainClinicVisit, isOrderContainOtherProducts, serviceLocation]);

  const onClickContinue = useCallback(() => {
    if (!checkFormValues(formValues)) {
      if ((!isOrderContainBloodCollection && serviceLocation !== ServiceLocationType.CLINIC) || isOrderContainSupplements) {
        if (
          products.length === 1 && products[0].price === 0 && products[0].product_type === ProductTypes.CLINIC_VISIT
        ) {
          dispatch(changeStepAction(StepTypes.REVIEW_ORDER));
        } else if (
          products.length === 1 && products[0].product_type === ProductTypes.ONLINE_CONSULTATION ||
          shippingOption === 'DURING_APPOINTMENT' ||
          shippingOption === 'SEPARATELY_APPOINTMENT'
        ) {
          dispatch(changeStepAction(StepTypes.PAYMENT_DETAILS));
        } else {
          dispatch(changeStepAction(StepTypes.DELIVERY_DETAILS));
        }
      } else if (isOrderContainClinicVisit && products.length === 1) {
        dispatch(changeStepAction(StepTypes.REVIEW_ORDER));
      } else if (serviceLocation === ServiceLocationType.CLINIC || ServiceLocationType.HOME) {
        dispatch(changeStepAction(StepTypes.PAYMENT_DETAILS));
      }
      dispatch(changePersonalInfo(formValues));
    } else {
      setAlerts(true);
    }
  }, [checkFormValues, isOrderContainBloodCollection, isOrderContainSupplements, isOrderContainClinicVisit, products.length, dispatch, formValues]);

  const [openGenderTooltip, setOpenGenderTooltip] = useState(false);
  const [openContactTooltip, setOpenContactTooltip] = useState(false);

  const onChangeValues = useCallback((value, type) => {
    switch (type) {
    case 'firstName': 
      setFormValues(prev => ({...prev, firstName: value}));
      break;
    case 'lastName': 
      setFormValues(prev => ({...prev, lastName: value}));
      break;
    case 'dateOfBirth': 
      setFormValues(prev => ({...prev, dateOfBirth: value}));

      const dateChange = validateDate(value);

      setValidateDateOfBirth(()=>{
        return dateChange;
      });

      break;
    case 'gender': 
      setFormValues(prev => ({...prev, gender: value}));
      break;
    case 'nationality': 
      setFormValues(prev => ({...prev, nationality: value}));
      break;
    case 'passport': 
      setFormValues(prev => ({...prev, passport: value}));
      break;
    case 'email': 
      setFormValues(prev => ({...prev, email: value}));

      const dateEmail = validateEmail(value);

      setValidateEmailString(()=>{
        return dateEmail;
      });

      break;
    case 'contact': 
      setFormValues(prev => ({...prev, contact: value}));

      const datePhone = validatePhone(value);

      setValidatePhoneNumber(()=>{
        return datePhone;
      });

      break;
    case 'allergies': 
      setFormValues(prev => ({...prev, allergies: value}));
      break;
    default: 
      break;
    }
  }, [setFormValues]);

  return (
    <form className={style.personalInfo}>
      <div className={style.flex}>
        <CustomInput 
          label={someSupplements ? 'Full name (as in NRIC/FIN/Passport):' : 'Full name' }
          type="text" 
          name="firstName"
          alert={alerts && formValues.firstName.length < 2} 
          placeholder="First Name" 
          value={formValues.firstName} 
          onChange={onChangeValues} 
        />
        <CustomInput 
          type="text" 
          name="lastName" 
          placeholder="Last Name" 
          alert={alerts && formValues.lastName.length < 2} 
          value={formValues.lastName} 
          onChange={onChangeValues} 
        />
      </div>
      {someSupplements && <div className={style.flex}>
        <CustomInput 
          label="Date of Birth:"
          type="text" 
          name="dateOfBirth"
          alert={Boolean(alerts && (formValues.dateOfBirth.length < 1 || formValues.dateOfBirth.indexOf('_') !== -1))}  
          mask="99/99/9999"
          placeholder="DD/MM/YYYY" 
          value={formValues.dateOfBirth} 
          onChange={onChangeValues}
          message={messageDateOfBirth}
        />
        <CustomSelect 
          label={(
            <div className={style.genderTooltip}>
              <div>Gender: </div>
              <CustomTooltip
                title="Please select a gender identity you identify with most. This is required for our doctor to provide you with the appropriate care." 
                setOpenTooltip={setOpenGenderTooltip}
                openTooltip={openGenderTooltip}
                PopperProps={{
                  disablePortal: true,
                }}
                disableTouchListener
                disableFocusListener
                disableHoverListener
                placement="right"
                arrow
              >
                <button type="button" onClick={() => setOpenGenderTooltip(true)}><IconInfo /></button>
              </CustomTooltip>
            </div>
          )}
          alert={alerts && formValues.gender === ''}
          options={genderOptions}
          value={formValues.gender}
          handleChange={onChangeValues}
          name="gender"
          placeholder={'Select...'}
        />
      </div>
      }
      {someSupplements && <div className={style.flex}>
        <CustomSelect 
          label="Nationality:"
          options={nationalityOptions}
          value={formValues.nationality}
          handleChange={onChangeValues}
          alert={alerts && formValues.nationality === ''}
          name="nationality"
          placeholder={'Select...'}
        />
        <CustomInput 
          type="text" 
          label="NRIC/FIN/Passport:"
          alert={alerts && formValues.passport.length < 2 }  
          name="passport" 
          placeholder="ID number" 
          value={formValues.passport} 
          onChange={onChangeValues} 
        />
      </div>
      }
      <div className={style.flex}>
        <CustomInput
          label="Email:"
          type="email" 
          name="email" 
          placeholder="Email address" 
          alert={
            (alerts && formValues.email.length < 2) || (alerts && formValues.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null)} 
          value={formValues.email} 
          onChange={onChangeValues} 
          message={messageEmailWarning}
        />
      </div>
      <div className={style.flex}>
        <CustomInput
          label={(
            <div className={style.genderTooltip}>
              <div>Contact: </div>
              <CustomTooltip
                title="Our doctor will contact you for the tele-consultation via WhatsApp at the phone number indicated here. Please ensure that it is correct." 
                setOpenTooltip={setOpenContactTooltip}
                openTooltip={openContactTooltip}
                PopperProps={{
                  disablePortal: true,
                }}
                disableTouchListener
                disableFocusListener
                disableHoverListener
                placement="right"
                arrow
              >
                <button type="button" onClick={() => setOpenContactTooltip(true)}><IconInfo /></button>
              </CustomTooltip>
            </div>
          )}
          type="number" 
          name="contact" 
          alert={alerts && formValues.contact.length < 10} 
          placeholder="Mobile number" 
          value={formValues.contact} 
          onChange={onChangeValues}
          message={messageValidatePhone}
        />
      </div>
      <div className={style.flex}>
        <CustomTextarea
          label="Allergies:"
          name="allergies"
          placeholder="(Mandatory)" 
          value={formValues.allergies} 
          alert={alerts && formValues.allergies.length < 1} 
          onChange={onChangeValues} 
        />
      </div>
      <BookButtons 
        onClickBack={onClickBack} 
        onClickContinue={onClickContinue} 
        activeContinueButton={!checkFormValues(formValues)}
        buttonText="Continue" 
      />
    </form>
  );
};

export default PersonalInfoForm;