import React from 'react';

const LogoFerne: React.FC = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="none"
      viewBox="0 0 70 18"
    >
      <path
        d="M6.9405 1.4365C4.53017 1.2405 3.325 2.34067 3.325 4.737V5.3915H6.9405V6.578H3.325V17.5225H2.1V6.578H0V5.3915H2.1V4.737C2.1 3.21567 2.51183 2.063 3.3355 1.279C4.15917 0.495 5.36083 0.159 6.9405 0.271V1.4365Z"
        fill="black"
      />
      <path
        d="M18.7358 5.1255C19.5526 5.09852 20.3651 5.25419 21.1141 5.58116C21.8631 5.90813 22.5297 6.39816 23.0653 7.0155C24.164 8.25024 24.7576 9.85344 24.7278 11.506C24.7278 11.569 24.7278 11.667 24.7278 11.7965C24.7278 11.926 24.7278 12.024 24.7278 12.087H13.7133C13.8126 13.3534 14.3999 14.5317 15.3513 15.3735C16.3187 16.2167 17.5685 16.6641 18.8513 16.6265C19.7378 16.647 20.6145 16.4371 21.3958 16.0175C22.0865 15.6512 22.6593 15.0966 23.0478 14.418L24.0978 15.048C23.5912 15.8963 22.8611 16.5889 21.9873 17.05C21.015 17.5544 19.9323 17.8082 18.8373 17.7885C17.9906 17.8175 17.1468 17.6739 16.3574 17.3664C15.5679 17.059 14.8493 16.5941 14.2453 16C13.6503 15.405 13.1839 14.6941 12.8751 13.9114C12.5662 13.1287 12.4215 12.291 12.4498 11.45C12.4237 10.6169 12.5652 9.78714 12.8658 9.00978C13.1664 8.23241 13.62 7.52331 14.1998 6.9245C14.7892 6.32568 15.497 5.85639 16.278 5.54662C17.0591 5.23686 17.8962 5.09343 18.7358 5.1255ZM18.7358 6.2875C17.4634 6.24902 16.2267 6.71152 15.2918 7.5755C14.3635 8.44246 13.7992 9.62973 13.7133 10.897H23.4678C23.4088 9.61276 22.8573 8.40062 21.9278 7.5125C21.0609 6.70884 19.9177 6.27011 18.7358 6.2875Z"
        fill="black"
      />
      <path
        d="M31.9727 7.76799C32.7007 6.06933 34.0517 5.21999 36.0257 5.21999V6.38549C34.9551 6.35562 33.9165 6.75219 33.1382 7.48799C32.3612 8.22533 31.9727 9.32199 31.9727 10.778V17.5225H30.7617V5.39149H31.9727V7.76799Z"
        fill="black"
      />
      <path
        d="M47.575 5.12551C49.017 5.12551 50.1615 5.56534 51.0085 6.44501C51.8555 7.32468 52.2802 8.50184 52.2825 9.97651V17.5225H51.047V9.97651C51.0996 8.99164 50.7641 8.02537 50.1125 7.28501C49.7692 6.94223 49.3571 6.6761 48.9034 6.50416C48.4498 6.33223 47.9648 6.25839 47.4805 6.28751C46.9123 6.26812 46.3463 6.3684 45.8193 6.58185C45.2923 6.7953 44.8161 7.11714 44.4215 7.52651C43.6305 8.35251 43.2338 9.54951 43.2315 11.1175V17.5225H42.0205V5.39151H43.2315V7.69451C44.1065 5.98184 45.5543 5.12551 47.575 5.12551Z"
        fill="black"
      />
      <path
        d="M64.0082 5.1255C64.8251 5.09852 65.6376 5.25419 66.3866 5.58116C67.1356 5.90813 67.8022 6.39816 68.3377 7.0155C69.4365 8.25024 70.03 9.85344 70.0002 11.506C70.0002 11.569 70.0002 11.667 70.0002 11.7965C70.0002 11.926 70.0002 12.024 70.0002 12.087H59.0102C59.1096 13.3534 59.6969 14.5317 60.6482 15.3735C61.4857 16.0564 62.5037 16.481 63.5782 16.5953C64.6527 16.7097 65.7373 16.5089 66.6997 16.0175C67.3898 15.6502 67.9624 15.0959 68.3517 14.418L69.4192 15.048C68.905 15.8988 68.1663 16.5915 67.2842 17.05C66.3119 17.5541 65.2293 17.8079 64.1342 17.7885C63.2834 17.8211 62.435 17.6792 61.641 17.3716C60.8471 17.0641 60.1245 16.5973 59.5177 16C58.9228 15.405 58.4564 14.6941 58.1475 13.9114C57.8387 13.1288 57.6939 12.291 57.7222 11.45C57.6962 10.6169 57.8377 9.78714 58.1383 9.00978C58.4389 8.23241 58.8925 7.52331 59.4722 6.9245C60.0616 6.32568 60.7695 5.85639 61.5505 5.54662C62.3315 5.23686 63.1686 5.09343 64.0082 5.1255ZM64.0082 6.2875C62.7313 6.2472 61.4896 6.7097 60.5502 7.57551C59.6241 8.44365 59.0613 9.6305 58.9752 10.897H68.7402C68.6809 9.61285 68.1294 8.40084 67.2002 7.51251C66.3342 6.70759 65.1904 6.26866 64.0082 6.2875Z"
        fill="black"
      />
    </svg>
  );
};

export default LogoFerne;