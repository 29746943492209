import React from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

interface Props {
  links: Array<{
    name: string,
    to: string,
    external: boolean,
  }>;
}

const HeaderMenu: React.FC<Props> = ({links}) => {
  return (
    <ul className={style.menu}>
      {
        links.map((link, index) => (
          <li key={index} className={style.item}>
            {
              link.external ? (
                <a href={link.to} className={style.link}>{link.name}</a>
              ) : (
                <Link to={link.to} className={style.link}>{link.name}</Link>
              )
            }
          </li>
        ))
      }
    </ul>
  );
};

export default HeaderMenu;