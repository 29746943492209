import React, {KeyboardEvent, useEffect, useState} from 'react';
import IconClose from '../../assets/images/icons/close';
import style from './style.module.scss';


import IconMagnifier from '../../assets/images/icons/magnifier';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { fetchSearchProducts } from '../../store/actions/searchResults';
import { useDispatch } from 'react-redux';

import  { useHistory } from 'react-router-dom';

type Anchor = 'top';


const SearchControl: React.FC = () => {
  const [state, setState] = React.useState({
    top: false,
  });

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
        (
          (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
        setTimeout(()=> {
          searchInputRef.current ? searchInputRef.current.focus() : null;
        }, 100);
        
      };

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
  };

  const history = useHistory();
  const handleKeyboardEvent = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      dispatch(fetchSearchProducts(searchInput));
      setState({top: false});
      history.push({
        pathname: '/search-results'
      });
    }
  };


  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            boxShadow: 'none'
          }
        }
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <button className={style.search} onClick={toggleDrawer('top', true)} >
        <IconMagnifier />
      </button>
      

      <Drawer
        anchor={'top'}
        open={state['top']}
        onClose={toggleDrawer('top', false)}
        sx={{ zIndex: '2' }}
      >
        <Box
          sx={{ width: '100%' }}
          role="presentation"
        >
         
          <div className={style.panel}>
            <div className={style.wrapper}>
              <div className='container'>
                <div className={style.inner}>
                  <div className={style.magnifier}>
                    <IconMagnifier />
                  </div>
                  <input
                    type="text"
                    placeholder="Search for services, symptoms, and more"
                    className={style.control}
                    onKeyUp={handleKeyboardEvent} 
                    onChange={(e) => searchItems(e.target.value)}
                    ref={searchInputRef}
                  />
                  <button className={style.close} onClick={toggleDrawer('top', false)}>
                    <IconClose />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default SearchControl;
