import React from 'react';
import style from './style.module.scss';

interface Props {
  label?: React.ReactChild | string,
  placeholder: string,
  value: string,
  name: string,
  onChange: (value: string, name: string) => void,
  alert?: boolean,
}

const CustomTextarea: React.FC<Props> = ({label, placeholder, value, onChange, name, alert}) => {
  return (
    <div className={style.inputWrapper}>
      {label && <div className={style.label}>{label}</div>}
      <textarea 
        style={alert ? {borderColor: 'red'} : {}}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value, name)}
      />
    </div>
  );
};

export default CustomTextarea;