
import { ProductAction, ProductActionTypes } from '../../types/product';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<ProductAction>) => void

export const fetchProduct = (slug: string): ReturnValue => {
  return async (dispatch: Dispatch<ProductAction>) => {
    try {
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCT
      });
      const response = await axios.get(`${process.env.REACT_APP_API}/product/${slug}`);
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCT_SUCCESS,
        payload: response.data.data
      });
    } catch (e) {
      dispatch({
        type: ProductActionTypes.FETCH_PRODUCT_ERROR,
        payload: 'Error fetch data'
      });
    }
  };
};

export const clearProduct = () => ({type: ProductActionTypes.CLEAR_PRODUCT});