import React from 'react';
import { Clinic } from '../../../types/bookClinicVisit';
import { Doctor } from '../../../types/bookConsultation';
import CustomSwiper from '../../CustomSwiper';
import style from './style.module.scss';

interface Props {
  typeData: Doctor[] | Clinic[], 
  activeTypeData: Doctor | Clinic | undefined, 
  changeActiveTypeData: (doctorId: number) => void,
  title: string,
}

function isDoctorData(obj: any): obj is Doctor[] {
  return obj[0].type === 'doctor';
}

function isClinicData(obj: any): obj is Clinic[] {
  return obj[0].type === 'clinic';
}

const BookTypePicker: React.FC<Props> = ({typeData, activeTypeData, changeActiveTypeData, title}) => {
  return (
    <section className={style.select}>
      <h3>{title}</h3>
      {isDoctorData(typeData) && <div className={style.listDoctor}>
        <div className='slider-book-type'>
          <div className='swiper-stretch'>
            <CustomSwiper parameters={{ slidesPerView: 'auto', followFinger: false, loop: false, spaceBetween: 0 }} classesForButtons={['.swiper-button-next', '.swiper-button-prev']} >
              {typeData.map(data => (
                <div key={data.id} className={style.cell}>
                  <button 
                    className={`${style.type} ${activeTypeData && activeTypeData.id === data.id && style.active}`}
                    onClick={() => changeActiveTypeData(data.id)}
                  >
                    <img src={data.photo} alt="" />
                    <span>{data.name}</span>
                  </button>
                </div>
              ))}
            </CustomSwiper>
          </div>
        </div>
      </div>}
      {isClinicData(typeData) && <div className={style.listClinic}>
        {typeData.map(data => (
          <div key={data.id} className={style.clinic}>
            <button 
              className={`${style.type} ${activeTypeData && activeTypeData.id === data.id && style.active}`}
              onClick={() => changeActiveTypeData(data.id)}
            >
              <div className={style.clinicName}>{data.name}</div>
              <div className={style.address}>{data.address}</div>
            </button>
          </div>
        ))}
      </div>}
    </section>
  );
};

export default BookTypePicker;