import React from 'react';
import style from './style.module.scss';

interface Props {
  title: string,
  component: React.ReactNode,
}

const BookNotice: React.FC<Props> = ({title, component}) => {
  return (
    <section className={style.notice}>
      <h4>{title}</h4>
      {component}
    </section>
  );
};

export default BookNotice;