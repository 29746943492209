import {
  CategoryState,
  CategoryAction,
  CategoryActionTypes
} from '../../types/category';

const initialState: CategoryState = {
  data: [],
  loading: false,
  error: null
};

export const categoryReducer = (state = initialState, action: CategoryAction): CategoryState => {
  switch (action.type) {
  case CategoryActionTypes.FETCH_CATEGORY:
    return {loading: true, error: null, data: []};
  case CategoryActionTypes.FETCH_CATEGORY_SUCCESS:
    return {loading: false, error: null, data: action.payload};
  case CategoryActionTypes.FETCH_CATEGORY_ERROR:
    return {loading: false, error: action.payload, data: []};
  default:
    return state;
  }
};