import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useCallback, useMemo } from 'react';
import { useTypedDispatch, useTypedSelector } from '../../../../hooks/productsTypedSelector';
import DateFnsUtils from '@date-io/date-fns';
import style from './style.module.scss';
import BookTitle from '../../BookTitle';
import BookButtons from '../../BookButtons';
import { ServiceLocationType, StepTypes } from '../../../../types/order';
import { changeStepAction } from '../../../../store/actions/order';
import { useHistory } from 'react-router';
import { getCartProducts, getCartTotal } from '../../../../store/selectors/cartProducts';
import { ProductTypes } from '../../../../types/product';
import { getOrderState } from '../../../../store/selectors/order';
import { clearCartProductsAction } from '../../../../store/actions/cartProducts';
import { ShippingOptionValues } from '../../../../types/shipping';
import { isEmpty } from 'lodash';

const ReviewOrder: React.FC = () => {

  const history = useHistory();

  const dispatch = useTypedDispatch();
  const { serviceLocation } = useTypedSelector(getOrderState);
  const state = useTypedSelector(state => state);
  const products = useTypedSelector(getCartProducts);
  const total = useTypedSelector(getCartTotal);

  const isOnlyClinicVisit = useMemo(() => {
    return products.every(product => product.product_type === ProductTypes.CLINIC_VISIT);
  }, [products]);

  const onClickBack = useCallback(() => {
    if (isOnlyClinicVisit) {
      dispatch(changeStepAction(StepTypes.PERSONAL_INFO));
    } else {
      dispatch(changeStepAction(StepTypes.PAYMENT_DETAILS));
    }
    
  }, [dispatch, isOnlyClinicVisit]);

  const onClickContinue = useCallback(() => {
    dispatch(clearCartProductsAction());
    history.push('/confirmed-order');
  }, [history, dispatch, clearCartProductsAction]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={style.wrapper}>
        <BookTitle 
          title="Review your order"
          description="Ready to place your order? Let’s make sure everything is good to go."
        />
        <div className={style.info}>
          <header>
            <span>Your info</span>
            <button onClick={() => dispatch(changeStepAction(StepTypes.PERSONAL_INFO))}>Edit</button>
          </header>
          <div>
            <div>{state.personalInfo.personalInfo.firstName} {state.personalInfo.personalInfo.lastName}</div>
            <div>{state.personalInfo.personalInfo.email}</div>
            <div>{state.personalInfo.personalInfo.contact}</div>
          </div>
        </div>
        {
          !isOnlyClinicVisit && !(serviceLocation === ServiceLocationType.CLINIC) && !isEmpty(state.bookConsultation.activeTime) && (
            <div className={style.info}>
              <header>
                <span>Tele-consultation Details</span>
                <button onClick={() => dispatch(changeStepAction(StepTypes.BOOK_CONSULTATION))}>Edit</button>
              </header>
              <div>
                <div>Doctor: {state.bookConsultation.activeDoctor?.name}</div>
                <div>Date: {new Date(state.bookConsultation.activeDate).toLocaleString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}</div>
                <div>Time: {state.bookConsultation.activeTime}</div>
              </div>
            </div>
          )
        }
        {
          state.bookClinicVisit.activeTime && (
            <div className={style.info}>
              <header>
                <span>Clinic Visit</span>
                <button onClick={() => dispatch(changeStepAction(StepTypes.BOOK_CLINIC_VISIT))}>Edit</button>
              </header>
              <div>
                <div>Date: {state.bookClinicVisit.activeDate}</div>
                <div>Time: {state.bookClinicVisit.activeTime}</div>
                <br />
                <div>Location: {state.bookClinicVisit.activeClinic?.address}</div>
              </div>
            </div>
          )
        }

        {
          state.bookBloodCollection.activeTime && (
            <div className={style.info}>
              <header>
                <span>Blood Collection Details:</span>
                <button onClick={() => dispatch(changeStepAction(StepTypes.BOOK_BLOOD_COLLECTION))}>Edit</button>
              </header>
              <div>
                <div>Date: {state.bookBloodCollection.activeDate}</div>
                <div>Time: {state.bookBloodCollection.activeTime}</div>
                <br />
                <div>Location: {state.bookBloodCollection.address?.streetAddress}, {state.bookBloodCollection.address?.additionalInfo} {state.bookBloodCollection.address?.postalCode}</div>
              </div>
            </div>
          )
        }

        {
          state.deliveryDetails.activeDeliveryOption && (
            <div className={style.info}>
              <header>
                <span>Delivery Details</span>
                {state.deliveryDetails.activeDeliveryOption !== null && state.shipping.activeShippingOption?.value !== ShippingOptionValues.SEPARATELY_APPOINTMENT ? (
                  <button onClick={() => dispatch(changeStepAction(StepTypes.DELIVERY_DETAILS))}>Edit</button>
                ) : (
                  <button onClick={() => dispatch(changeStepAction(StepTypes.SHIPPING))}>Edit</button>
                )}
              </header>
              <div>
                {
                  state.deliveryDetails.address.streetAddress !== '' ? (
                    <div>{state.deliveryDetails.address.streetAddress}, {state.deliveryDetails.address.additionalInfo}, {state.deliveryDetails.address.postalCode}</div>
                  ) : null
                }
                
                <div>
                  <span className={style.label}>Delivery method:</span>
                  {state.deliveryDetails && state.deliveryDetails.activeDeliveryOption ? state.deliveryDetails.activeDeliveryOption.text : null}
                  {state.shipping && state.shipping.activeDeliveryOption ? state.shipping.activeDeliveryOption.text : null}</div>
              </div>
            </div>
          )
        }
        {
          !isOnlyClinicVisit && (
            <div className={style.info}>
              <header>
                <span>Payment Details</span>
                <button onClick={() => dispatch(changeStepAction(StepTypes.PAYMENT_DETAILS))}>Edit</button>
              </header>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{display: 'block', marginLeft: '1rem'}}>••••</span>
              </div>
            </div>
          )
        }
        {
          state.personalInfo.personalInfo.allergies && (
            <div className={style.info}>
              <header>
                <span>Allergies</span>
                <button onClick={() => dispatch(changeStepAction(StepTypes.PERSONAL_INFO))}>Edit</button>
              </header>
              <div>
                {state.personalInfo.personalInfo.allergies}
              </div>
            </div>
          )
        }
        <BookButtons 
          onClickBack={onClickBack} 
          onClickContinue={onClickContinue}
          activeContinueButton
          buttonText="Place Order"
          payment
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default ReviewOrder;