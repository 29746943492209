import React from 'react';
import { Product } from '../../types/product';
import ProductsList from '../ProductsList';
import style from './style.module.scss';

interface Props {
  products: Product[];
}

const Related: React.FC<Props> = ({products}) => {
  return (
    <div className={style.related}>
      <div className={style.head}>
        <strong className={style.title}>Related Products</strong>
      </div>
      <div className={style.body}>
        <ProductsList products={products} slide />
      </div>
    </div>
  );
};

export default Related;

