import React, { useCallback, useEffect, useState } from 'react';
import { Address } from '../../../types/paymentDetails';
import style from './style.module.scss';

enum InputTypes {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  STREET_ADDRESS = 'STREET_ADDRESS',
  ADDITIONAL_INFO = 'ADDITIONAL_INFO',
  POSTAL_CODE = 'POSTAL_CODE',
}

interface Props {
  address: Address,
  setAddress: (address: Address) => void,
}

const BillingAddressForm: React.FC<Props> = ({address, setAddress}) => {

  const [addressState, setAddressState] = useState(address);

  const changeAddress = useCallback((value: string, type: InputTypes) => {
    switch (type) {
    case InputTypes.FIRST_NAME: 
      setAddressState(prev => ({...prev, firstName: value}));
      break;
    case InputTypes.LAST_NAME: 
      setAddressState(prev => ({...prev, lastName: value}));
      break;
    case InputTypes.STREET_ADDRESS: 
      setAddressState(prev => ({...prev, streetAddress: value}));
      break;
    case InputTypes.ADDITIONAL_INFO: 
      setAddressState(prev => ({...prev, additionalInfo: value}));
      break;
    case InputTypes.POSTAL_CODE: 
      setAddressState(prev => ({...prev, postalCode: value}));
      break;
    default:
      break;
    }
  }, [setAddress]);

  useEffect(() => {
    setAddress(addressState);
  }, [addressState]);

  return (
    <form className={style.address}>
      <div>
        <input 
          type="text" 
          placeholder="First name" 
          value={address.firstName} 
          onChange={(e) => changeAddress(e.target.value, InputTypes.FIRST_NAME)} 
        />
        <input 
          type="text" 
          placeholder="Last name" 
          value={address.lastName} 
          onChange={(e) => changeAddress(e.target.value, InputTypes.LAST_NAME)} 
        />
      </div>
      <input 
        type="text" 
        placeholder="Street address" 
        value={address.streetAddress} 
        onChange={(e) => changeAddress(e.target.value, InputTypes.STREET_ADDRESS)} 
      />
      <input 
        type="text" 
        placeholder="Apt, suite, unit, floor (optional)" 
        value={address.additionalInfo} 
        onChange={(e) => changeAddress(e.target.value, InputTypes.ADDITIONAL_INFO)} 
      />
      <div>
        <input 
          type="number" 
          placeholder="Postal code" 
          value={address.postalCode} 
          onChange={(e) => changeAddress(e.target.value, InputTypes.POSTAL_CODE)} 
        />
        <input 
          type="text" 
          value="Singapore" 
          disabled 
        />
      </div>
    </form>
  );
};

export default BillingAddressForm;