import React, {useEffect, useMemo} from 'react';
import { Redirect, useHistory } from 'react-router';
import Step from '../../components/OrderComponents/Step';
import BookBloodCollection from '../../components/OrderComponents/StepComponents/BookBloodCollection';
import BookClinicVisit from '../../components/OrderComponents/StepComponents/BookClinicVisit';
import BookConsultation from '../../components/OrderComponents/StepComponents/BookConsultation';
import PersonalInfo from '../../components/OrderComponents/StepComponents/PersonalInfo';
import ServiceLocation from '../../components/OrderComponents/StepComponents/ServiceLocation';
import Shipping from '../../components/OrderComponents/StepComponents/Shipping';
import DeliveryDetails from '../../components/OrderComponents/StepComponents/DeliveryDetails';
import ReviewOrder from '../../components/OrderComponents/StepComponents/ReviewOrder';
import PaymentDetails from '../../components/OrderComponents/StepComponents/PaymentDetails';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import { StepTypes } from '../../types/order';

const Order: React.FC = () => {
  const { step } = useTypedSelector(state => state.order);

  switch (step) {
  case StepTypes.SERVICE_LOCATION:
    return <Step step={<ServiceLocation />} stepType={step} />;
  case StepTypes.BOOK_CONSULTATION: 
    return <Step step={<BookConsultation />} stepType={step} />;
  case StepTypes.BOOK_CLINIC_VISIT: 
    return <Step step={<BookClinicVisit />} stepType={step} />;
  case StepTypes.BOOK_BLOOD_COLLECTION: 
    return <Step step={<BookBloodCollection />} stepType={step} />;
  case StepTypes.SHIPPING: 
    return <Step step={<Shipping />} stepType={step} />;
  case StepTypes.PERSONAL_INFO: 
    return <Step step={<PersonalInfo />} stepType={step} />;
  case StepTypes.DELIVERY_DETAILS: 
    return <Step step={<DeliveryDetails />} stepType={step} />;
  case StepTypes.REVIEW_ORDER: 
    return <Step step={<ReviewOrder />} stepType={step} />;
  case StepTypes.PAYMENT_DETAILS: 
    return <Step step={<PaymentDetails />} stepType={step} />;
  default: 
    return <Redirect to={'/cart'} />;
  }
};

export default Order;