import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import CustomSwiper from '../CustomSwiper';
// styles
import style from './style.module.scss';

interface Props {
  list: any;
}

const HowWorks: React.FC<Props> = ({ list }) => {

  const [windowWidth] = useWindowSize();

  const slides = (
    list.sort((a: any, b:any) => a.order_column > b.order_column ? 1 : -1)
      .map((info: { custom_properties: { header: string; description: string; }; original_url: string; }, index: number) => (
        <div className={style.cell} key={index}>
          <div className={style.card}>
            <div className={style.preview} style={{backgroundImage: `url(${info.original_url})`}}></div>
            <div className={style.panel}>
              <strong className={style.headline}>{info.custom_properties.header}</strong>
              <div className={style.description}>
                <p>{info.custom_properties.description}</p>
              </div>
            </div>
          </div>
        </div>
      ))
  );
  return (
    <div className={style.section}>
      <div className={style.container}>
        <div className={style.head}>
          <strong className={style.title}>How It Works</strong>
        </div>
        <div className={style.body}>
          <div className='slider-default center'>
            <div className={style.grid}>
              {windowWidth > 767 ? (
                <CustomSwiper parameters={{ slidesPerView: 'auto', followFinger: false, loop: false, spaceBetween: 0 }} classesForButtons={['.slider-button-next', '.slider-button-prev']} >
                  {slides}
                </CustomSwiper>
              ) : slides }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;