
import { SearchResultsAction, SearchResultsActionTypes } from '../../types/searchResults';
import { Dispatch } from 'react';
import axios from 'axios';

type ReturnValue = (dispatch: Dispatch<SearchResultsAction>) => void;

export const fetchSearchProducts = (searchInput: string): ReturnValue => {
  return async (dispatch: Dispatch<SearchResultsAction>) => {
    try {
      dispatch({
        type: SearchResultsActionTypes.FETCH_SEARCH_PRODUCTS
      });
      const response = await axios.get(`${process.env.REACT_APP_API}/product/search?search_line=${searchInput}`);
      dispatch({
        type: SearchResultsActionTypes.FETCH_SEARCH_PRODUCTS_SUCCESS,
        payload: response.data.data,
        text: searchInput,
      });
    } catch (e) {
      dispatch({
        type: SearchResultsActionTypes.FETCH_SEARCH_PRODUCTS_ERROR,
        payload: 'Error fetch data',
      });
    }
  };
};