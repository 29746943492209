import React from 'react';
import style from './style.module.scss';
import { useTypedSelector } from '../../hooks/productsTypedSelector';

interface Props {
  slug: string,
}

const CategoryDescription: React.FC<Props> = ({slug}) => {
  const { categories } = useTypedSelector((state) => state);

  return (
    <div className={style.info}>
      {
        categories.categories.filter((categories: { slug: string; }) => categories.slug === slug).map((category, index) =>
          <div key={index} className={style.item}>
            <h2 className={style.title}>{category.name}</h2>
            <div className={style.description}>
              <p>{category.content}</p> 
            </div>
          </div>
        )
      }
    </div>
  );
};

export default CategoryDescription;
