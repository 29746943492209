import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IconClose from '../../assets/images/icons/close';
import IconMagnifier from '../../assets/images/icons/magnifier';
import { useTypedSelector } from '../../hooks/productsTypedSelector';
import { fetchSearchProducts } from '../../store/actions/searchResults';
import style from './style.module.scss';
import debounce from 'lodash.debounce';

const SearchControlDefault: React.FC = () => {

  const dispatch = useDispatch();

  const searchText = useTypedSelector(state => state.search.text);

  const [searchInput, setSearchInput] = useState(searchText);
  const [dbValue, saveToDb] = useState('');

  const debouncedSave = useCallback(
    debounce(
      nextValue => saveToDb(nextValue), 1000),
    [],
  );
 
  useEffect(() => {
    if(searchInput !== '') {
      dispatch(fetchSearchProducts(searchInput));
    }
  }, [dbValue]);
  

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
    debouncedSave(searchValue);
  };

  const resetInputField = () => {
    setSearchInput('');
  };

  return (
    <div className={style.wrapper}>
      <div className={style.magnifier}>
        <IconMagnifier />
      </div>
      <input
        type="text"
        placeholder="Search"
        value={searchInput}
        onChange={(e) => searchItems(e.target.value)}
        className={style.control}
      />
      <button className={style.close} onClick={resetInputField}>
        <IconClose />
      </button>
    </div> 
  );
};

export default SearchControlDefault;