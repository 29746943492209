import React from 'react';
import style from './style.module.scss';
import HeaderMenu from '../HeaderMenu';
import LogoFerne from '../../assets/images/logo-ferne';
import Basket from '../Basket';
import Help from '../Help';
import classNames from 'classnames';
import SearchControl from '../SearchControl';
import Sidebar from '../../components/Sidebar';

const menu = [
  {
    name: 'shop',
    to: '/',
    external: false,
  },
  {
    name: 'blog',
    to: 'https://www.fernehealth.com/blog',
    external: true,
  },
  {
    name: 'take assessment',
    to: 'https://www.fernehealth.com/assessment',
    external: true,
  },
];

const LayoutHeader: React.FC = () => {
  return (
    <div className={style.header}>
      <div className={style.wrapper}>
        <div className="container">
          <div className={style.inner}>
            <Sidebar />
            <div className={style.panel}>
              <a href="https://www.fernehealth.com" className={style.logo}>
                <LogoFerne />
              </a>
              <HeaderMenu links={menu} />
            </div>
            <div className={style.panel}>
              <ul className={style.list}>
                <li className={classNames(style.item, style.hide)}>
                  <Help />
                </li>
                <li className={style.item}>
                  <SearchControl />
                </li>
                <li className={style.item}>
                  <Basket />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutHeader;